import React, { useState, useEffect, useContext } from "react";
import widgetView from "../../assets/images/view-primary.svg";
import widgetPPC from "../../assets/images/ppc-primary.svg";
import widgetstar from "../../assets/images/star-primary.svg";
import widgetPhone from "../../assets/images/phone-primary.svg";
import widgetWebsite from "../../assets/images/website-primary.svg";
import widgetChat from "../../assets/images/chat-primary.svg";
import canclePrimaryImg from "../../assets/images/cancle-primary.svg";
import nodataImage from "../../assets/images/NoData.png";
import NodataPPCvive from "../../assets/images/NodataPPCView.png";
import backImgBlack from "../../assets/images/back.svg";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { Line, Bar } from "react-chartjs-2";
import { addCommasToPrice } from "../../constants/utils";
import { DashboardAction } from "../../actions/index";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
const DashboardIndex = () => {
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const context = useContext(SpinnerContext);
  const [Data, setData] = useState([]);
  const [detailData, setdetailData] = useState([]);
  const [phoneCTR, setphoneCTR] = useState([]);
  const [FacilityCTR, setFacilityCTR] = useState([]);
  const [WebsiteCTR, setWebsiteCTR] = useState([]);
  const [ChatCTR, setChatCTR] = useState([]);
  const [PPCView, setPPCView] = useState([]);

  const [classNameAdded, setclassNameAdded] = useState("");

  const [monthsData, setmonthsData] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);

  const allOption = { label: "All", value: "*" };
  const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);

  const [selectedUserOptions, setselectedUserOptions] = useState([]);

  const [selectedCountryOptions, setSelectedCountryOptions] = useState([
    {
      label: "United States",
      value: "United States",
    },
  ]);
  const [selectedFacilityTypeOptions, setSelectedFacilityTypeOptions] =
    useState([
      {
        label: "DETOX",
        value: "detox",
      },
      {
        label: "RESIDENTIAL",
        value: "residential",
      },
      {
        label: "PHP",
        value: "php",
      },
      {
        label: "IOP",
        value: "iop",
      },
      {
        label: "SOBER LIVING",
        value: "living",
      },
    ]);
  const [
    selectedSubscriptionPlansOptions,
    setSelectedSubscriptionPlansOptions,
  ] = useState([
    {
      label: "Basic",
      value: "basic",
    },
    {
      label: "Pro",
      value: "pro",
    },
  ]);

  const [detail_user, setdetail_user] = useState(false);
  const [facilitytype, setfacilitytype] = useState(false);
  const [facilityNameData, setfacilityNameData] = useState(false);
  const [subscriptiontype, setsubscriptiontype] = useState(false);
  const [citydata, setcitydata] = useState(false);
  const [statedata, setstatedata] = useState(false);
  const [countrydata, setcountrydata] = useState(false);

  const phoneDataDetail = useSelector((state) => state?.dashboard);
  const maxDataValue = 100;
  const tickIncrement = maxDataValue / 5;

  useEffect(() => {
    getDetailforData();
  }, []);

  const getDetailforData = async () => {
    if (detailData?.length === 0) {
      const getDashbaordDetail = await dispatch(
        DashboardAction.fetchDashboardDetail()
      );
      setdetailData(getDashbaordDetail);

      setSelectedCityOptions(
        getDashbaordDetail?.city?.map((obj) => ({
          label: obj,
          value: obj,
        })) || []
      );
      setSelectedStateOptions(
        getDashbaordDetail?.state?.map((obj) => ({
          label: obj,
          value: obj,
        })) || []
      );

      if (phoneDataDetail?.setData?.name) {
        setselectedUserOptions([
          {
            label: phoneDataDetail?.setData?.name,
            value: phoneDataDetail?.setData?.name,
          },
        ]);
      } else {
        setselectedUserOptions(
          getDashbaordDetail?.name?.map((obj) => ({
            label: obj,
            value: obj,
          })) || []
        );
      }
    }
  };

  useEffect(() => {
    context.showLoader(`Fetching Dashboard Details`);
    GetDetail(
      selectedFacilityTypeOptions,
      selectedUserOptions,
      selectedCityOptions,
      selectedStateOptions,
      selectedCountryOptions,
      selectedSubscriptionPlansOptions
    );
  }, [
    selectedFacilityTypeOptions,
    selectedUserOptions,
    selectedCityOptions,
    selectedStateOptions,
    selectedCountryOptions,
    selectedSubscriptionPlansOptions,
  ]);

  const GetDetail = async (type, name, city, state, country, subscription) => {
    try {
      const FormData = await {
        facilityType: type?.length === 5 ? [] : type?.map((item) => item.value),
        subscriptionType:
          subscription?.length === 2
            ? []
            : subscription?.map((item) => item.value),
        name: phoneDataDetail?.setData?.name
          ? [phoneDataDetail?.setData?.name]
          : name?.length === detailData?.name?.length
          ? []
          : name?.map((item) => item.value),
        city:
          city?.length === detailData?.city?.length
            ? []
            : city?.map((item) => item.value),
        state:
          state?.length === detailData?.state?.length
            ? []
            : state?.map((item) => item.value),
        country:
          country?.length === detailData?.country?.length
            ? []
            : country?.map((item) => item.value),
      };
      const getdetail = await dispatch(
        DashboardAction.getDashboardDetail(FormData)
      );

      setData(getdetail);

      context.hideLoader();

      if (Object.keys(getdetail?.ctrImpressions?.phoneCTRData)?.length > 0) {
        const dataItemPhone = populateData(
          getdetail?.ctrImpressions?.phoneCTRData
            ? getdetail?.ctrImpressions?.phoneCTRData
            : [],
          monthsData
        );
        setphoneCTR(dataItemPhone);
      } else {
        setphoneCTR([]);
      }

      // for facility

      if (Object.keys(getdetail?.ctrImpressions?.facilityCTRData)?.length > 0) {
        const dataItemFacility = populateData(
          getdetail?.ctrImpressions?.facilityCTRData
            ? getdetail?.ctrImpressions?.facilityCTRData
            : [],
          monthsData
        );
        setFacilityCTR(dataItemFacility);
      } else {
        setFacilityCTR([]);
      }

      //WebsiteLinkCTR
      if (Object.keys(getdetail?.ctrImpressions?.websiteCTRData)?.length > 0) {
        const dataItemWebisiteCTR = populateData(
          getdetail?.ctrImpressions?.websiteCTRData
            ? getdetail?.ctrImpressions?.websiteCTRData
            : [],
          monthsData
        );

        setWebsiteCTR(dataItemWebisiteCTR);
      } else {
        setWebsiteCTR([]);
      }

      // Chat CTR

      if (Object.keys(getdetail?.ctrImpressions?.chatCTRData)?.length > 0) {
        const dataItemChatCTR = populateData(
          getdetail?.ctrImpressions?.chatCTRData
            ? getdetail?.ctrImpressions?.chatCTRData
            : [],
          monthsData
        );
        setChatCTR(dataItemChatCTR);
      } else {
        setChatCTR([]);
      }

      //PPCViewData
      if (Object.keys(getdetail?.ppcData?.ppcMonthlyClick)?.length > 0) {
        const dataItemPPCView = populateDataPPCView(
          getdetail?.ppcData?.ppcMonthlyClick
            ? getdetail?.ppcData?.ppcMonthlyClick
            : [],
          monthsData
        );

        setPPCView(dataItemPPCView);
      } else {
        setPPCView([]);
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // for all View
  const populateData = (aaraydata, months) => {
    try {
      const data = [];
      Object.keys(aaraydata).forEach((year) => {
        Object.keys(aaraydata[year]).forEach((month) => {
          const monthIndex = months.findIndex(
            (m) => m.toLowerCase() === month.toLowerCase()
          );
          if (monthIndex !== -1) {
            data[monthIndex] = aaraydata[year][month];
          }
        });
      });

      for (let i = 0; i < months.length; i++) {
        if (data[i] === undefined) {
          data[i] = 0;
        }
      }

      return data;
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // for PPC View
  const populateDataPPCView = (phoneCTRData, months) => {
    const data = [];
    for (const year in phoneCTRData) {
      if (phoneCTRData?.hasOwnProperty(year)) {
        const yearData = phoneCTRData[year];
        for (let i = 0; i < months.length; i++) {
          const month = months[i]?.toLowerCase();
          if (yearData?.hasOwnProperty(month)) {
            data[i] = yearData[month];
          } else if (!data[i]) {
            data[i] = 0;
          }
        }
      }
    }

    return data;
  };

  // PPCView
  const PPCViewData = {
    labels: monthsData,
    datasets: [
      {
        label: "PPC VIEWS",
        type: "bar",
        data: PPCView,
        backgroundColor: "#3968BE",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        barPercentage: isMobile() ? 1 : 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  function isMobile() {
    return window.innerWidth <= 992; // Define your own breakpoint for mobile
  }

  const PPCViewOption = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          display: true,
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: true,
            // display: isMobile() ? false : true,
            labelString: "Month",
            position: "bottom",
          },
        },
      ],

      yAxes: [
        {
          ticks: {
            display: isMobile() ? false : true,
            beginAtZero: true,
            max: 100,
          },
          scaleLabel: {
            // display: true,
            display: isMobile() ? false : true,
            labelString: "PPC VIEW",
          },
        },
      ],
    },
    tooltips: {
      position: "nearest",
      yAlign: "top",
      backgroundColor: "#FF5F5F",
      titleFontColor: "white",
      bodyFontColor: "white",
      footerFontColor: "white",
      callbacks: {
        label: function (tooltipItem, data) {
          let value = tooltipItem?.yLabel?.toFixed(0);
          return `${value} PPC Views`;
        },
      },
    },
  };

  // Chat CTR

  const ChatCTRData = {
    labels: monthsData,

    datasets: [
      {
        label: "Chat CTR",
        type: "bar",
        data: ChatCTR,
        backgroundColor: "#28B463",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        barPercentage: isMobile() ? 1 : 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const ChatCTROption = {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          display: true,
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: true,
            //display: isMobile() ? false : true,
            labelString: "Month",
            position: "bottom",
          },
        },
      ],

      yAxes: [
        {
          scaleLabel: {
            //display: true,
            display: isMobile() ? false : true,
            labelString: "Chat CTR",
          },
          ticks: {
            display: isMobile() ? false : true,
            beginAtZero: true,
            callback: function (value) {
              return `${value}%`;
            },
            stepSize: tickIncrement,
          },
        },
      ],
    },
    tooltips: {
      position: "nearest",
      yAlign: "top",
      backgroundColor: "#FF5F5F",
      titleFontColor: "white",
      bodyFontColor: "white",
      footerFontColor: "white",
      callbacks: {
        label: function (tooltipItem, data) {
          let value = tooltipItem?.yLabel?.toFixed(1);
          return `${value}% Chat CTR`;
        },
      },
    },
  };

  // phone CTR

  const phoneData = {
    labels: monthsData,
    datasets: [
      {
        data: phoneCTR,
        label: "Phone Number CTR",
        fill: false,
        pointBackgroundColor: "green",
        pointRadius: 5,
        pointHoverRadius: 10,
        tension: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const optionsphoneData = {
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: true,
            //display: isMobile() ? false : true,
            labelString: "Month",
            position: "bottom",
          },
        },
      ],

      yAxes: [
        {
          scaleLabel: {
            // display: true,
            display: isMobile() ? false : true,
            labelString: "Phone Number CTR",
          },
          ticks: {
            display: isMobile() ? false : true,
            beginAtZero: true,
            callback: function (value) {
              return `${value}%`;
            },
            stepSize: tickIncrement,
          },
        },
      ],
    },
    tooltips: {
      position: "nearest",
      yAlign: "top",
      backgroundColor: "#FF5F5F",
      titleFontColor: "white",
      bodyFontColor: "white",
      footerFontColor: "white",
      callbacks: {
        label: function (tooltipItem, data) {
          let value = tooltipItem?.yLabel?.toFixed(1);
          return `${value}% Phone Number CTR`;
        },
      },
    },
  };

  //
  //Facility Click Data

  const FacilityCTRData = {
    labels: monthsData,
    datasets: [
      {
        data: FacilityCTR,
        label: "Facility Click-Through Rates (CTR)",
        fill: false,

        pointBackgroundColor: "green",
        pointRadius: 5,
        pointHoverRadius: 10,
        tension: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const optionsFacilityCTRData = {
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: true,
            // display: isMobile() ? false : true,
            labelString: "Month",
            position: "bottom",
          },
        },
      ],

      yAxes: [
        {
          scaleLabel: {
            //display: true,
            display: isMobile() ? false : true,
            labelString: "Facility Click-Through Rates (CTR)",
          },
          ticks: {
            display: isMobile() ? false : true,
            beginAtZero: true,
            callback: function (value) {
              return `${value}%`;
            },
            stepSize: tickIncrement,
          },
        },
      ],
    },
    tooltips: {
      position: "nearest",
      yAlign: "top",
      backgroundColor: "#FF5F5F",
      titleFontColor: "white",
      bodyFontColor: "white",
      footerFontColor: "white",
      callbacks: {
        label: function (tooltipItem, data) {
          let value = tooltipItem?.yLabel?.toFixed(1);
          return `${value}% Facility Click`;
        },
      },
    },
  };

  // Website Link CTR

  const WebsiteLinkCTRData = {
    labels: monthsData,

    datasets: [
      {
        data: WebsiteCTR,
        label: "Website Link CTR",
        fill: false,
        type: "bar",

        backgroundColor: "#7D3C98",
        borderColor: "rgba(75, 192, 192, 1)",
        pointRadius: 5,
        pointHoverRadius: 10,
        borderWidth: 1,
        barPercentage: isMobile() ? 1 : 0.5,
        categoryPercentage: 0.5,
        tension: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const WebsiteLinkCTROption = {
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: false,
          },
          scaleLabel: {
            display: true,
            // display: isMobile() ? false : true,
            labelString: "Month",
            position: "bottom",
          },
        },
      ],

      yAxes: [
        {
          scaleLabel: {
            //display: true,
            display: isMobile() ? false : true,
            labelString: "Website Link CTR",
          },
          ticks: {
            display: isMobile() ? false : true,
            beginAtZero: true,
            callback: function (value) {
              return `${value}%`;
            },
            stepSize: tickIncrement,
          },
        },
      ],
    },
    tooltips: {
      position: "nearest",
      yAlign: "top",
      backgroundColor: "#FF5F5F",
      titleFontColor: "white",
      bodyFontColor: "white",
      footerFontColor: "white",
      callbacks: {
        label: function (tooltipItem, data) {
          let value = tooltipItem?.yLabel?.toFixed(1);
          return `${value}% Website Link CTR`;
        },
      },
    },
  };

  const cityOptions =
    detailData?.city?.map((obj) => ({ label: obj, value: obj })) || [];
  const stateOptions =
    detailData?.state?.map((obj) => ({ label: obj, value: obj })) || [];
  const countryOptions =
    detailData?.country?.map((obj) => ({ label: obj, value: obj })) || [];
  const facilitytypeOptions = [
    {
      label: "DETOX",
      value: "detox",
    },
    {
      label: "RESIDENTIAL",
      value: "residential",
    },
    {
      label: "PHP",
      value: "php",
    },
    {
      label: "IOP",
      value: "iop",
    },
    {
      label: "SOBER LIVING",
      value: "living",
    },
  ];
  const SubscriptionOptions = [
    {
      label: "Basic",
      value: "basic",
    },
    {
      label: "Pro",
      value: "pro",
    },
  ];
  const userOptions =
    detailData?.name?.map((obj) => ({
      label: obj,
      value: obj,
    })) || [];

  const isSelectAllSelected = (selected, options) =>
    selected.length === options.length;
  const isOptionSelected = (option, selected) =>
    selected.some(({ value }) => value === option.value);

  const handleCityChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      setSelectedCityOptions(
        isSelectAllSelected(selectedCityOptions, cityOptions) ? [] : cityOptions
      );
    } else {
      setSelectedCityOptions(
        selected.filter((option) => option.value !== allOption.value)
      );
    }

    setcitydata(true);
  };

  const handleSubscriptionChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      setSelectedSubscriptionPlansOptions(
        isSelectAllSelected(
          selectedSubscriptionPlansOptions,
          SubscriptionOptions
        )
          ? []
          : SubscriptionOptions
      );
    } else {
      setSelectedSubscriptionPlansOptions(
        selected.filter((option) => option.value !== allOption.value)
      );
    }
    setsubscriptiontype(true);
  };
  const handleStateChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      setSelectedStateOptions(
        isSelectAllSelected(selectedStateOptions, stateOptions)
          ? []
          : stateOptions
      );
    } else {
      setSelectedStateOptions(
        selected.filter((option) => option.value !== allOption.value)
      );
    }

    setstatedata(true);
  };

  const handleCountryChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      setSelectedCountryOptions(
        isSelectAllSelected(selectedCountryOptions, countryOptions)
          ? []
          : countryOptions
      );
    } else {
      setSelectedCountryOptions(
        selected.filter((option) => option.value !== allOption.value)
      );
    }

    setcountrydata(true);
  };

  const handleFacilityNameChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      setselectedUserOptions(
        isSelectAllSelected(selectedUserOptions, userOptions) ? [] : userOptions
      );
    } else {
      setselectedUserOptions(
        selected.filter((option) => option.value !== allOption.value)
      );
    }
    setfacilityNameData(true);

    if (phoneDataDetail?.setData?.name) {
      dispatch(
        DashboardAction.setDataforRedirection({
          name: false,
          url: phoneDataDetail?.setData?.url,
        })
      );
    }
  };

  const handleFacilitytypeChange = (selected) => {
    if (selected.some((option) => option.value === allOption.value)) {
      setSelectedFacilityTypeOptions(
        isSelectAllSelected(selectedFacilityTypeOptions, facilitytypeOptions)
          ? []
          : facilitytypeOptions
      );
    } else {
      setSelectedFacilityTypeOptions(
        selected.filter((option) => option.value !== allOption.value)
      );
    }
    setfacilitytype(true);
  };

  const removeCityByIndex = (index) => {
    if (index >= 0 && index < selectedCityOptions.length) {
      const newData = [...selectedCityOptions];
      newData.splice(index, 1);
      setSelectedCityOptions(newData);
    }
  };

  const removeStateByIndex = (index) => {
    if (index >= 0 && index < selectedStateOptions.length) {
      const newData = [...selectedStateOptions];
      newData.splice(index, 1);
      setSelectedStateOptions(newData);
    }
  };

  const removeCountryByIndex = (index) => {
    if (index >= 0 && index < selectedCountryOptions.length) {
      const newData = [...selectedCountryOptions];
      newData.splice(index, 1);
      setSelectedCountryOptions(newData);
    }
  };
  const removeFacilitytypeByIndex = (index) => {
    if (index >= 0 && index < selectedFacilityTypeOptions.length) {
      const newData = [...selectedFacilityTypeOptions];
      newData.splice(index, 1);
      setSelectedFacilityTypeOptions(newData);
    }
  };
  const removeUserByIndex = (index) => {
    if (index >= 0 && index < selectedUserOptions.length) {
      const newData = [...selectedUserOptions];
      newData.splice(index, 1);
      setselectedUserOptions(newData);
    }
  };
  const removeSubscriptionPlansByIndex = (index) => {
    if (index >= 0 && index < selectedSubscriptionPlansOptions.length) {
      const newData = [...selectedSubscriptionPlansOptions];
      newData.splice(index, 1);
      setSelectedSubscriptionPlansOptions(newData);
    }
  };

  const displayCityValue = isSelectAllSelected(selectedCityOptions, cityOptions)
    ? [allOption]
    : selectedCityOptions;
  const displayStateValue = isSelectAllSelected(
    selectedStateOptions,
    stateOptions
  )
    ? [allOption]
    : selectedStateOptions;
  const displayCountryValue = isSelectAllSelected(
    selectedCountryOptions,
    countryOptions
  )
    ? [allOption]
    : selectedCountryOptions;
  const displayFacilitytypeValue = isSelectAllSelected(
    selectedFacilityTypeOptions,
    facilitytypeOptions
  )
    ? [allOption]
    : selectedFacilityTypeOptions;
  const displayUserValue = isSelectAllSelected(selectedUserOptions, userOptions)
    ? [allOption]
    : selectedUserOptions;

  const displaySubscriptionValue = isSelectAllSelected(
    selectedSubscriptionPlansOptions,
    SubscriptionOptions
  )
    ? [allOption]
    : selectedSubscriptionPlansOptions;

  return (
    <div>
      <>
        <section className="owner-dashboard-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="owner-dashboard-wrapper d-flex flex-wrap align-items-start">
                  <div className={`dashboard-filter ${classNameAdded}`}>
                    <div className="filter-modal d-block d-md-none">
                      <div className="modal-header flex-wrap text-md-center">
                        <div className="modal-header-top d-flex w-100 align-items-center justify-content-between mb-4">
                          <a
                            role="button"
                            onClick={() => setclassNameAdded("")}
                          >
                            <img src={backImgBlack} alt="" />
                          </a>
                          <div className="filter-right d-flex align-items-center">
                            <a
                              className="cancel-btn"
                              role="button"
                              onClick={(e) => {
                                e.preventDefault();

                                setSelectedCityOptions(
                                  detailData?.city?.map((obj) => ({
                                    label: obj,
                                    value: obj,
                                  })) || []
                                );
                                setSelectedStateOptions(
                                  detailData?.state?.map((obj) => ({
                                    label: obj,
                                    value: obj,
                                  })) || []
                                );
                                setSelectedCountryOptions([
                                  {
                                    label: "United States",
                                    value: "United States",
                                  },
                                ]);
                                setSelectedFacilityTypeOptions([
                                  {
                                    label: "DETOX",
                                    value: "detox",
                                  },
                                  {
                                    label: "RESIDENTIAL",
                                    value: "residential",
                                  },
                                  {
                                    label: "PHP",
                                    value: "php",
                                  },
                                  {
                                    label: "IOP",
                                    value: "iop",
                                  },
                                  {
                                    label: "SOBER LIVING",
                                    value: "living",
                                  },
                                ]);
                                setSelectedSubscriptionPlansOptions([
                                  {
                                    label: "Basic",
                                    value: "basic",
                                  },
                                  {
                                    label: "Pro",
                                    value: "pro",
                                  },
                                ]);
                                setselectedUserOptions(
                                  detailData?.name?.map((obj) => ({
                                    label: obj,
                                    value: obj,
                                  })) || []
                                );
                                setdetail_user(false);
                                setcountrydata(false);
                                setstatedata(false);
                                setcitydata(false);
                                setsubscriptiontype(false);
                                setfacilitytype(false);
                                setfacilityNameData(false);
                                setclassNameAdded("");
                              }}
                            >
                              <span>Clear</span>
                            </a>
                            <a
                              className="apply-btn"
                              role="button"
                              onClick={() => setclassNameAdded("")}
                            >
                              Apply
                            </a>
                          </div>
                        </div>
                        <h3 className="w-100 mb-0">Filters</h3>
                      </div>
                    </div>
                    <div className="edit-profile-view taste-recovery-form">
                      <form action="#" autocomplete="off">
                        <div className="form-group-wrapper">
                          <div className="form-group">
                            <label for="">Facility Type</label>
                            {facilitytypeOptions && (
                              <Select
                                isMulti
                                className="form-select"
                                options={[allOption, ...facilitytypeOptions]}
                                value={displayFacilitytypeValue}
                                onChange={handleFacilitytypeChange}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option: (props) => (
                                    <components.Option
                                      {...props}
                                      className="d-flex align-item-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isOptionSelected(
                                          props.data,
                                          selectedFacilityTypeOptions
                                        )}
                                        onChange={() => null}
                                      />
                                      <label className="dropdown-label pl-2 mb-0">
                                        {props.label}
                                      </label>
                                    </components.Option>
                                  ),
                                }}
                              />
                            )}
                          </div>

                          <div className="form-group">
                            <label for="">Subscription Plans</label>
                            {SubscriptionOptions && (
                              <Select
                                isMulti
                                options={[allOption, ...SubscriptionOptions]}
                                value={displaySubscriptionValue}
                                onChange={handleSubscriptionChange}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className="form-select"
                                components={{
                                  Option: (props) => (
                                    <components.Option
                                      {...props}
                                      className="d-flex align-item-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isOptionSelected(
                                          props.data,
                                          selectedSubscriptionPlansOptions
                                        )}
                                        onChange={() => null}
                                      />
                                      <label className="dropdown-label pl-2 mb-0">
                                        {props.label}
                                      </label>
                                    </components.Option>
                                  ),
                                }}
                              />
                            )}
                          </div>

                          <div className="form-group">
                            <label for="">Facility Name</label>
                            {userOptions && (
                              <Select
                                isMulti
                                options={[allOption, ...userOptions]}
                                value={displayUserValue}
                                onChange={handleFacilityNameChange}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className="form-select"
                                components={{
                                  Option: (props) => (
                                    <components.Option
                                      {...props}
                                      className="d-flex align-item-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isOptionSelected(
                                          props.data,
                                          selectedUserOptions
                                        )}
                                        onChange={() => null}
                                      />
                                      <label className="pl-1 mb-1">
                                        {props.label}
                                      </label>
                                    </components.Option>
                                  ),
                                }}
                              />
                            )}
                          </div>

                          <div className="form-group">
                            <label for="">City</label>

                            {cityOptions && (
                              <Select
                                isMulti
                                options={[allOption, ...cityOptions]}
                                value={displayCityValue}
                                onChange={handleCityChange}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className="form-select"
                                components={{
                                  Option: (props) => (
                                    <components.Option
                                      {...props}
                                      className="d-flex align-item-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isOptionSelected(
                                          props.data,
                                          selectedCityOptions
                                        )}
                                        onChange={() => null}
                                      />
                                      <label className="dropdown-label pl-2 mb-0">
                                        {props.label}
                                      </label>
                                    </components.Option>
                                  ),
                                }}
                              />
                            )}
                          </div>

                          <div className="form-group">
                            <label for="">State</label>
                            {stateOptions && (
                              <Select
                                isMulti
                                options={[allOption, ...stateOptions]}
                                value={displayStateValue}
                                onChange={handleStateChange}
                                closeMenuOnSelect={false}
                                className="form-select"
                                hideSelectedOptions={false}
                                components={{
                                  Option: (props) => (
                                    <components.Option
                                      {...props}
                                      className="d-flex align-item-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isOptionSelected(
                                          props.data,
                                          selectedStateOptions
                                        )}
                                        onChange={() => null}
                                      />
                                      <label className="dropdown-label pl-2 mb-0">
                                        {props.label}
                                      </label>
                                    </components.Option>
                                  ),
                                }}
                              />
                            )}
                          </div>

                          <div className="form-group">
                            <label for="">Country</label>
                            {countryOptions && (
                              <Select
                                isMulti
                                options={[allOption, ...countryOptions]}
                                value={displayCountryValue}
                                onChange={handleCountryChange}
                                closeMenuOnSelect={false}
                                className="form-select"
                                hideSelectedOptions={false}
                                components={{
                                  Option: (props) => (
                                    <components.Option
                                      {...props}
                                      className="d-flex align-item-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isOptionSelected(
                                          props.data,
                                          selectedCountryOptions
                                        )}
                                        onChange={() => null}
                                      />
                                      <label className="dropdown-label pl-2 mb-0">
                                        {props.label}
                                      </label>
                                    </components.Option>
                                  ),
                                }}
                              />
                            )}
                          </div>

                          {citydata ||
                          statedata ||
                          countrydata ||
                          detail_user ||
                          facilitytype ||
                          subscriptiontype ||
                          facilityNameData ||
                          phoneDataDetail?.setData?.name ? (
                            <div className="selected-filters-wrapper mb-4">
                              <h3>Selected Filters</h3>
                              {facilitytype === true ? (
                                <>
                                  {" "}
                                  <h4>Facility Type</h4>
                                  <div className="selected-filters d-flex flex-wrap align-items-center">
                                    {selectedFacilityTypeOptions?.length >
                                      0 && (
                                      <>
                                        {facilitytypeOptions?.length ===
                                        selectedFacilityTypeOptions?.length ? (
                                          <>
                                            <div className="chip d-flex align-items-center">
                                              <span>All</span>
                                              <span
                                                onClick={() => {
                                                  setSelectedFacilityTypeOptions(
                                                    [
                                                      {
                                                        label: "DETOX",
                                                        value: "detox",
                                                      },
                                                      {
                                                        label: "RESIDENTIAL",
                                                        value: "residential",
                                                      },
                                                      {
                                                        label: "PHP",
                                                        value: "php",
                                                      },
                                                      {
                                                        label: "IOP",
                                                        value: "iop",
                                                      },
                                                      {
                                                        label: "SOBER LIVING",
                                                        value: "living",
                                                      },
                                                    ]
                                                  );
                                                  setfacilitytype(false);
                                                }}
                                                className="d-flex align-items-cente"
                                              >
                                                <img
                                                  src={canclePrimaryImg}
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          selectedFacilityTypeOptions?.map(
                                            (obj, index) => {
                                              return (
                                                <div className="chip d-flex align-items-center">
                                                  <span>
                                                    {obj?.value?.toUpperCase()}
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      removeFacilitytypeByIndex(
                                                        index
                                                      );
                                                    }}
                                                    className="d-flex align-items-cente"
                                                  >
                                                    <img
                                                      src={canclePrimaryImg}
                                                      alt=""
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>{" "}
                                </>
                              ) : (
                                ""
                              )}

                              {subscriptiontype === true ? (
                                <>
                                  <h4>Subscription Plans</h4>
                                  <div className="selected-filters d-flex flex-wrap align-items-center">
                                    {selectedSubscriptionPlansOptions?.length >
                                      0 && (
                                      <>
                                        {SubscriptionOptions?.length ===
                                        selectedSubscriptionPlansOptions?.length ? (
                                          <>
                                            <div className="chip d-flex align-items-center">
                                              <span>All</span>
                                              <span
                                                onClick={() => {
                                                  setSelectedSubscriptionPlansOptions(
                                                    [
                                                      {
                                                        label: "Basic",
                                                        value: "basic",
                                                      },
                                                      {
                                                        label: "Pro",
                                                        value: "pro",
                                                      },
                                                    ]
                                                  );

                                                  setsubscriptiontype(false);
                                                }}
                                                className="d-flex align-items-cente"
                                              >
                                                <img
                                                  src={canclePrimaryImg}
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          selectedSubscriptionPlansOptions?.map(
                                            (obj, index) => {
                                              return (
                                                <div className="chip d-flex align-items-center">
                                                  <span>
                                                    {obj?.label?.toUpperCase()}
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      removeSubscriptionPlansByIndex(
                                                        index
                                                      );
                                                    }}
                                                    className="d-flex align-items-cente"
                                                  >
                                                    <img
                                                      src={canclePrimaryImg}
                                                      alt=""
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {facilityNameData === true ? (
                                <>
                                  {" "}
                                  <h4>Facility Name</h4>
                                  <div className="selected-filters d-flex flex-wrap align-items-center">
                                    {selectedUserOptions?.length > 0 && (
                                      <>
                                        {userOptions?.length ===
                                        selectedUserOptions?.length ? (
                                          <>
                                            <div className="chip d-flex align-items-center">
                                              <span>All</span>
                                              <span
                                                onClick={() => {
                                                  setselectedUserOptions(
                                                    detailData?.name?.map(
                                                      (obj) => ({
                                                        label: obj,
                                                        value: obj,
                                                      })
                                                    ) || []
                                                  );
                                                  setfacilityNameData(false);
                                                }}
                                                className="d-flex align-items-cente"
                                              >
                                                <img
                                                  src={canclePrimaryImg}
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          selectedUserOptions?.map(
                                            (obj, index) => {
                                              return (
                                                <div className="chip d-flex align-items-center">
                                                  <span>
                                                    {obj?.value?.toUpperCase()}
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      removeUserByIndex(index);
                                                    }}
                                                    className="d-flex align-items-cente"
                                                  >
                                                    <img
                                                      src={canclePrimaryImg}
                                                      alt=""
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>{" "}
                                </>
                              ) : (
                                ""
                              )}

                              {citydata === true ? (
                                <>
                                  {" "}
                                  <h4>City</h4>
                                  <div className="selected-filters d-flex flex-wrap align-items-center">
                                    {selectedCityOptions?.length > 0 && (
                                      <>
                                        {cityOptions?.length ===
                                        selectedCityOptions?.length ? (
                                          <>
                                            <div className="chip d-flex align-items-center">
                                              <span>All</span>
                                              <span
                                                onClick={() => {
                                                  setSelectedCityOptions(
                                                    detailData?.city?.map(
                                                      (obj) => ({
                                                        label: obj,
                                                        value: obj,
                                                      })
                                                    ) || []
                                                  );
                                                  setcitydata(false);
                                                }}
                                                className="d-flex align-items-cente"
                                              >
                                                <img
                                                  src={canclePrimaryImg}
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          selectedCityOptions?.map(
                                            (obj, index) => {
                                              return (
                                                <div className="chip d-flex align-items-center">
                                                  <span>
                                                    {obj?.value?.toUpperCase()}
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      removeCityByIndex(index);
                                                    }}
                                                    className="d-flex align-items-cente"
                                                  >
                                                    <img
                                                      src={canclePrimaryImg}
                                                      alt=""
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {statedata === true ? (
                                <>
                                  <h4>State</h4>
                                  <div className="selected-filters d-flex flex-wrap align-items-center">
                                    {selectedStateOptions?.length > 0 && (
                                      <>
                                        {stateOptions?.length ===
                                        selectedStateOptions?.length ? (
                                          <>
                                            <div className="chip d-flex align-items-center">
                                              <span>All</span>
                                              <span
                                                onClick={() => {
                                                  setSelectedStateOptions(
                                                    detailData?.state?.map(
                                                      (obj) => ({
                                                        label: obj,
                                                        value: obj,
                                                      })
                                                    ) || []
                                                  );
                                                  setstatedata(false);
                                                }}
                                                className="d-flex align-items-cente"
                                              >
                                                <img
                                                  src={canclePrimaryImg}
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          selectedStateOptions?.map(
                                            (obj, index) => {
                                              return (
                                                <div className="chip d-flex align-items-center">
                                                  <span>
                                                    {obj?.value?.toUpperCase()}
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      removeStateByIndex(index);
                                                    }}
                                                    className="d-flex align-items-cente"
                                                  >
                                                    <img
                                                      src={canclePrimaryImg}
                                                      alt=""
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {countrydata === true ? (
                                <>
                                  {" "}
                                  <h4>Country</h4>
                                  <div className="selected-filters d-flex flex-wrap align-items-center mb-0">
                                    {selectedCountryOptions?.length > 0 && (
                                      <>
                                        {countryOptions?.length ===
                                        selectedCountryOptions?.length ? (
                                          <>
                                            <div className="chip d-flex align-items-center">
                                              <span>All</span>
                                              <span
                                                onClick={() => {
                                                  setSelectedCountryOptions([
                                                    {
                                                      label: "United States",
                                                      value: "United States",
                                                    },
                                                  ]);
                                                  setcountrydata(false);
                                                }}
                                                className="d-flex align-items-cente"
                                              >
                                                <img
                                                  src={canclePrimaryImg}
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          selectedCountryOptions?.map(
                                            (obj, index) => {
                                              return (
                                                <div className="chip d-flex align-items-center">
                                                  <span>
                                                    {obj?.value?.toUpperCase()}
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      removeCountryByIndex(
                                                        index
                                                      );
                                                    }}
                                                    className="d-flex align-items-cente"
                                                  >
                                                    <img
                                                      src={canclePrimaryImg}
                                                      alt=""
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {citydata ||
                        statedata ||
                        countrydata ||
                        detail_user ||
                        facilitytype ||
                        subscriptiontype ||
                        facilityNameData ? (
                          <div className="filter-action d-none d-md-block">
                            <button
                              onClick={(e) => {
                                e.preventDefault();

                                setSelectedCityOptions(
                                  detailData?.city?.map((obj) => ({
                                    label: obj,
                                    value: obj,
                                  })) || []
                                );
                                setSelectedStateOptions(
                                  detailData?.state?.map((obj) => ({
                                    label: obj,
                                    value: obj,
                                  })) || []
                                );
                                setSelectedCountryOptions([
                                  {
                                    label: "United States",
                                    value: "United States",
                                  },
                                ]);
                                setSelectedFacilityTypeOptions([
                                  {
                                    label: "DETOX",
                                    value: "detox",
                                  },
                                  {
                                    label: "RESIDENTIAL",
                                    value: "residential",
                                  },
                                  {
                                    label: "PHP",
                                    value: "php",
                                  },
                                  {
                                    label: "IOP",
                                    value: "iop",
                                  },
                                  {
                                    label: "SOBER LIVING",
                                    value: "living",
                                  },
                                ]);
                                setSelectedSubscriptionPlansOptions([
                                  {
                                    label: "Basic",
                                    value: "basic",
                                  },
                                  {
                                    label: "Pro",
                                    value: "pro",
                                  },
                                ]);
                                setselectedUserOptions(
                                  detailData?.name?.map((obj) => ({
                                    label: obj,
                                    value: obj,
                                  })) || []
                                );
                                setdetail_user(false);
                                setcountrydata(false);
                                setstatedata(false);
                                setcitydata(false);
                                setsubscriptiontype(false);
                                setfacilitytype(false);
                                setfacilityNameData(false);
                              }}
                              className="btn btn-primary-outline btn-lg radius-20 w-100"
                            >
                              Clear Filters
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </div>
                  <div className="dashboard-content-wrapper d-flex flex-column">
                    <div className="filter-modal d-block d-md-none">
                      <div className="modal-header flex-wrap text-md-center">
                        <div className="modal-header-top d-flex w-100 align-items-center justify-content-between mb-4">
                          {phoneDataDetail?.setData?.url ? (
                            <>
                              <a
                                role="button"
                                href={phoneDataDetail?.setData?.url}
                              >
                                <img src={backImgBlack} alt="" />
                              </a>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="filter-right d-flex align-items-center">
                            <a
                              className="apply-btn"
                              role="button"
                              onClick={() => setclassNameAdded("show")}
                            >
                              FILTERS
                            </a>
                          </div>
                        </div>
                        <h3 className="w-100 mb-0">DASHBOARD</h3>
                      </div>
                    </div>
                    <div className="dashboard-content d-flex flex-column">
                      <div className="dashboard-widget-wrapper d-flex flex-wrap">
                        <div className="dashboard-widget d-flex align-items-center">
                          <div className="widget-left">
                            <div className="widget-icon d-flex align-items-center justify-content-center">
                              <img src={widgetView} alt="" />
                            </div>
                          </div>
                          <div className="widget-right">
                            <h3>
                              {Data?.ppcData?.ppcViews
                                ? Data?.ppcData?.ppcViews
                                : 0}
                            </h3>
                            <p>PPC Views</p>
                          </div>
                        </div>
                        <div className="dashboard-widget d-flex align-items-center">
                          <div className="widget-left">
                            <div className="widget-icon d-flex align-items-center justify-content-center">
                              <img src={widgetPPC} alt="" />
                            </div>
                          </div>
                          <div className="widget-right">
                            <h3>
                              ${" "}
                              {Data?.ppcData?.totalBudget
                                ? addCommasToPrice(Data?.ppcData?.totalBudget)
                                : 0}
                            </h3>
                            <p>PPC Budget</p>
                          </div>
                        </div>
                        <div className="dashboard-widget d-flex align-items-center">
                          <div className="widget-left">
                            <div className="widget-icon d-flex align-items-center justify-content-center">
                              <img src={widgetstar} alt="" />
                            </div>
                          </div>
                          <div className="widget-right">
                            <h3>
                              {Data?.ctrImpressions?.facilityCTR
                                ? Data?.ctrImpressions?.facilityCTR?.toFixed(1)
                                : 0}
                              %
                            </h3>
                            <p>Facility CTR</p>
                          </div>
                        </div>
                        <div className="dashboard-widget d-flex align-items-center">
                          <div className="widget-left">
                            <div className="widget-icon d-flex align-items-center justify-content-center">
                              <img src={widgetPhone} alt="" />
                            </div>
                          </div>
                          <div className="widget-right">
                            <h3>
                              {Data?.ctrImpressions?.phoneCTR
                                ? Data?.ctrImpressions?.phoneCTR?.toFixed(1)
                                : 0}
                              %
                            </h3>
                            <p>Phone Number CTR</p>
                          </div>
                        </div>
                        <div className="dashboard-widget d-flex align-items-center">
                          <div className="widget-left">
                            <div className="widget-icon d-flex align-items-center justify-content-center">
                              <img src={widgetWebsite} alt="" />
                            </div>
                          </div>
                          <div className="widget-right">
                            <h3>
                              {Data?.ctrImpressions?.websiteCTR
                                ? Data?.ctrImpressions?.websiteCTR?.toFixed(1)
                                : 0}
                              %
                            </h3>
                            <p>Website Link CTR</p>
                          </div>
                        </div>
                        <div className="dashboard-widget d-flex align-items-center">
                          <div className="widget-left">
                            <div className="widget-icon d-flex align-items-center justify-content-center">
                              <img src={widgetChat} alt="" />
                            </div>
                          </div>
                          <div className="widget-right">
                            <h3>
                              {Data?.ctrImpressions?.chatCTR
                                ? Data?.ctrImpressions?.chatCTR?.toFixed(1)
                                : 0}
                              %
                            </h3>
                            <p>Chat CTR</p>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-card">
                        <div className="card-header">
                          <h3>PPC Views</h3>
                        </div>
                        <div className="card-content">
                          {PPCView?.length > 0 ? (
                            <>
                              <Bar
                                data={PPCViewData}
                                options={PPCViewOption}
                                height={isMobile() ? 350 : ""}
                              />
                            </>
                          ) : (
                            <>
                              <img src={NodataPPCvive} alt="" />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="dashboard-card">
                        <div className="card-header">
                          <h3>Facility Click-Through Rates (CTR)</h3>
                        </div>
                        <div className="card-content">
                          {/* <img src={facilityGraph} alt="" /> */}
                          {FacilityCTR?.length > 0 ? (
                            <Line
                              data={FacilityCTRData}
                              options={optionsFacilityCTRData}
                              height={isMobile() ? 300 : ""}
                            />
                          ) : (
                            <>
                              <img src={nodataImage} alt="" />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="dashboard-card">
                        <div className="card-header">
                          <h3>Website Link CTR</h3>
                        </div>
                        <div className="card-content">
                          {WebsiteCTR?.length > 0 ? (
                            <Bar
                              data={WebsiteLinkCTRData}
                              options={WebsiteLinkCTROption}
                              height={isMobile() ? 300 : ""}
                            />
                          ) : (
                            <>
                              <img src={nodataImage} alt="" />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="dashboard-card">
                        <div className="card-header">
                          <h3>Phone Number CTR</h3>
                        </div>
                        <div className="card-content">
                          {phoneCTR?.length > 0 ? (
                            <Line
                              data={phoneData}
                              options={optionsphoneData}
                              height={isMobile() ? 300 : ""}
                            />
                          ) : (
                            <>
                              <img src={nodataImage} alt="" />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="dashboard-card">
                        <div className="card-header">
                          <h3>Chat CTR</h3>
                        </div>
                        <div className="card-content">
                          {ChatCTR?.length > 0 ? (
                            <Bar
                              data={ChatCTRData}
                              options={ChatCTROption}
                              height={isMobile() ? 300 : ""}
                            />
                          ) : (
                            <>
                              <img src={nodataImage} alt="" />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default DashboardIndex;
