import React, { useEffect, useState } from 'react';

export const Tooltip = ({ message, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [disableHover, setDisableHover] = useState(false);  // State to disable hover when clicked

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (windowWidth > 767) {
                setDisableHover(false);  // Enable hover when window is resized above 767
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    const handleMouseToggle = () => {
        if (windowWidth > 767 && !disableHover) {
            setShowTooltip(!showTooltip);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (windowWidth <= 767) {
            setDisableHover(true);  // Disable hover after click
            setShowTooltip(!showTooltip);
            setTimeout(() => setDisableHover(false), 500);  // Re-enable hover after a delay if needed
        }
    };

    return (
        <div className="tooltip-container">
            <div
                className='tooltip-container-btn'
                onMouseEnter={handleMouseToggle}
                onMouseLeave={handleMouseToggle}
                onClick={handleClick}
            >
                {children}
                {showTooltip && <div className='custom-tooltip-block'><p>{message}</p></div>}
            </div>
        </div>
    );
}