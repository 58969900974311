import React, { Component } from "react";
import starImg from "../../assets/images/star-white.svg";
import starFillImg from "../../assets/images/star-24px.svg";
import pinImg from "../../assets/images/ic_pin_small.svg";
import defaultImg from "../../assets/images/default.png";
import NoImage from "../../assets/images/no-image.svg";
import {
  getCityName,
  getLocationFromItem,
  getPriceFromItem,
  getRatingsFromItem,
  Ucfirst,
} from "../../utils";
import { Link, withRouter } from "react-router-dom";
import { seoUrlText } from "../../constants";

import editImg from "../../assets/images/edit-primary.svg";
import editImgMobile from "../../assets/images/edit.svg";
import deleteImg from "../../assets/images/delete-primary.svg";

import surveyImg from "../../assets/images/survey.svg";

import DeleteConfirmationModal from "../modal/DeleteConfirmationModal";
import PayPerClick from "../modal/payPerClick.component";
import promotionsImg from "../../assets/images/promotions.svg";
class SoberLivingProfileCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      ratingStr: "",
      priceStr: "",
      locationStr: "",
      heading: "",
      paymentPeriod: "",
      stateStr: "",
      isPromoted: false,
      objFit: "cover",
      isSubscribed: "",
      openDeletemodal: "",
      openPPC: false,
    };
    this.onCardClick = this.onCardClick.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
  }

  componentDidMount() {
    const item = this?.props?.item;
    this.setState({
      imageUrl: this.getImageUrlFromItem(),
      ratingStr: getRatingsFromItem(item),
      priceStr: getPriceFromItem(item),
      heading: item?.name,
      locationStr: getLocationFromItem(item),
      paymentPeriod: Ucfirst(item?.paymentPeriod),
      stateStr: item?.geocodingResult?.stateLong,
      isPromoted: item?.isPromoted,
      isSubscribed: item?.isSubscribed,
    });
  }
  openDeleteModal = () => {
    this.setState({ openDeletemodal: true });
  };

  closeDeleteModal = () => {
    this.setState({ openDeletemodal: false });
  };
  deleteLiving = (id) => {};

  render() {
    const isTextLong = this.state?.locationStr?.length > 20;

    const { showPromoted = true } = this?.props;
    let city = getCityName(this?.props?.item?.geocodingResult);
    return (
      <>
        <DeleteConfirmationModal
          open={this.state.openDeletemodal}
          onCancel={this.closeDeleteModal}
          onDelete={this.props?.onDeleteLiving}
        />

        <PayPerClick
          open={this.state.openPPC}
          onClosePPC={() => this.setState({ openPPC: false })}
          id={this?.props?.item?.id}
          isPPCSubscribed={this?.props?.item?.isPPCSubscribed}
          houseType="sober living"
          ppcPerClickPrice={this?.props?.item?.ppcPerClickPrice}
        />

        <div className="sober-living-slider_item w-100">
          <Link
            className="card text-decoration-none d-flex flex-wrap flex-row"
            to={{
              pathname: `../../living/${seoUrlText}-${city}&id=${this?.props?.item?.id}`,
              state: { property: this?.props?.item },
            }}
          >
            <div
              className="card-img"
              onClick={() => {
                this.deleteLiving(this?.props?.item?.id);
              }}
            >
              <img
                className="img-fluid"
                src={this.state.imageUrl ? this.state.imageUrl : NoImage}
                style={{ objectFit: this.state.objFit }}
                onLoad={this.onImgLoad}
                onError={() => this.setState({ imageUrl: defaultImg })}
                alt=""
              />
              {this?.props?.pageName === "Profile" ? (
                <div>
                  {this?.props?.isHost == true ? (
                    this.state.isSubscribed === true ? (
                      <span className="subscription-msg">Pro</span>
                    ) : (
                      <span className="subscription-msg unsubscribed">
                        Basic
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {!this?.props?.isRecommended ? (
              <div className="card-body">
                <div className="property-wrapper d-flex flex-column flex-md-row justify-content-between w-100 h-100 border-0 pr-0">
                  <div className="card-content-left d-flex flex-column py-2">
                    <div className="card-heading">
                      <span>{this.state.heading.toUpperCase()}</span>
                      <span className="card-actions d-flex d-md-none align-items-center">
                        <button
                          className="owner-card-action-button d-flex d-md-none"
                          onClick={(e) => {
                            this.openDeleteModal();
                            e.preventDefault();
                          }}
                        >
                          <img src={deleteImg} className="img-fluid" alt="" />
                        </button>
                      </span>
                    </div>
                    <span className="location">
                      <img src={pinImg} className="img-fluid" alt="" />{" "}
                      {isTextLong
                        ? `${this.state.locationStr.slice(0, 20)}...`
                        : this.state.locationStr}
                    </span>
                    {this.state.isSubscribed == true && (
                      <span className="location star-rating">
                        <img src={starImg} className="img-fluid" alt="" />{" "}
                        {this.state.ratingStr}
                      </span>
                    )}

                    <div className="card-price">
                      {" "}
                      ${`${this.state.priceStr.split("/")[0]}`}
                      <span>{`${this.state.priceStr.split("/")[1]}`}</span>
                    </div>
                  </div>
                  <div className="card-content-right d-flex flex-column justify-content-between">
                    {this?.props?.showActionItems && (
                      <span className="card-actions">
                        <button
                          className="owner-card-action-button"
                          onClick={(e) => {
                            this.props.onScheduleTour();
                            e.preventDefault();
                          }}
                        >
                          <svg
                            id="baseline-calendar_today-24px"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              id="Path"
                              d="M0,0H24V24H0Z"
                              fill="none"
                            ></path>
                            <path
                              id="Shape"
                              className="fill-change"
                              d="M18,22H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H3V0H5V2H15V0h2V2h1a2,2,0,0,1,2,2V20A2,2,0,0,1,18,22ZM2,7V20H18V7H2Z"
                              transform="translate(2 1)"
                              fill="#D7D7DB"
                            ></path>
                          </svg>
                        </button>
                        <button
                          className="owner-card-action-button"
                          onClick={(e) => {
                            this.props.history.push(
                              `/living/edit-living/${this?.props?.item?.id}`
                            );
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={editImg}
                            className="img-fluid d-none d-md-block"
                            alt=""
                          />
                          <img
                            src={editImgMobile}
                            className="img-fluid d-block d-md-none"
                            alt=""
                          />
                        </button>
                        <button
                          className="owner-card-action-button d-none d-md-flex"
                          onClick={(e) => {
                            this.openDeleteModal();
                            e.preventDefault();
                          }}
                        >
                          <img src={deleteImg} className="img-fluid" alt="" />
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="property-wrapper d-flex flex-column flex-md-row justify-content-between w-100 h-100 border-0 pr-0">
                  <div className="card-content-left d-flex flex-column py-2">
                    <div className="card-heading">
                      <span>{this.state.heading.toUpperCase()}</span>
                      <span className="card-actions d-flex d-md-none align-items-center">
                        <button
                          className="owner-card-action-button d-flex d-md-none"
                          onClick={(e) => {
                            this.openDeleteModal();
                            e.preventDefault();
                          }}
                        >
                          <img src={deleteImg} className="img-fluid" alt="" />
                        </button>
                      </span>
                    </div>
                    <span className="location">
                      <img src={pinImg} className="img-fluid" alt="" />{" "}
                      {isTextLong
                        ? `${this.state.locationStr.slice(0, 20)}...`
                        : this.state.locationStr}
                    </span>
                    {this.state.isSubscribed == true && (
                      <span className="location star-rating">
                        <img src={starFillImg} className="img-fluid" alt="" />{" "}
                        {this.state.ratingStr}
                      </span>
                    )}

                    <div className="card-price">
                      {" "}
                      ${`${this.state.priceStr?.split("/")[0]}`}
                      <span>{`${this.state.priceStr.split("/")[1]}`}</span>
                    </div>
                  </div>
                  <div className="card-content-right d-flex flex-column justify-content-between">
                    {this?.props?.showActionItems && (
                      <span className="card-actions">
                        <button
                          className="owner-card-action-button"
                          onClick={(e) => {
                            this.props.onScheduleTour();
                            e.preventDefault();
                          }}
                        >
                          <svg
                            id="baseline-calendar_today-24px"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              id="Path"
                              d="M0,0H24V24H0Z"
                              fill="none"
                            ></path>
                            <path
                              id="Shape"
                              className="fill-change"
                              d="M18,22H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H3V0H5V2H15V0h2V2h1a2,2,0,0,1,2,2V20A2,2,0,0,1,18,22ZM2,7V20H18V7H2Z"
                              transform="translate(2 1)"
                              fill="#D7D7DB"
                            ></path>
                          </svg>
                        </button>
                        <button
                          className="owner-card-action-button"
                          onClick={(e) => {
                            this.props.history.push(
                              `/living/edit-living/${this?.props?.item?.id}`
                            );
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={editImg}
                            className="img-fluid d-none d-md-block"
                            alt=""
                          />
                          <img
                            src={editImgMobile}
                            className="img-fluid d-block d-md-none"
                            alt=""
                          />
                        </button>
                        <button
                          className="owner-card-action-button d-none d-md-flex"
                          onClick={(e) => {
                            this.openDeleteModal();
                            e.preventDefault();
                          }}
                        >
                          <img src={deleteImg} className="img-fluid" alt="" />
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="card-btn-container mb-md-2">
             
                <button
                  className="btn btn-primary-outline radius-20"
                  data-placement="top"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ openPPC: true });
                  }}
                >
                  <img
                    src={promotionsImg}
                    className="img-fluid d-block d-md-none"
                    alt=""
                  />
                  Promote facility
                </button>
           
            </div>
          </Link>
        </div>
      </>
    );
  }

  getImageUrlFromItem() {
    const item = this?.props?.item;
    if (!item || !item?.photos || item?.photos?.length === 0) {
      return " ";
    }
    const mainImage = item?.photos?.find((photo) => photo?.isMain);
    if (!mainImage.thumbnails || mainImage.thumbnails.length === 0) {
      return mainImage.path;
    }

    return mainImage.thumbnails["thumb360w"];
  }

  onCardClick() {
    let city = getCityName(this?.props?.item?.geocodingResult);
    this.props.history.push(
      `living/sober-living-in${city}&id=${this?.props?.item?.id}`
    );
  }

  onImgLoad({ target: img }) {
    try {
      if (img.naturalWidth < img.offsetWidth) {
        this.setState({ objFit: "cover" });
      } else {
        this.setState({ objFit: "cover" });
      }
    } catch (e) {}
  }
}

export default withRouter(SoberLivingProfileCard);
