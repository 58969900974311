import adhd from '../../../assets/images/adhd.svg';
import alcohol from '../../../assets/images/Alcohol.svg';
import anger from '../../../assets/images/Anger.svg';
import anxiety from '../../../assets/images/Anxiety.svg';
import benzodiazepines from "../../../assets/images/benzodiazepines.svg";
import bipolar from "../../../assets/images/Bipolar.svg";
import burnout from "../../../assets/images/Burnout.svg";
import chronicPainManagement from "../../../assets/images/Chronic Pain Management.svg";
import chronicRelaps from "../../../assets/images/chronic Relaps.svg";
import cooccurringDisorders from "../../../assets/images/Co occurring Disorders.svg";
import cocaine from "../../../assets/images/Cocaine.svg";
import codependency from "../../../assets/images/Codependency.svg";
import depression from "../../../assets/images/Depression.svg";
import drugAddiction from "../../../assets/images/Drug Addiction.svg";
import eatingDisorders from "../../../assets/images/Eating Disorders.svg";
import ecstasy from "../../../assets/images/Ecstasy.svg";
import gambling from "../../../assets/images/Gambling.svg";
import gaming from "../../../assets/images/gaming.svg";
import griefAndLoss from "../../../assets/images/Grief AndLoss.svg";
import heroin from "../../../assets/images/heroin.svg";
import internetAddiction from "../../../assets/images/Internet Addiction.svg";
import marijuana from "../../../assets/images/marijuana.svg";
import methamphetamine from "../../../assets/images/methamphetamine.svg";
import nicotine from "../../../assets/images/nicotine.svg";
import obsessiveCompulsiveDisorder from "../../../assets/images/obsessive Compulsive Disorder.svg";
import opioids from "../../../assets/images/opioids.svg";
import personalityDisorder from "../../../assets/images/personality Disorder.svg";
import pornography from "../../../assets/images/pornography.svg";
import postTraumaticStressDisorder from "../../../assets/images/post Traumatic Stress Disorder.svg";
import prescriptionDrugs from "../../../assets/images/prescription Drugs.svg";
import psychedelics from "../../../assets/images/psychedelics.svg";
import schizophrenia from "../../../assets/images/schizophrenia.svg";
import sexAddiction from "../../../assets/images/sex Addiction.svg";
import sexHarm from "../../../assets/images/sex Harm.svg";
import shopping from "../../../assets/images/shopping.svg";
import smokingCessation from "../../../assets/images/smoking Cessation.svg";
import stress from "../../../assets/images/stress.svg";
import suicidality from "../../../assets/images/suicidality.svg";
import syntheticDrugs from "../../../assets/images/synthetic drugs.svg";
import trauma from "../../../assets/images/trauma.svg"
import weightLoss from "../../../assets/images/weight Loss.svg";

import adhdActive from '../../../assets/images/adhd active.svg';
import alcoholActive from '../../../assets/images/AlcoholActive.svg';
import angerActive from '../../../assets/images/AngerActive.svg';
import anxietyActive from '../../../assets/images/AnxietyActive.svg';
import benzodiazepinesActive from "../../../assets/images/benzodiazepinesActive.svg";
import bipolarActive from "../../../assets/images/BipolarActive.svg";
import burnoutActive from "../../../assets/images/BurnoutActive.svg";
import chronicPainManagementActive from "../../../assets/images/Chronic Pain ManagementActive.svg";
import chronicRelapsActive from "../../../assets/images/chronic RelapsActive.svg";
import cooccurringDisordersActive from "../../../assets/images/Co occurring DisordersActive.svg";
import cocaineActive from "../../../assets/images/CocaineActive.svg";
import codependencyActive from "../../../assets/images/CodependencyActive.svg";
import depressionActive from "../../../assets/images/DepressionActive.svg";
import drugAddictionActive from "../../../assets/images/Drug AddictionActive.svg";
import eatingDisordersActive from "../../../assets/images/Eating DisordersActive.svg";
import ecstasyActive from "../../../assets/images/EcstasyActive.svg";
import gamblingActive from "../../../assets/images/GamblingActive.svg";
import gamingActive from "../../../assets/images/gaming-active.svg"
import griefAndLossActive from "../../../assets/images/Grief AndLossActive.svg";
import heroinActive from "../../../assets/images/heroinActive.svg";
import internetAddictionActive from "../../../assets/images/Internet AddictionActive.svg";
import marijuanaActive from "../../../assets/images/marijuana active.svg";
import methamphetamineActive from "../../../assets/images/methamphetamineActive.svg";
import nicotineActive from "../../../assets/images/nicotineActive.svg";
import obsessiveCompulsiveDisorderActive from "../../../assets/images/obsessive Compulsive DisorderActive.svg";
import opioidsActive from "../../../assets/images/opioidsActive.svg";
import personalityDisorderActive from "../../../assets/images/personality DisorderActive.svg";
import pornographyActive from "../../../assets/images/pornographyActive.svg";
import postTraumaticStressDisorderActive from "../../../assets/images/post Traumatic Stress DisorderActive.svg";
import prescriptionDrugsActive from "../../../assets/images/prescription DrugsActive.svg";
import psychedelicsActive from "../../../assets/images/psychedelicsActive.svg";
import schizophreniaActive from "../../../assets/images/schizophreniaActive.svg";
import sexAddictionActive from "../../../assets/images/sex AddictionActive.svg";
import sexHarmActive from "../../../assets/images/sex HarmActive.svg";
import shoppingActive from "../../../assets/images/shoppingActive.svg";
import smokingCessationActive from "../../../assets/images/smoking CessationActive.svg";
import stressActive from "../../../assets/images/stressActive.svg";
import suicidalityActive from "../../../assets/images/suicidalityActive.svg";
import syntheticDrugsActive from "../../../assets/images/synthetic drugsActive.svg";
import traumaActive from "../../../assets/images/traumaActive.svg"
import weightLossActive from "../../../assets/images/weight LossActive.svg";




export const condiTreatedOptions = [
    { img: adhd, activeImg: adhdActive, label: 'ADHD', value: "adhd" },
    { img: alcohol, activeImg: alcoholActive, label: 'Alcohol', value: "alcohol" },
    { img: anger, activeImg: angerActive, label: 'Anger', value: "anger" },
    { img: anxiety, activeImg: anxietyActive, label: 'Anxiety', value: "anxiety" },
    { img: benzodiazepines, activeImg: benzodiazepinesActive, label: 'Benzodiazepines', value: "benzodiazepines" },
    { img: bipolar, activeImg: bipolarActive, label: 'Bipolar', value: "bipolar" },
    { img: burnout, activeImg: burnoutActive, label: 'Burnout', value: "burnout" },
    { img: chronicPainManagement, activeImg: chronicPainManagementActive, label: 'Chronic Pain Management', value: "chronicPainManagement" },
    { img: chronicRelaps, activeImg: chronicRelapsActive, label: 'Chronic Relapse', value: "chronicRelapse" },
    { img: cooccurringDisorders, activeImg: cooccurringDisordersActive, label: 'Co-occurring Disorders', value: "cooccurringDisorders" },
    { img: cocaine, activeImg: cocaineActive, label: 'Cocaine', value: "cocaine" },
    { img: codependency, activeImg: codependencyActive, label: 'Codependency', value: "codependency" },
    { img: depression, activeImg: depressionActive, label: 'Depression', value: "depression" },
    { img: drugAddiction, activeImg: drugAddictionActive, label: 'Drug Addiction', value: "drugAddiction" },
    { img: eatingDisorders, activeImg: eatingDisordersActive, label: 'Eating Disorders', value: "eatingDisorders" },
    { img: ecstasy, activeImg: ecstasyActive, label: 'Ecstasy', value: "ecstasy" },
    { img: gambling, activeImg: gamblingActive, label: 'Gambling', value: "gambling" },
    { img: gaming, activeImg: gamingActive, label: 'Gaming', value: "gaming" },
    { img: griefAndLoss, activeImg: griefAndLossActive, label: 'Grief And Loss', value: "griefAndLoss" },
    { img: heroin, activeImg: heroinActive, label: 'Heroin', value: "heroin" },
    { img: internetAddiction, activeImg: internetAddictionActive, label: 'Internet Addiction', value: "internetAddiction" },
    { img: marijuana, activeImg: marijuanaActive, label: 'Marijuana', value: "marijuana" },
    { img: methamphetamine, activeImg: methamphetamineActive, label: 'Methamphetamine', value: "methamphetamine" },
    { img: nicotine, activeImg: nicotineActive, label: 'Nicotine', value: "nicotine" },
    { img: obsessiveCompulsiveDisorder, activeImg: obsessiveCompulsiveDisorderActive, label: 'Obsessive Compulsive Disorder', value: "obsessiveCompulsiveDisorder" },
    { img: opioids, activeImg: opioidsActive, label: 'Opioids', value: "opioids" },
    { img: personalityDisorder, activeImg: personalityDisorderActive, label: 'Personality Disorder', value: "personalityDisorder" },
    { img: pornography, activeImg: pornographyActive, label: 'Pornography', value: "pornography" },
    { img: postTraumaticStressDisorder, activeImg: postTraumaticStressDisorderActive, label: 'Post Traumatic Stress Disorder', value: "postTraumaticStressDisorder" },
    { img: prescriptionDrugs, activeImg: prescriptionDrugsActive, label: 'Prescription Drugs', value: "prescriptionDrugs" },
    { img: psychedelics, activeImg: psychedelicsActive, label: 'Psychedelics', value: "psychedelics" },
    { img: schizophrenia, activeImg: schizophreniaActive, label: 'Schizophrenia', value: "schizophrenia" },
    { img: sexAddiction, activeImg: sexAddictionActive, label: 'Sex Addiction', value: "sexAddiction" },
    { img: sexHarm, activeImg: sexHarmActive, label: 'Sex-harm', value: "sexHarm" },
    { img: shopping, activeImg: shoppingActive, label: 'Shopping', value: "shopping" },
    { img: smokingCessation, activeImg: smokingCessationActive, label: 'Smoking Cessation', value: "smokingCessation" },
    { img: stress, activeImg: stressActive, label: 'Stress', value: "stress" },
    { img: suicidality, activeImg: suicidalityActive, label: 'Suicidality', value: "suicidality" },
    { img: syntheticDrugs, activeImg: syntheticDrugsActive, label: 'Synthetic Drugs', value: "syntheticDrugs" },
    { img: trauma, activeImg: traumaActive, label: 'Trauma', value: "trauma" },
    { img: weightLoss, activeImg: weightLossActive, label: 'Weight Loss', value: "weightLoss" }
];
