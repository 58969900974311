import React, { useState } from "react";

const ContentComponent = ({ obj }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };


  const truncatedContent = obj?.about.length > 50 ? `${obj?.about.slice(0, 30)}...` : obj?.about;

  return (
    <div>
      <p>{showFullContent ? obj?.about : truncatedContent}</p>
      {obj?.about.length > 50 ? (
        <strong style={{color:"#000", cursor: "pointer"}} onClick={toggleContent}>
           {showFullContent ? 'Show less' : 'Show more'}
        </strong>
      ) : null}
    </div>
  );
};

export default ContentComponent;
