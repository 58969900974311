import React, { Component } from "react";

import { Modal } from "react-bootstrap";
import ReactHelmet from "../../components/react-helmet/react-helmet";
class TermsOfServiceIos extends Component {
  render() {
    return (
      <>
        <div ref={this.props.newRef}>
          <ReactHelmet
            title={"Taste Recovery - Terms of Service"}
            description={
              "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
            }
          />

          <section
            className={`container savehome-wrp setting-list ${
              this.props.location?.pathname !== "/terms-of-service-ios"
                ? "p-0"
                : ""
            }`}
            id="eight"
          >
            {this.props.location?.pathname === undefined ? (
              <Modal.Header closeButton>
                <Modal.Title>Terms of Service </Modal.Title>
              </Modal.Header>
            ) : (
              <h3 className="main-title title-decoration">Terms of Service </h3>
            )}

            <div className="saved-home-wrappper mt-4 text-justify">
              <div className="cms-wrp">

                <span className="heading">I. DISCLOSURE </span>
                <p>
                  <span className="heading">A. Who We Are </span>
                  Taste Recovery operates this website with the intent of
                  providing useful information to its visitors. By accessing the
                  information contained in this site, visitors expressly
                  acknowledge, and agree to, the terms provided below.
                  <pre>Tasterecovery.com</pre>is not a provider of rehabilitation services
                  or any other healthcare, medical, or psychological services.
                  If you need these services urgently, please call 911.
                </p>
                <p>
                Just as
                  <pre>Tasterecovery.com</pre> does not provide healthcare services, it
                  does not provide financial advice or services. You are
                  responsible for the costs of any rehabilitation services you
                  choose to arrange after consulting                   <pre>Tasterecovery.com</pre>’s
                  website. If you intend to use public or private insurance to
                  cover your care, please confirm that the provider will accept
                  your insurance.
                </p>
                <p>
                  <span className="heading">B. Who Listed Providers Are </span>
                  Listings on Taste Recovery have been added on by the facility
                  themselves to ensure all information is up to date and
                  accurate. To provide users with the widest range of options,
                  we do not evaluate the providers before they are listed. Our
                  philosophy is to give all facilities the opportunity to market
                  their company. We do believe in customer reviews and hope they
                  provide transparency into how the facility operates.
                </p>
                <p>
                All
                  treatment facilities do pay a small monthly fee to be listed
                  on Taste Recovery. Some providers also choose to pay for
                  additional advertising services. Taste Recovery does not
                  recommend a particular provider to you. Instead, Taste
                  Recovery seeks to present you with information so you can make
                  the best decision for you. 
                </p>
                <p>
                Taste Recovery does not own—and is
                  not owned by—any of the providers listed. We do not have any
                  relationship with any of the listed providers except as a
                  supplier of advertising services for providers that have
                  elected to upload their listings or to purchase other
                  advertising services from us.
                </p>

                <span className="heading">II.TERMS OF USE </span>
                <p>
                  Please read these terms and conditions of use carefully before
                  using or obtaining any materials, information, products or
                  services through this website. The terms “                  <pre>Tasterecovery.com</pre>”,
                  “us”, “we”, or “our” refer to Taste Recovery. The following
                  terms and conditions (the “Terms” or the “Agreement”) form a
                  binding agreement between you and us.
                </p>

                <span className="heading">1. No Medical Advice. </span>
                <p>
                  This site is solely intended to provide information. Although
                  we hope the information is useful, it should not be relied
                  upon as medical advice. We believe that all medical decisions
                  should be made in consultation with a physician or qualified
                  healthcare professional. THIS WEBSITE SHOULD NOT BE USED FOR
                  DIAGNOSTIC OR TREATMENT PURPOSES.
                </p>
                <span className="heading">2. No Affiliation.</span>
                <p>
                  This site provides information pertaining to treatment
                  facilities such as detox centers, rehabilitation centers,
                  intensive outpatient programs, partial hospitalization
                  programs and sober living homes. Taste Recovery does not have
                  any interest, financial or otherwise, in any of the facilities
                  listed on this site. Nothing contained on this site should be
                  construed as any type of warranty, or assurance as to the
                  quality of service or care provided by any of the homes.
                </p>
                <span className="heading">3. No Patient Brokering.</span>
                <p>
                  Unfortunately the recovery industry has been plagued by
                  individuals and companies “selling” referrals to those willing
                  to buy them. This is both illegal and counter-productive to
                  treatment itself. Taste Recovery does not receive any
                  compensation, of any kind, from any facility listed on our
                  site in exchange for referred individuals.
                </p>
                <span className="heading">4. Third Party Content. </span>
                <p>
                  Information contained on this site may be created by third
                  parties, including, but not limited to, the facilities that
                  are listed. Some third parties may also have links on this
                  site that take the user to another site. Taste Recovery is not
                  responsible for this content or links and makes no
                  representation as to its accuracy, or reliability.
                </p>
                <span className="heading">
                  5. Use Of User Generated Content.{" "}
                </span>
                <p>
                  Taste Recovery may use, and repurpose, content created by
                  users of this site. This may be in the form of advertisements,
                  such as testimonials, or to enrich the experience of other
                  users, such as chat rooms. Users that share, post or make
                  available content on this site agree to provide Taste Recovery
                  with a license and unrestricted right to use this user-created
                  content.
                </p>
                <span className="heading">6. Privacy.</span>
                <p>
                  Taste Recovery respects the privacy rights of users of this
                  site. Taste Recovery will not provide information it deems
                  confidential to any third party without the express permission
                  and consent of users. This shall not include any postings made
                  by users on this site, which shall be considered permissive
                  use pursuant to these terms.
                </p>
                <span className="heading">7. Unauthorized Use.</span>
                <p>
                  All users of this site agree to only use it the content for
                  its intended purpose.{" "}
                </p>
                <p> Following is a non-exhaustive list of prohibited uses:</p>
                <p> a) Post or upload any defamatory content;</p>
                <p>
                  {" "}
                  b) Solicit any users or third-parties for any business purpose
                  without their express authorization;
                </p>
                <p>
                  {" "}
                  c) Attempt to “hack” or in any way obtain information
                  contained on this website through the use of deceptive
                  practices.
                </p>
                <p>
                  {" "}
                  d) Commit any act of fraud, including impersonating another
                  individual;{" "}
                </p>
                <p>
                  {" "}
                  e) Copy any of the content on this website for any commercial
                  purpose.
                </p>
                <span className="heading">8. Modification Of Content.</span>
                <p>
                  Taste Recovery reserves the right to modify or remove any of
                  the content contained on the website for any purpose. This
                  right does not create a duty or obligation on the part of
                  Taste Recovery to monitor or approve any of the user-created
                  content. All users expressly hold Taste Recovery harmless from
                  any information that is inaccurate or harmful in any way.
                </p>
                <span className="heading">9. Jurisdiction and Venue.</span>
                <p>
                  Taste Recovery, the operator of this website, is located in
                  Los Angeles County, California. Any claim brought against
                  Taste Recovery, including a claim related to the content of
                  this website, shall be brought in the Superior Court of
                  California, county of Los Angeles. The Court shall enforce the
                  laws of the state of California. In the event a claim is
                  brought, the prevailing party shall be entitled to legal fees
                  and costs, provided the prevailing party first attempted in
                  good faith to mediate the dispute through the use of a third
                  party mediator.
                </p>

                <span className="heading">10. Consumer User Accounts</span>
                <p>
                  You may be required to create an account and provide certain
                  information about yourself in order to use some Website
                  features. You are responsible for maintaining the
                  confidentiality of your account password. You are also
                  responsible for all activities that occur in connection with
                  your account. You agree to notify us immediately of any
                  unauthorized use of your account. We reserve the right to
                  close your account at any time for any or no reason and
                  without prior notice. Your account is for your personal,
                  non-commercial use only. In creating it, we ask that you
                  provide complete and accurate information about yourself to
                  bolster your credibility as a contributor to the Website. You
                  agree not to impersonate someone else (e.g., adopt the
                  identity of a celebrity or your next-door neighbor), create or
                  use an account for anyone other than yourself, provide an
                  email address other than your own, or create multiple
                  accounts. If you use a pseudonym, take care to note that
                  others may still be able to identify you if, for example, you
                  include identifying information in your reviews, use the same
                  account information on other sites, or allow other sites to
                  share information about you with Taste Recovery.
                </p>

                <span className="heading">
                  11. Service Provider User Accounts
                </span>
                <p>
                  You are required to create an account and provide certain
                  information about yourself in order to list your facility on
                  Taste Recovery. You are responsible for maintaining the
                  confidentiality of your account password. You are also
                  responsible for all activities that occur in connection with
                  your account. You agree to notify us immediately of any
                  unauthorized use of your account. We reserve the right to
                  close your account at any time for any or no reason. Your
                  account is for your use only in order to list your profile, to
                  respond to customer ratings, reviews and comments, and to
                  upload and edit “Your Content” (as defined below). In creating
                  your account, we ask that you provide complete and accurate
                  information about the Service Provider you represent. You may
                  not impersonate another Service Provider, create or use an
                  account for anyone other than the Service Provider you
                  represent, provide an email address other than your own, or
                  create multiple accounts.
                </p>

                <span className="heading">12. Disclaimer of Warranties</span>
                <p>
                  The Website is provided “AS IS” and “AS AVAILABLE” and you
                  assume any and all liability for using the Website and its
                  services. Taste Recovery EXPRESSLY DISCLAIMS ALL WARRANTIES OF
                  ANY KIND, WHETHER EXPRESS OR IMPLIED. Taste Recovery MAKES NO
                  WARRANTY, AND EXPRESSLY DISCLAIMS ANY OBLIGATION, THAT: (A)
                  THE WEBSITE WILL MEET YOUR REQUIREMENTS OR WILL BE AVAILABLE
                  ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (B)
                  THE WEBSITE CONTENT WILL BE UP-TO-DATE, COMPLETE,
                  COMPREHENSIVE, ACCURATE OR APPLICABLE TO YOUR CIRCUMSTANCES;
                  (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                  WEBSITE OR ANY SERVICES OFFERED THROUGH THE WEBSITE WILL BE
                  ACCURATE OR RELIABLE; OR (D) THE QUALITY OF ANY PRODUCTS,
                  SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU
                  THROUGH THE WEBSITE WILL MEET YOUR EXPECTATIONS. THIS
                  DISCLAIMER OF WARRANTIES APPLIES BOTH TO CONTENT ON THE
                  WEBSITE AND SERVICES PROVIDED BY Taste Recovery AS WELL AS
                  INFORMATION AND SERVICES FOUND BY FOLLOWING LINKS FROM THE
                  WEBSITE.
                </p>

                <span className="heading">13. Limitation of Liability</span>
                <p>
                  WE (TOGETHER WITH OUR OFFICERS, DIRECTORS, EMPLOYEES,
                  REPRESENTATIVES, AFFILIATES, INVESTORS, SPONSORS AND
                  PROVIDERS) WILL NOT BE RESPONSIBLE OR LIABLE, IN CONTRACT,
                  TORT, OR ANY OTHER FORM OF ACTION, FOR ANY DIRECT, SPECIAL,
                  EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL
                  DAMAGES OF ANY KIND ARISING FROM OR CONNECTED WITH (A) DAMAGES
                  TO OR VIRUSES THAT MAY INFECT YOUR COMPUTER EQUIPMENT OR OTHER
                  PROPERTY AS THE RESULT OF YOUR ACCESS TO THE WEBSITE, OR (B)
                  ANY INJURY, DEATH, LOSS, CLAIM, ACT OF GOD, ACCIDENT, DELAY
                  ARISING FROM (I) ANY USE OF THE WEBSITE OR THE WEBSITE
                  CONTENT; (II) ANY FAILURE OR DELAY (INCLUDING WITHOUT
                  LIMITATION THE USE OF OR INABILITY TO USE ANY COMPONENT OF
                  THIS WEBSITE); OR (III) THE PERFORMANCE OR NON-PERFORMANCE BY
                  US OR ANY PROVIDER, EVEN IF WE HAVE BEEN ADVISED OF THE
                  POSSIBILITY OF DAMAGES TO SUCH PARTIES OR ANY OTHER PARTY. YOU
                  AGREE THAT Taste Recovery MAXIMUM LIABILITY AND YOUR SOLE
                  REMEDY FOR ALL CLAIMS, WHETHER ARISING IN CONTRACT, TORT, OR
                  ANY OTHER FORM OF ACTION, SHALL BE LIMITED, IN THE AGGREGATE,
                  TO $150.00. THIS LIMIT APPLIES TO ANY CLAIMS YOU MAY HAVE
                  DURING YOUR LIFETIME, REGARDLESS OF THE CONNECTION, OR LACK OF
                  CONNECTION, CLAIMS MAY BEAR TO ONE ANOTHER. YOU AGREE THAT,
                  REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR
                  CAUSE OF ACTION YOU MAY HAVE ARISING OUT OF, RELATING TO, OR
                  CONNECTED WITH YOUR USE OF THE WEBSITE OR ITS SERVICES, MUST
                  BE FILED WITHIN ONE (1) CALENDAR YEAR AFTER SUCH CLAIM OR
                  CAUSE OF ACTION ARISES, OR FOREVER BE BARRED.
                </p>

                <span className="heading">14.Indemnification</span>
                <p>
                  You agree to indemnify, defend, and hold harmless
                  <pre>Tasterecovery.com</pre>, and its present, former and future
                  officers, directors, employees and agents, and parent
                  companies, subsidiaries, affiliates, successors, and assigns
                  from and against any claim, demand, loss, damage, cost, or
                  liability arising out of or relating to any content you
                  submit, post, transmit, or make available through the Website
                  or its services; Your use or misuse of the Website; Your
                  breach or alleged breach of these Terms; or your violation of
                  any rights (including intellectual property rights) of a third
                  party.
                </p>

                <span className="heading">15.Arbitration</span>
                <p>
                  Any controversy or claim between you and <pre>Tasterecovery.com</pre> or
                  any of its officers, directors, employees, agents, parent
                  companies, subsidiaries, affiliates, successors, or assigns
                  arising out of or relating to this Agreement, your use of the
                  Website, or your communication with or transactions with a
                  provider of substance use disorder listed on the Website,
                  shall be settled by binding arbitration before a single
                  arbitrator in accordance with the JAMS Streamlined Arbitration
                  Rules. The decision of the arbitrator shall be final and
                  unappealable. The arbitration shall be conducted in California
                  and judgment on the arbitration award may be entered into any
                  court having jurisdiction thereof. Notwithstanding the
                  foregoing, we may seek injunctive or other equitable relief to
                  protect our intellectual property rights in any court of
                  competent jurisdiction. If either party wants to arbitrate a
                  dispute, the party raising the complaint agrees to send
                  written notice to the other party providing a description of
                  the dispute, previous efforts to resolve the dispute, all
                  supporting documents/information, and the proposed resolution.
                  Taste Recovery and you each agree to make attempts to resolve
                  this dispute within forty-five (45) days of receipt of the
                  notice to arbitrate. Thereafter, either <pre>Tasterecovery.com</pre> or
                  you may submit the dispute to formal arbitration. Both you and
                  Taste Recovery each agree not to pursue arbitration on a
                  consolidated, mass, representative, or class basis. Both you
                  and Taste Recovery each agree that any arbitration will be
                  solely between you and Taste Recovery and not in a class
                  action, mass action, or representative action. If for any
                  reason any court or arbitrator holds that this bar on
                  consolidated, mass, representative, or class actions is
                  unconscionable or unenforceable, then this agreement to
                  arbitrate does not apply and the consolidated, mass,
                  representative, or class dispute must be brought in court.
                </p>

                <span className="heading">16.Governing Law</span>
                <p>
                  This Agreement shall be governed by the laws of the State of
                  California without giving effect to any principles that may
                  provide the application of the law of another jurisdiction.
                </p>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default TermsOfServiceIos;
