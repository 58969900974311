import React, { Component } from 'react';
import ButtonGroupComponent from '../../components/button/button-group.component';
import { StandartOptions, RoomOptions, PropertyServing, sobrietyRequirement, OccupancyMeter, PaymentPeriodFilter } from '../../constants';
import Slider from 'rc-slider';
import { Ucfirst } from './../../utils/common.util';
import { connect } from 'react-redux';
import { livingAction } from '../../actions';
import backImg from '../../assets/images/back.svg';
import alertImg from '../../assets/images/alert.svg';
import { SpinnerContext } from '../../components/spinner/spinner.component';
import { Button } from 'react-bootstrap';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

class LivingFilter extends Component {
  static contextType = SpinnerContext;
  defaultParams = {};
  fieldLabels = {
    paymentPeriod: 'Payment Period',
    servingForWhom: 'Serving For Whom',
    children: 'Parents with children allowed',
    availability: 'Availability',
    pets: 'Pets',
    transportation: 'Transportation',
    room: 'Room',
    bathroom: 'Bathroom',
    handicapFriendly: 'Handicap Friendly',
    smokeFriendly: 'Smoke Friendly',
    privateChef: 'Private Chef',
    sobrietyRequirement: 'Sobriety Requirement',
    maximumOccupancy: 'Maximum Occupancy',
    acceptMAT:"MAT accepted house",
    isCertifiedSoberLiving:"Certified by the Sober Living Network"
  };
  isChanged = false;
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.queryParams
    };
    this.defaultParams = {
      ...this.props.defaultParams,
      maximumOccupancy: OccupancyMeter.ONE,
      sobrietyRequirement: sobrietyRequirement[0].value
    };
    this.onRangeChange = this.onRangeChange.bind(this);
    this.onApplySearch = this.onApplySearch.bind(this);
    this.onClearFilter = this.onClearFilter.bind(this);
    this.onAlert = this.onAlert.bind(this);
    this.props.getLivingSearchInfo(this.state);
  }

  render() {
    return (
      <div>
        <div className="filter active">
          <div className="filter-header">
            <div className="close-filter" onClick={this.props.onBack}>
              <img src={backImg} alt="" width={16} />
            </div>
            <div className="title">Filters</div>
            <div className="apply btn" onClick={this.onApplySearch}>
              Apply
            </div>
          </div>
          <div className="filter-top mb-4">
            <div className="filter-top-left">
              <span>
                <strong>{this.props.searchInfo ? this.props.searchInfo.totalEntries : 0}</strong> matches out of {this.props.searchInfo ? this.props.searchInfo.allEntries : 0} properties
              </span>
              <p />
              <div>
                <p>
                  More than ${this.state.priceMin} - Less than ${this.state.priceMax}
                </p>
                {this.state !== this.defaultParams &&
                  Object.keys(this.state).map((item, index) => {
                    if (['priceMin', 'priceMax', 'page', 'boundsCoordinates', 'coordinates'].indexOf(item) >= 0) {
                      return;
                    } else if (['sobrietyRequirement'].indexOf(item) >= 0) {
                      const lable = sobrietyRequirement.findIndex((i) => i.value === this.state[item]);
                      return (
                        <div key={index}>
                          {' '}
                          + {' ' + this.fieldLabels[item]}: {sobrietyRequirement[lable].label}
                        </div>
                      );
                    } else if (this.state[item] !== this.defaultParams[item]) {
                      return (
                        <div key={index}>
                          {' '}
                          + {' ' + this.fieldLabels[item]}: {Ucfirst(this.state[item])}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className={`filter-top-right btn ${this.isStateChange() ? '' : ' disabled clear-filter-disabled'}`} onClick={this.isChanged ? this.onClearFilter : () => {}}>
              Clear filters
            </div>
          </div>
          <div className="get-alert mb-4">
            <p>Alert me on new properties or price changes based on my filters</p>
            <a href="#" className="btn btn-default" onClick={this.onAlert}>
              <img src={alertImg} alt="" /> Get alerts
            </a>
          </div>
          <div className="price-range mb-4">
            <label>Price Range</label>
            <Range min={parseInt(process.env.REACT_APP_MIN_PRICE_RANGE)} max={parseInt(process.env.REACT_APP_MAX_PRICE_RANGE)} defaultValue={[this.state.priceMin, this.state.priceMax]} value={[this.state.priceMin, this.state.priceMax]} onChange={this.onRangeChange} tipFormatter={(value) => `$${value}`} />
            <span>${parseInt(process.env.REACT_APP_MIN_PRICE_RANGE)}</span>
            <span className="float-right">${process.env.REACT_APP_MAX_PRICE_RANGE.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
          </div>
          <div className="common-selection mb-4">
            <label>Rent Period</label>
            <ButtonGroupComponent options={PaymentPeriodFilter} value={this.state.paymentPeriod} onSelect={(value) => this.onValueChange({ paymentPeriod: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Is this house serving</label>
            <ButtonGroupComponent options={PropertyServing} value={this.state.servingForWhom} onSelect={(value) => this.onValueChange({ servingForWhom: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Parents with children allowed</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.children} onSelect={(value) => this.onValueChange({ children: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Availability</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.availability} onSelect={(value) => this.onValueChange({ availability: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Pets</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.pets} onSelect={(value) => this.onValueChange({ pets: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Transportation</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.transportation} onSelect={(value) => this.onValueChange({ transportation: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Room</label>
            <ButtonGroupComponent options={RoomOptions} value={this.state.room} onSelect={(value) => this.onValueChange({ room: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Bathroom</label>
            <ButtonGroupComponent options={RoomOptions} value={this.state.bathroom} onSelect={(value) => this.onValueChange({ bathroom: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Handicap Friendly</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.handicapFriendly} onSelect={(value) => this.onValueChange({ handicapFriendly: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Smoke friendly</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.smokeFriendly} onSelect={(value) => this.onValueChange({ smokeFriendly: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Private chef</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.privateChef} onSelect={(value) => this.onValueChange({ privateChef: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>MAT accepted house</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.acceptMAT} onSelect={(value) => this.onValueChange({ acceptMAT: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Certified by the Sober Living Network</label>
            <ButtonGroupComponent options={StandartOptions} value={this.state.isCertifiedSoberLiving} onSelect={(value) => this.onValueChange({ isCertifiedSoberLiving: value })} />
          </div>
          <div className="common-selection mb-4">
            <label>Sobriety Requirement</label>
            <div className="form-group">
              <select className="form-control" onChange={(val) => this.onValueChange({ sobrietyRequirement: val.target.value })} value={this.state.sobrietyRequirement}>
                {Object.keys(sobrietyRequirement).map((item, index) => {
                  item = sobrietyRequirement[index];
                  return (
                    <option key={index} value={item.value}>
                      {Ucfirst(item.label)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="common-selection mb-4">
            <label>Maximum Occupancy</label>
            <div className="form-group">
              <select className="form-control" onChange={(val) => this.onValueChange({ maximumOccupancy: val.target.value })} value={this.state.maximumOccupancy}>
                {Object.keys(OccupancyMeter).map((item, index) => {
                  item = Ucfirst(OccupancyMeter[item]);
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
  onValueChange(value) {
    this.isChanged = true;
    this.setState({ ...value }, () => this.props.getLivingSearchInfo(this.state));
  }

  onRangeChange(value) {
    this.isChanged = true;
    this.setState({ priceMin: value[0], priceMax: value[1] }, () => this.props.getLivingSearchInfo(this.state));
  }

  onApplySearch() {
    this.props.onApplySearch(this.isChanged ? this.state : this.props.defaultParams);
  }

  onClearFilter() {
    this.isChanged = false;
    this.setState(
      {
        ...this.defaultParams
      },
      () => this.props.getLivingSearchInfo(this.props.defaultParams)
    );
  }

  onAlert() {
    if (this.state.id && JSON.stringify(this.state) === JSON.stringify(this.props.queryParams)) {
      return this.context.showMessage('Sorry, you have already saved this filter options.');
    }
    this.props.getAlert(this.state).then(() => this.context.showMessage('Alert added successfully!!!'));
  }

  isStateChange = () => {
    return Object.keys(this.state).some((key) => this.defaultParams[key] !== this.state[key]);
  };
}

export default connect(
  ({ living }) => ({
    searchInfo: living.searchInfo
  }),
  livingAction
)(LivingFilter);
