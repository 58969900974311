import React, { useEffect, useState } from "react";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import varifyBadgeStep1 from "../../assets/images/varify-badge-step1.png";
import varifyBadgeStep1Mobile from "../../assets/images/varify-badge-step1-mobile.png";
import varifyBadgeStep12Mobile from "../../assets/images/varify-badge-step1_2-mobile.png";
import varifyBadgeStep2 from "../../assets/images/varify-badge-step2.png";
import varifyBadgeStep2Mobile from "../../assets/images/varify-badge-step2-mobile.png";
import varifyBadgeStep3 from "../../assets/images/varify-badge-step3.png";
import varifyBadgeStep3Mobile from "../../assets/images/Image 23 1 (1).png";
import questionnaireStep1 from "../../assets/images/questionnaire-step1.png";
import questionnaireStep2 from "../../assets/images/questionnaire-step2.png";
import questionnaireStep3 from "../../assets/images/questionnaire-step3.png";
import questionnaireStep4 from "../../assets/images/questionnaire-step4.png";
import questionnaireStep1Mobile from "../../assets/images/questionnaire-step1-mobile.png";
import questionnaireStep2Mobile from "../../assets/images/questionnaire-step2-mobile.png";
import questionnaireStep3Mobile from "../../assets/images/questionnaire-step3-mobile.png";
import questionnaireStep4Mobile from "../../assets/images/questionnaire-step4-mobile.png";
import varifyBadgeStep3Mobilssse from "../../assets/images/facility_click-through_rates_CTR.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addiopcenterAction,
  detoxAction,
  homeAction,
  phpAction,
  residentialAction,
} from "../../actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Helpandsupport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const phpPlan = useSelector((state) => state?.php?.phpPlan);
  const detoxPlan = useSelector((state) => state?.detox?.detoxPlan);
  const iopPlan = useSelector((state) => state?.iopCenter?.iopPlan);
  const residentialPlan = useSelector(
    (state) => state?.residential?.residentialPlan
  );
  const state = useSelector((state) => state);
  const isHost = state?.auth?.isHost;

  const subscribePlans = useSelector((state) => state?.home);
  const [activeTab, setActiveTab] = useState("Plans and Pricing");
  const [selectedPaymentCard, setSelectedPaymentCard] = useState("Detox");
  const [planPrice, setPlanPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [interval, setInterval] = useState("month");

  useEffect(() => {
    dispatch(homeAction.fetchPlan());
    dispatch(addiopcenterAction.getIopSubscriptionPlan());
    dispatch(residentialAction.getResidentialSubscriptionPlan());
    dispatch(detoxAction.getDetoxSubscriptionPlan());
    dispatch(phpAction.getPhpSubscriptionPlan());
  }, [dispatch]);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handlePaymentCardChange = (cardName) => {
    setSelectedPaymentCard(cardName);
  };

  return (
    <>
      <ReactHelmet
        title={"Taste Recovery - Faqs"}
        description={
          "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
        }
      />
      <>
        <section className="help-support-section manage-survey_section mt-3 pt-5">
          <div className="container">
            <div class="listing-header test pt-4">
              <h3 class="main-title flex-fill mb-5">HELP AND SUPPORT</h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="manage-survey_header">
                  <ul className="manage-survey_tabs d-flex align-items-center">
                    <li
                      className={`tab-item ${activeTab === "Plans and Pricing" ? "active" : ""
                        }`}
                    >
                      <a
                        className="tab-link"
                        onClick={() => handleTabClick("Plans and Pricing")}
                      >
                        Plans and Pricing
                      </a>
                    </li>

                    <li
                      className={`tab-item ${activeTab === "Verification Badge Request"
                          ? "active"
                          : ""
                        }`}
                    >
                      <a
                        className="tab-link"
                        onClick={() =>
                          handleTabClick("Verification Badge Request")
                        }
                      >
                        Verification Badge Request
                      </a>
                    </li>

                    <li
                      className={`tab-item ${activeTab === "Questionnaire Configuration"
                          ? "active"
                          : ""
                        }`}
                    >
                      <a
                        className="tab-link"
                        onClick={() =>
                          handleTabClick("Questionnaire Configuration")
                        }
                      >
                        Questionnaire Configuration
                      </a>
                    </li>

                    <li
                      className={`tab-item ${activeTab === "FAQ" ? "active" : ""
                        }`}
                    >
                      <a
                        className="tab-link"
                        onClick={() => handleTabClick("FAQ")}
                      >
                        FAQs
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="help-support-content">
              {/* Plans and Pricing */}
              {activeTab === "Plans and Pricing" && (
                <div className="plan-pricing-content">
                  <ul className="plan-pricing-tab d-flex align-items-center mb-5 pb-2">
                    <li
                      className={`tab-item ${selectedPaymentCard === "Detox" ? "active" : ""
                        }`}
                      onClick={() => handlePaymentCardChange("Detox")}
                    >
                      <a href="#" className="tab-link">
                        Detox
                      </a>
                    </li>
                    <li
                      className={`tab-item ${selectedPaymentCard === "Residential" ? "active" : ""
                        }`}
                      onClick={() => handlePaymentCardChange("Residential")}
                    >
                      <a className="tab-link">Residential</a>
                    </li>
                    <li
                      className={`tab-item ${selectedPaymentCard === " PHP" ? "active" : ""
                        }`}
                      onClick={() => handlePaymentCardChange(" PHP")}
                    >
                      <a className="tab-link">PHP</a>
                    </li>
                    <li
                      className={`tab-item ${selectedPaymentCard === "IOP" ? "active" : ""
                        }`}
                      onClick={() => handlePaymentCardChange("IOP")}
                    >
                      <a className="tab-link">IOP</a>
                    </li>
                    <li
                      className={`tab-item ${selectedPaymentCard === "soberLiving" ? "active" : ""
                        }`}
                      onClick={() => handlePaymentCardChange("soberLiving")}
                    >
                      <a className="tab-link">Sober Living</a>
                    </li>
                  </ul>
                  <p className="heplandsupportconter">
                    To list your facility on the Taste Recovery home page and
                    access premium features: <b>log in </b> to the portal,{" "}
                    <b>go to your profile</b>, select or create the facility you
                    want to subscribe, proceed to the <b>'subscription' page</b>
                    , choose your plan and cycle, and complete the{" "}
                    <b>payment</b> by adding your card. If the facility is
                    already created but not subscribed, click 'Edit' from the
                    profile list view of respective facility, then proceed with
                    the same process to complete the subscription.
                  </p>
                  {selectedPaymentCard === "soberLiving" ? (
                    <div>
                      <section className="pt-0 mt-0 mt-md-5 plans-pricing listing">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="subscription-wrp">
                                <div className="togglediv d-flex flex-wrap align-items-center justify-content-center">
                                  <div class="pricing-toggle">
                                    {subscribePlans?.subscribePlan?.map(
                                      (data, index) => (
                                        <div
                                          key={index}
                                          className="toggle-button-container d-flex align-items-center"
                                        >
                                          <input
                                            type="radio"
                                            id={data?.id}
                                            name="pricing"
                                            checked={
                                              interval ===
                                              data?.recurring.interval
                                            }
                                          />
                                          <label
                                            className="radio-button"
                                            htmlFor={
                                              interval === "monthly"
                                                ? "pricing-toggle-monthly"
                                                : "pricing-toggle-annually"
                                            }
                                            onClick={() => {
                                              setPlanPrice(data?.id);
                                              setInterval(
                                                data?.recurring.interval
                                              );
                                              setAmount(data?.unit_amount);
                                            }}
                                          >
                                            {data?.recurring.interval
                                              .charAt(0)
                                              .toUpperCase() +
                                              data?.recurring.interval.slice(1)}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className="offer">Save up to 15%</div>
                                </div>

                                <div className="plan-list justify-content-center">
                                  <div className="plan-card activecard">
                                    <span className="save-para"></span>
                                    <h3>Basic</h3>
                                    <h4>
                                      <b>Free</b>
                                    </h4>
                                    <div className="plan-info">
                                      <ul>
                                        <li>House Rules</li>
                                        <li>Curfew</li>
                                        <li>Amenities</li>
                                        <li>Requirements</li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="plan-card">
                                    <span className="save-para">
                                      {interval === "year" &&
                                        "$23.50 savings/year"}
                                    </span>
                                    <h3>Pro</h3>
                                    <h4>
                                      <b>
                                        $
                                        {amount
                                          ? amount
                                          : subscribePlans?.subscribePlan[0]
                                            ?.unit_amount}
                                        /home/{interval}
                                      </b>
                                    </h4>
                                    <div className="plan-info">
                                      <ul>
                                        <li>House Rules</li>
                                        <li>Curfew</li>
                                        <li>Amenities</li>
                                        <li>Requirements</li>
                                        <li>Customer Reviews</li>
                                        <li>Website Listed</li>
                                        <li>Phone Number Listed</li>
                                        <li>
                                          Chat with potential clients through
                                          the <br />
                                          platform
                                        </li>
                                        <li>Scheduling House Tours</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : (
                    <div class="plan-list sober-living-plan-list justify-content-center pt-4 mt-md-3">
                      <div class="plan-card">
                        <h3>Basic</h3>
                        <h4>
                          <b>
                            $
                            {selectedPaymentCard === "Detox"
                              ? detoxPlan[0]?.unit_amount
                              : selectedPaymentCard === "Residential"
                                ? residentialPlan[0]?.unit_amount
                                : selectedPaymentCard === " PHP"
                                  ? phpPlan[0]?.unit_amount
                                  : selectedPaymentCard === "IOP"
                                    ? iopPlan[0]?.unit_amount
                                    : selectedPaymentCard === "soberLiving"
                                      ? subscribePlans?.subscribePlan[0]?.unit_amount
                                      : ""}
                            /Month
                          </b>
                        </h4>
                        <div class="plan-info">
                          <ul>
                            <li>Standard visibility</li>
                            <li>Facility center details</li>
                            <li>Upload one photo to showcase your facility</li>
                            <li>List your phone number for direct contact</li>
                            <li>Showcase your website link</li>
                            <li>
                              Connect with potential clients via in-platform
                              chat
                            </li>
                            <li>Access and view reviews</li>
                          </ul>
                        </div>
                      </div>
                      <div class="plan-card">
                        <h3>Pro</h3>
                        <h4>
                          <b>
                            $
                            {selectedPaymentCard === "Detox"
                              ? detoxPlan[2]?.unit_amount
                              : selectedPaymentCard === "Residential"
                                ? phpPlan[2]?.unit_amount
                                : selectedPaymentCard === " PHP"
                                  ? phpPlan[2]?.unit_amount
                                  : selectedPaymentCard === "IOP"
                                    ? iopPlan[2]?.unit_amount
                                    : selectedPaymentCard === "soberLiving"
                                      ? subscribePlans?.subscribePlan[1]?.unit_amount
                                      : ""}
                            /Month
                          </b>
                        </h4>
                        <div class="plan-info">
                          <ul>
                            <li className="highlight">
                              <b>Includes all basic options</b>
                            </li>
                            <li>Highest visibility</li>
                            <li>
                              Upload up to 10 photos for a more detailed
                              showcase
                            </li>
                            <li>
                              Offer a virtual facility tour link to engage
                              potential clients
                            </li>
                            <li>
                              Predefined survey questionnaire in the chat to
                              assist in quicker responses and personalized care
                            </li>
                            <li>
                              Respond to reviews for enhanced communication
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Verification Badge Request */}
              {activeTab === "Verification Badge Request" && (
                <div className="varify-badge-content pt-1">
                  <div class="listing-header test pt-4 pb-2">
                    <h3 class="main-title flex-fill mb-5">HOW IT WORKS</h3>
                  </div>
                  <div className="step-wrapper">
                    <div className="step-content step1 d-flex">
                      <div className="step-no">1</div>
                      <div className="step-detail">
                        <div className="step-name">Step 1</div>
                        <div className="step-info mw-100">
                          To have a verified check mark on your profile, you
                          will need to submit information about your facility
                          that will be checked by a Taste Recovery Team Member.
                          <br /> To get started, click on "Request Verified
                          Badge" for the facility you would like to be verified.
                        </div>
                        <div className="step-image">
                          <LazyLoadImage
                            effect="blur"
                            src={varifyBadgeStep1}
                            className="img-fluid desktop-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={varifyBadgeStep1Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={varifyBadgeStep12Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="step-content step2 d-flex">
                      <div className="step-no">2</div>
                      <div className="step-detail">
                        <div className="step-name">Step 2</div>
                        <div className="step-info">
                          You will need to upload your business license and
                          accreditation's that are pertinent to your type of
                          facility. Please provide any information in the
                          description that you believe to be relevant for this
                          process.
                        </div>
                        <div className="step-image">
                          <LazyLoadImage
                            effect="blur"
                            src={varifyBadgeStep2}
                            className="img-fluid desktop-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={varifyBadgeStep2Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="step-content step3 d-flex">
                      <div className="step-no">3</div>
                      <div className="step-detail">
                        <div className="step-name">Step 3</div>
                        <div className="step-info">
                          Your facility will either be accepted or denied. If
                          accepted, your facility profile page will have a blue
                          check mark showcasing the legitimacy of your program.
                          If denied, someone from the Taste Recovery Team will
                          reach out to request more information or inform you as
                          to why your request has been denied.
                        </div>
                        <div className="step-image">
                          <LazyLoadImage
                            effect="blur"
                            src={varifyBadgeStep3}
                            className="img-fluid desktop-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={varifyBadgeStep3Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Questionnaire Configuration */}
              {activeTab === "Questionnaire Configuration" && (
                <div className="questionnaire-content pt-1 pb-3">
                  <div class="listing-header flex-wrap test pt-4 pb-2 text-justify">
                    <h3 class="main-title flex-fill mb-3">HOW IT WORKS</h3>
                    <div className="step-info mw-100">
                      This questionnaire is to curate questions for potential
                      clients to answer to see if they would be a good fit for
                      your facility! At the end of the questionnaire, their
                      answers will be sent to your inbox where you can continue
                      the conversation. At the end of the questionnaire, we will
                      include your contact information, making it easier for
                      them to contact you. We suggest that your admissions staff
                      have access to this questionnaire to make the process
                      seamless for both parties.
                    </div>
                  </div>
                  <div className="step-wrapper">
                    <div className="step-content step1 d-flex">
                      <div className="step-no">1</div>
                      <div className="step-detail">
                        <div className="step-name">Step 1</div>
                        <div className="step-info mw-100"></div>
                        <div className="step-image">
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep1}
                            className="img-fluid desktop-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep1Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="step-content step2 d-flex">
                      <div className="step-no">2</div>
                      <div className="step-detail">
                        <div className="step-name">Step 2</div>
                        <div className="step-info"></div>
                        <div className="step-image">
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep2}
                            className="img-fluid desktop-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep2Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="step-content step3 d-flex">
                      <div className="step-no">3</div>
                      <div className="step-detail">
                        <div className="step-name">Step 3</div>
                        <div className="step-info mw-100"></div>
                        <div className="step-image">
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep3}
                            className="img-fluid desktop-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep3Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="step-content step4 d-flex">
                      <div className="step-no">4</div>
                      <div className="step-detail">
                        <div className="step-name">Step 4</div>
                        <div className="step-info mw-100"></div>
                        <div className="step-image">
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep4}
                            className="img-fluid desktop-img"
                            alt=""
                          />
                          <LazyLoadImage
                            effect="blur"
                            src={questionnaireStep4Mobile}
                            className="img-fluid mobile-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* FAQ */}
              {activeTab === "FAQ" && (
                <div className="faq-content py-1">
                  <div className="faq-content-block">
                    <h2 className="faqs">For Facility Owners:</h2>
                    <h3>1. How do I list my facility on Taste Recovery?</h3>
                    <p>
                      To list your facility on Taste Recovery, simply sign up
                      for an account, fill out the appropriate facility type
                      questionnaire and choose a plan that best suits your
                      needs. You can select either the Basic Plan for $19 or the
                      Pro Plan for $39 per month. Once you've subscribed,
                      potential clients can start reaching out to you via the
                      chat, your phone number that is listed or your website.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      2. What are the benefits of upgrading to the Pro Plan?
                    </h3>
                    <p>
                      The Pro Plan offers additional features and visibility for
                      your facility, including priority placement in search
                      results, enhanced profile customization options, up to 10
                      photos and also allows you to fill out a list of questions
                      that will appear on your profile, helping potential
                      clients determine if your facility is the right fit for
                      their program.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      3. Is there a fee for listing my facility on Taste
                      Recovery?
                    </h3>
                    <p>
                      Yes, there is a subscription fee for listing your facility
                      on Taste Recovery. However, there is no charge for
                      individuals seeking rehabilitation services to access and
                      use our platform.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      4. How does the pay-per-click marketing program work?
                    </h3>
                    <p>
                      Our pay-per-click marketing program allows you to increase
                      your facility's visibility by setting a monthly budget of
                      your choosing for ad placement within our platform. You
                      only pay when users click on your ads, making it a
                      cost-effective way to attract more clients to your
                      facility.
                    </p>
                  </div>
                  <div className="faq-content-block mb-5">
                    <h3>
                      5. How to claim my profile on Taste Recovery?
                    </h3>
                    <ul>
                      <p className="mb-2">To claim your profile on TasteRecovery.com, follow these steps:</p>


                      <li className="mb-2">
                        <strong>i. Check Your Email </strong>: Look for an email from TasteRecovery.com that was sent to the address associated with your facility. This email should contain instructions on how to claim your profile.
                      </li>
                      <li className="mb-2">
                        <strong>ii. Follow Instructions </strong>: If you received the email, follow the provided instructions to claim your profile.
                      </li>
                      <li className="mb-2">
                        <strong>iii. Didn’t Receive the Email ? </strong>: If you didn’t receive an email or can't find it, you can still claim your profile by reaching out to TasteRecovery.com directly.
                      </li>
                      <li className="mb-2">
                        <strong>iv. Contact Us </strong>: Email us at <a href="mailto:contact@tasterecovery.com">
                          contact@tasterecovery.com.
                        </a>{" "}
                        with your request to claim your profile. Include details about your facility to help us assist you more efficiently.
                      </li>

                    </ul>
                  </div>
                  <h2 className="faqs">For Sober Living Homes:</h2>
                  <div className="faq-content-block">
                    <h3>
                      1. Will I be charged a fee for moving into a home that I
                      found through the app?
                    </h3>
                    <p>
                      Taste Recovery does not charge the user a fee for using
                      the app, nor do we make commission when someone moves into
                      a sober living home from our app.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      2. What happens if the sober living I move into is not the
                      right fit?
                    </h3>
                    <p>
                      Each sober living has different contracts regarding if and
                      when they refund someone once they have moved in, which
                      needs to be discussed further with the owner of that sober
                      living. We are a third party and are not responsible for
                      the sober living homes. However, we would greatly
                      appreciate a review of the home once you have.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      3. I’ve never been to a sober living, how do I know what
                      questions to ask to make sure it is the right place for
                      me?
                    </h3>
                    <p>
                      This is a personal decision and will be different for
                      everyone, but focus on what truly matters to you. If you
                      are looking for a house with women your age, you can ask
                      the owner or house manager what the age range is in the
                      house through our messaging system. If you are nervous
                      about too many women sharing a bathroom, you can ask how
                      many women share each bathroom in the house. If you have
                      to wake up early every morning for work, you can ask what
                      the quiet hours are and are they actually implementing
                      this rule? If you are looking for a very structured home
                      to keep you accountable, you can ask how many meetings are
                      required each week and how does the house manager check?
                      Does the sober living have you sign a meeting card? There
                      are so many important questions to ask, but it truly
                      depends on what the individual feels is important to their
                      sobriety and serenity. This is why we have set up the
                      messaging system, so you can have all of your questions
                      answered and you can feel sure about this next move.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      4. Can I schedule a tour with a sober living home prior to
                      moving in?
                    </h3>
                    <p>
                      Absolutely! We have made this step easy through our app so
                      that you can tour as many sober homes as possible. This is
                      a big decision and we encourage tours if you are in the
                      area and can do so!
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>5. How can I add my sober living to your website?</h3>
                    <p>
                      Create an account on the app and state that you own a
                      sober living. You can add your sober living homes
                      information through the profile icon, including pictures,
                      house rules, and so much more.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      6. My sober living is not well known and would love to be
                      a promoted house on your app, how does this work?
                    </h3>
                    <p>
                      Once you have created a profile and uploaded your sober
                      living, you can then upgrade your house to a promoted home
                      through our ad services.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      7. What plans are available for listing sober living homes
                      on Taste Recovery?
                    </h3>
                    <p>
                      Sober living homes are currently eligible for a free Basic
                      Plan. However, they also have the option to upgrade to the
                      Pro Plan for $12.99 per month, which provides additional
                      features and visibility benefits.
                    </p>
                  </div>
                  <h2 className="faqs">
                    For Individuals Seeking Rehab Services:
                  </h2>
                  <div className="faq-content-block">
                    <h3>
                      1. Is there a fee for using Taste Recovery to find a rehab
                      facility?
                    </h3>
                    <p>
                      No, Taste Recovery is completely free for individuals
                      seeking rehab services. You can search for facilities,
                      read reviews, and contact facilities directly without any
                      charge.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>
                      2. How can I search for rehab facilities on Taste
                      Recovery?
                    </h3>
                    <p>
                      You can search for rehab facilities on Taste Recovery by
                      entering your location and filtering your search based on
                      specific criteria such as treatment type, amenities, and
                      more. Our platform makes it easy to find the right
                      facility to meet your needs.
                    </p>
                  </div>
                  <div className="faq-content-block">
                    <h3>3. How do I choose the right facility for my needs?</h3>
                    <p>
                      Choosing the right facility is an important decision. We
                      recommend considering factors such as the location,
                      treatment approach, amenities, staff credentials, and
                      reviews from other clients. Our platform provides detailed
                      information about each facility to help you make an
                      informed choice.
                    </p>
                  </div>
                  <h2 className="faqs">General:</h2>
                  <div className="faq-content-block">
                    <h3>
                      1. How can I contact Taste Recovery for support or further
                      inquiries?
                    </h3>
                    <p>
                      If you have any questions or need assistance, please feel
                      free to reach out to our support team via email at{" "}
                      <a href="mailto:contact@tasterecovery.com">
                        contact@tasterecovery.com.
                      </a>{" "}
                      We're here to help!
                    </p>
                  </div>{" "}
                  <div className="faq-content-block">
                    <h3>
                      2. Can I update or cancel my subscription plan at any
                      time?
                    </h3>
                    <p>
                      Yes, you can update or cancel your subscription plan at
                      any time through your profile. Changes will take effect
                      immediately, and you will only be charged for the
                      remainder of the month that has been currently paid for.
                    </p>
                  </div>{" "}
                  <div className="faq-content-block">
                    <h3>
                      3. Is my personal information secure on Taste Recovery?
                    </h3>
                    <p>
                      Yes, we take the privacy and security of your personal
                      information seriously. We use industry-standard encryption
                      and security protocols to protect your data and ensure a
                      safe browsing experience for all users and are HIPPA
                      Compliant.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="get-in-touch-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="get-in-touch-content d-flex flex-column align-items-center">
                  <p>NEED HELP? GET IN TOUCH WITH TASTE RECOVERY</p>
                  <button
                    className="btn btn-primary-outline radius-20"
                    onClick={() =>
                      history?.location?.pathname ===
                        "/settings/help-and-support"
                        ? history.push(`/settings/contact-support`)
                        : history.push(`/contact-support`)
                    }
                  >
                    Contact us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Helpandsupport;
