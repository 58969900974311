import { addIopCenterActionType, livingActionType } from "../actions";

// reducer.js

const initialStateiopcenter = {
  fetchiopcenterid:[],
  iopHouse:{},
  fetching:false,

  fetchingIopPlan: true,
  iopPlan:[],
  priceId:null,
  priceAmount:null,
  facilityInfo:{},
  isChange:false,
};

export const iopcenterReducer = (state = initialStateiopcenter, action) => {
  switch (action.type) {
    case addIopCenterActionType.Iopcenter_Adding:
      return { ...state, addiopcenter: true };
    case addIopCenterActionType.Iopcenter_Add:
      return { ...state, addiopcenter: false,iopHouse:action?.payload?.iopcenter};

      case addIopCenterActionType.Editing_iopCenter:
        return { ...state, EditingIopCenter: true };
      case addIopCenterActionType.Edited_iopCenter:
        return { ...state, EditingIopCenter: false };
        case addIopCenterActionType.fetch_IOPcneter_ById_ActionType:
          return { ...state, fetching: true };
        case addIopCenterActionType.fetch_IOPcneter_ById_Success:
          const { IOPhouseData } = action.payload;
          return { ...state, fetching: false, fetchiopcenterid:IOPhouseData };
        case addIopCenterActionType.fetch_IOPcneter_ById_Failure:
          return { ...state, fetching: false, error: action.payload.error };

          case addIopCenterActionType.SubscriptionPlan_Fetching:
            return { ...state, fetchingIopPlan: true };
        case addIopCenterActionType.SubscriptionPlan_Fetched:
            return { ...state, fetchingIopPlan: false,iopPlan: action.payload };
    
        case addIopCenterActionType.IopBasicPlan_Set:
            return {...state, priceId:action.payload?.id, priceAmount:action?.payload?.price}
        case addIopCenterActionType.IopProPlan_Set:
            return {...state, priceId:action.payload?.id,priceAmount:action?.payload?.price }
        case addIopCenterActionType.IopFacilityType_Set:
            return {...state, facilityInfo:{facilitytype:action?.payload?.facilitytype, id:action?.payload?.id, name:action?.payload?.name}}
   
        case addIopCenterActionType.Iop_Sub:
          return {...state, isChange:action.payload}
   
      default:
      return state;
  }
};
