
import React, { useState, useEffect } from "react";
import axios from "axios";
import plusImg from "../../assets/images/plus.svg";
import cancleImg from "../../assets/images/cancle.svg";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { residentialAction } from "../../actions/index";
import { SpinnerContext } from "../spinner/spinner.component";
import { compressImage } from "./compressImage";

const FileUploadComponent = ({ handleFileChange, photos }) => {
  
  const context = React.useContext(SpinnerContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const comp = useParams();
  const [media, setMedia] = useState(photos);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (comp?.id) {
      setMedia(photos);
    }
  }, [photos]);

  const handleChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const selectedFiles = Array.from(event.target.files);
    const totalFiles = media.length + selectedFiles.length;
    const invalidFiles = selectedFiles.map((file) => {
      const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
      return allowedFormats.includes(file.type);
    });
    const isInvalid = invalidFiles.some((ele) => ele === false);

    if (isInvalid) {
      setErrorMessage("Please select only PNG, JPG, or JPEG photos.");
      setShowErrorModal(true);
      return;
    }

    if (totalFiles > 10 || media.length === 10) {
      setErrorMessage("You can add a maximum of 10 photos.");
      setShowErrorModal(true);
      return;
    }

    const fileSizeLimit = 5 * 1024 * 1024; // 1 MB limit
    const oversizedFiles = selectedFiles.filter((file) => file.size > fileSizeLimit);
    if (oversizedFiles.length > 0) {
      setErrorMessage("Please upload photos less than or equal to 5 MB.");
      setShowErrorModal(true);
      return;
    }

    context.showLoader('Please wait while processing...');

    const compressedFiles = await Promise.all(selectedFiles.map((file) => compressImage(file)));

    handleFileChange(compressedFiles);
    setMedia((prevMedia) => [...prevMedia, ...compressedFiles.slice(0, 10 - prevMedia.length)]);
    context.hideLoader("");
    if (event.target) {
      event.target.value = "";
    }
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setShowErrorModal(false);
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleDeleteImage = (index) => {
    const updatedMedia = [...media];
    updatedMedia.splice(index, 1);
    setMedia(updatedMedia);
  };

  const handleAddImage = () => {
    if (media?.length >= 10) {
      setShowErrorModal(true);
      setErrorMessage("Can't add more than 10 photos");
      return;
    }
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.style.display = "none";
    input.addEventListener("change", handleChange);
    input.click();
  };

  useEffect(() => {
    dispatch(residentialAction?.onAddRes(media));
  }, [media]);

  return (
    <div className="col-md-12">
      <div className="form-group">
        <label>
          Photos <span style={{fontSize:"12px"}}>(.jpg / .png / .jpeg)</span>  <span className="mandatory">*</span>
        </label>
        <p>
          <span style={{fontSize:"12px"}}>(Max 10 uploads allowed{" - "} Max up to 5 MB per image)</span> 
        </p>
        <div className="added-photo-listing">
          <div
            className="photo-wrp placeholder"
            onClick={handleAddImage}
            style={{ textAlign: "center" }}
          >
            <span className="add-pic">
              <img src={plusImg} alt="Add" />
            </span>
          </div>
          {media?.map((photo, index) => (
            <div key={index} className="photo-wrp cursor-pointer">
              <img
                src={
                  location?.pathname?.includes(`${location?.pathname}`)
                    ? photo?.path || URL.createObjectURL(photo)
                    : URL?.createObjectURL(photo)
                }
                alt={`Photo ${index}`}
              />
              <span
                className="delete-pic"
                onClick={() => handleDeleteImage(index)}
              >
                <img src={cancleImg} alt="Delete" />
              </span>
            </div>
          ))}
        </div>
      </div>
      <Modal
        centered
        show={showErrorModal}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          color: "#FF5F5F",
          alignItems: "center",
        }}
        className="spinner-taste"
        onHide={() => {}}
      >
        <img
          src={require("../../assets/images/cross-cancel.png")}
          width="50"
          height="50"
          alt="Error!"
          onClick={() => setShowErrorModal(false)}
        />
        <span className="loading-msg">
          {errorMessage}
        </span>
      </Modal>
    </div>
  );
};

export default FileUploadComponent;
