import React, { Component } from "react";
import { Toast } from "react-bootstrap";

class ToastError extends Component {
  render() {
    return (
      <>
        {this.props.showError && (
          <Toast
            onClose={this.props.hideError}
            show={this.props.showError}
            delay={this.props?.delay || 3000}
            autohide
            className="list-group-item-warning"
          >
            <Toast.Body>
              <ul>
                {this.props.error &&
                  this.props.error?.data?.length>0&&this.props.error?.data.map(function (item) {
                    return <li key={item}>{item}</li>;
                  })}
              </ul>
            </Toast.Body>
          </Toast>
        )}
      </>
    );
  }
}

export default ToastError;
