import { httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const tourActionType = {
  Tours_Booking: "[Tours_Booking] Action",
  Tours_Booked: "[Tours_Booked] Action",
  Tours_Fetching: "[Tours_Fetching] Action",
  Tours_Fetched: "[Tours_Fetched] Action",
  Tours_Canceled: "[Tour_Canceled] Action",
  Tours_Calendar_Fetching: "[Tours_Calendar_Fetching] Action",
  Tours_Calendar_Fetched: "[Tours_Calendar_Fetched] Action",
};

const onBookATour = (data) => {
  return (dispatch) => {
    dispatch({ type: tourActionType.Tours_Booking });
    return httpClient
      .post(`${SERVER_APIS.Tours_URL}`, data)
      .then(({ data }) => {
        dispatch({
          type: tourActionType.Tours_Booked,
          payload: { tours: data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onUpdateATour = (data, id) => {
  return (dispatch) => {
    dispatch({ type: tourActionType.Tours_Booking });
    return httpClient
      .patch(`${SERVER_APIS.Tours_URL}/${id}`, data)
      .then(({ data }) => {
        dispatch({
          type: tourActionType.Tours_Booked,
          payload: { tours: data },
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => Promise.reject(data));
  };
};

const fetchTourList = (page = 1) => {
  return (dispatch) => {
    dispatch({ type: tourActionType.Tours_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Tours_URL}?page=${page}`)
      .then(({ data }) => {
        dispatch({
          type: tourActionType.Tours_Fetched,
          payload: { tours: data, page },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onCancelTour = (id) => {
  return (dispatch) => {
    return httpClient
      .delete(`${SERVER_APIS.Tours_URL}/${id}`)
      .then(({ data }) => {
        dispatch({
          type: tourActionType.Tours_Canceled,
          payload: { tours: data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const fetchingTourCalendar = (id) => {
  return (dispatch) => {
    dispatch({ type: tourActionType.Tours_Calendar_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Tours_Calendar_URL}/${id}`)
      .then(({ data }) => {
        dispatch({
          type: tourActionType.Tours_Calendar_Fetched,
          payload: { calendar: data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

export const tourAction = {
  bookATour: onBookATour,
  updateATour: onUpdateATour,
  fetchTours: fetchTourList,
  cancelTour: onCancelTour,
  fetchTourCalendar: fetchingTourCalendar,
};
