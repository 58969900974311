import { ErrorMessage } from "../constants";

export const mapErrorToField = (error) => {
  const fieldError = {};
  if (
    error &&
    error.message &&
    ErrorMessage.hasOwnProperty(error.message) &&
    error.data &&
    error.data.length > 0
  ) {
    error.data.forEach((e) => {
      let key = "general";
      let value = e;
      if (e.includes(":")) {
        const values = e.split(":");
        key = values[0];
        value = values[1].trim();
      }
      fieldError[key] = value;
    });
  }
  if (Object.keys(fieldError).length === 0) {
    return {
      general: typeof error === "string" ? error : JSON.stringify(error),
    };
  }
  return fieldError;
};
