import React, {Component} from "react";
import {Modal,Button} from "react-bootstrap"

class ConfirmationModalComponent extends Component {

render() {
  const {show,onCancel,onConfirm,title,message,cancelButtonName,confirmButtonName}=this.props;
  return ( 
          <Modal show={show} onHide={()=>onCancel()} className="d-flex h-100 w-100 align-items-center justify-content-center">
            <Modal.Header closeButton>
              <Modal.Title className="text-center w-100">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-between">
              <Button variant="secondary" onClick={()=>onCancel()}>
               {cancelButtonName}
              </Button>
             <Button variant="primary" onClick={()=>onConfirm()}>
               {confirmButtonName}
             </Button>
            </Modal.Footer>
         </Modal>
        )
    }
}
export default ConfirmationModalComponent;
