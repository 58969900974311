import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authActionTypes } from "../actions";
import * as routerHelpers from "../router/router-helpers";
import { STORAGE } from "../constants";

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  error: undefined,
  forgotPassword: false,
  isHost: undefined,
  device: undefined,
  isFetchingMe: false,
  me: {},
  isUpdatingMe: false,
  sessionInformation:{}
};

export const autReducer = persistReducer(
  {
    storage,
    key: STORAGE.AUTH_KEY,
    keyPrefix: STORAGE.PREFIX,
    whitelist: ["user", "authToken", "isHost", "device"],
  },
  (state = initialAuthState, action) => {
  
    switch (action.type) {
      case authActionTypes.Login:
      case authActionTypes.Register: {
        const { authToken, user } = action.payload;
        const isHost = user.role === "host";
        localStorage.setItem(STORAGE.AUTH_KEY, JSON.stringify(authToken));

        return { ...initialAuthState, authToken, user, isHost };
      }

      case authActionTypes.Auth_Failed: {
        const { error } = action.payload;
        return { ...initialAuthState, error };
      }

      case authActionTypes.Forgot_Password_Success: {
        const { forgotPassword } = action.payload;
        return { ...initialAuthState, forgotPassword };
      }

      case authActionTypes.Register_Device:
        const { device } = action.payload;
        return { ...state, device };

      case authActionTypes.Logout:
      case authActionTypes.Removed_Me: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case authActionTypes.Fetching_Me: {
        return { ...state, isFetchingMe: true };
      }
      case authActionTypes.Fetched_Me: {
        return { ...state, isFetchingMe: false, me: action.payload.me };
      }
      case authActionTypes.Expiring_Session: {
        return { ...state, isFetchingMe: true };
      }
      case authActionTypes.Expired_Session : {
        return { ...state, isFetchingMe: true ,sessionInformation: action.payload.sessionInfo };
      }
      case authActionTypes.Updating_Me: {
        return { ...state, isUpdatingMe: true };
      }
      case authActionTypes.Updated_Me: {
        return { ...state, isUpdatingMe: false, me: action.payload ? action.payload.me : state.me };
      }

      default:
        return state;
    }
  }
);
