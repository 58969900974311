import { generateQueryParams, httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const chatActionType = {
  Fetching_Chat: "[Fetching_Chat] Action",
  Fetched_Chat: "[Fetched_Chat] Action",
  Fetching_Active_Chat: "[Fetching_Active_Chat] Action",
  Fetched_Active_Chat: "[Fetched_Active_Chat] Action",
  Adding_Chat_Message: "[Adding_Chat_Message] Action",
  Added_Chat_Message: "[Added_Chat_Message] Action",
  Fetching_Chat_By_Name: "[Fetching_Chat_By_Name] Action",
  Fetched_Chat_By_Name: "[Fetched_Chat_By_Name] Action",
  Mark_Read: "[Mark_Read] Action",
  Facility_List: "[Facility_List] Action",
  Adding_Survey: "[Adding_Survey] Action",
  Fetch_Survey: "[Fetch_Survey] Action",
  Update_Survey: "[Update_Survey] Action",
};

const onFetchChats = () => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Fetching_Chat });
    return httpClient
      .get(`${SERVER_APIS.Fetch_Chats}`)
      .then((response) => {
        dispatch({
          type: chatActionType.Fetched_Chat,
          payload: { chats: response.data.sort(sortByLatestMsg) },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};
function sortByLatestMsg(selectedDate, prevDate) {
  return (
    new Date(
      prevDate.lastMessageAt
        ? prevDate.lastMessageAt
        : "1900-01-01T00:00:00.000Z"
    ).getTime() -
    new Date(
      selectedDate.lastMessageAt
        ? selectedDate.lastMessageAt
        : "1900-01-01T00:00:00.000Z"
    ).getTime()
  );
}

const onFetchByDialogueId = (id) => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Fetching_Active_Chat });
    return httpClient
      .get(`${SERVER_APIS.Fetch_Chats}/${id}`)
      .then((response) => {
     
        dispatch({
          type: chatActionType.Fetched_Active_Chat,
          payload: { activeChat: response.data.reverse() },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onAddChatMessage = (dialogueId, data) => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Adding_Chat_Message });
    return httpClient
      .post(`${SERVER_APIS.Fetch_Chats}/${dialogueId}/message`, data)
      .then((response) => {
     
        dispatch({
          type: chatActionType.Added_Chat_Message,
          payload: {},
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onSearchChatByName = (name) => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Fetching_Chat_By_Name });
    return httpClient
      .get(`${SERVER_APIS.Search_Chat}?name=${name}`)
      .then((response) => {
        dispatch({
          type: chatActionType.Fetched_Chat_By_Name,
          payload: { searchedChat: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onMarkRead = (chatId) => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Mark_Read, payload: { chatId } });
  };
};

const onFetchFacilityList = () => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Facility_List });
    return httpClient
      .get(`${SERVER_APIS.Survey_Fetch_List}`)
      .then((response) => {
        dispatch({
          type: chatActionType.Facility_List,
          payload: { facilitylist: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onAddSurvey = (data) => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Adding_Survey });
    return httpClient
      .post(`${SERVER_APIS.Add_Survey}`, data)
      .then((response) => {
        dispatch({
          type: chatActionType.Adding_Survey,
          payload: response,
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onFetchServeyById = (id) => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Fetch_Survey });
    return httpClient
      .get(`${SERVER_APIS.Fetch_Survey_By_Id}/${id}`)
      .then((response) => {
        dispatch({
          type: chatActionType.Fetch_Survey,
          payload: { surveyList: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onUpdateSurvey = (surveyId, data) => {
  return (dispatch) => {
    dispatch({ type: chatActionType.Update_Survey });
    return httpClient
      .put(`${SERVER_APIS.Update_Survey_Data}/${surveyId}`, data)
      .then((response) => {
        dispatch({
          type: chatActionType.Update_Survey,
          payload: response,
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

export const chatActions = {
  fetchChats: onFetchChats,
  fetchActiveChat: onFetchByDialogueId,
  addChatMessage: onAddChatMessage,
  searchChatByName: onSearchChatByName,
  markRead: onMarkRead,
  facilityList: onFetchFacilityList,
  addingSurvey: onAddSurvey,
  fetchSurveyById: onFetchServeyById,
  updateSurvey: onUpdateSurvey,
};
