import { surveyActionType } from "../actions";

const intialChatState = {
  surveyQuestiones: [],
  surveyResponse:null
};
export const surveyReducer = (state = intialChatState, action) => {
  switch (action.type) {
    case  surveyActionType.Fetching_surveyQuestion:
      return { ...state, fetchingChats: true };
    case surveyActionType.Fetched_surveyQuestion:
      return { ...state, fetchingChats: false, surveyQuestiones:action.payload?.questions };
      case surveyActionType.Submitting_SurveyResponse:
        return { ...state, fetchingChats: true };
      case surveyActionType.SurveyResponse_Submitted :
       
        return { ...state, fetchingChats: false, surveyResponse:action.payload };
    default:
      return state;
  }
};
