import React, { useState, useEffect, useRef } from "react";
import pinImg from "../../../assets/images/ic_pin_small.svg";
import starImg from "../../../assets/images/star-24px.svg";
import NoImage from "../../../assets/images/no-image.svg";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import tickMarkIcon from "../../../assets/images/tick-mark.svg";
import SliderNextArrow from "../../../components/slider/imageSliderNext";
import SliderPrevArrow from "../../../components/slider/imageSliderPrev";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import accountImgMobile from "../../../assets/images/account-mobile.png";

const FacilityList = ({
  getRecordsPerPage,
  recordsPerPage,
  activeFacility,
  setRecordsperPage,
}) => {
  const history = useHistory();
  const state = useSelector((state) => state);
  const facilityList = useSelector((state) => state?.home?.facilityList);
  const [displayCount, setDisplayCount] = useState(9);
  var settings1 = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };

  const handleShowMore = () => {
    getRecordsPerPage(displayCount + recordsPerPage);
  };

  return (
    <>
      {facilityList?.facilities?.length !== 0 ? (
        <div className="row">
          {facilityList?.facilities?.map((ele) => {
            return (
              <div className="col-md-6 col-xl-4">
                <Link
                  className="text-decoration-none"
                  to={{ pathname: `/home/centers/view/${ele?.id}` }}
                >
                  <div className="facility-list-item">
                    <div className="img-slider position-relative">
                      {ele?.subscriptionType === "pro" ||
                      ele?.subscriptionDetail?.subscriptionType === "pro" ? (
                        <Slider {...settings1}>
                          {ele?.photos?.length > 0 ? (
                            ele?.photos?.map((item) => {
                              return (
                                <img
                                  src={item?.path}
                                  className="img-fluid facility-img"
                                  alt=""
                                />
                              );
                            })
                          ) : (
                            <img
                              src={NoImage}
                              className="img-fluid facility-img"
                              alt=""
                            />
                          )}
                        </Slider>
                      ) : (
                        <Slider {...settings1}>
                          {ele?.photos?.length > 0 ? (
                            <img
                              src={ele?.photos[0]?.path}
                              className="img-fluid facility-img"
                              alt=""
                            />
                          ) : (
                            <img
                              src={NoImage}
                              className="img-fluid facility-img"
                              alt=""
                            />
                          )}
                        </Slider>
                      )}
                      {/* {(ele?.preSalePlanType === "pro" || ele?.subscriptionDetail?.subscriptionType === "pro") && <span class="subscription-msg unsubscribed">Featured</span>} */}
                      {ele?.isPPCSubscribed && (
                        <span className="promote-ad-msg">Ad</span>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="card-heading">
                        {ele?.name?.length > 30
                          ? ele?.name
                              ?.slice(0, 30)
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ") + "...."
                          : ele?.name
                              ?.split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")}
                        {ele?.isVerified && (
                          <img
                            src={tickMarkIcon}
                            className="img-fluid"
                            alt=""
                          />
                        )}
                      </div>
                      <span class="location">
                        <img src={pinImg} class="img-fluid" alt="" />
                        {ele?.geocodingResult?.cityName}
                        {", "}
                        {ele?.geocodingResult?.stateShort}
                        {", "}
                        {ele?.geocodingResult?.country}
                      </span>

                      {(ele?.isSubscribed || ele?.isPreSaleSubscribed) && (
                        <span class="location">
                          <img src={starImg} class="img-fluid m-0" alt="" />
                          {ele?.rating?.value !== 0 ? (
                            <>
                              <span>{ele?.rating?.value}</span> /{" "}
                              <span>{ele?.rating?.quantity}</span>
                            </>
                          ) : (
                            <>
                              <span>0.0</span>/<span>0</span>
                            </>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}

          {facilityList?.total > recordsPerPage && (
            <div className="col-md-12 mt-3">
              <p className="d-none d-md-flex flex-column flex-md-row justify-content-center">
                Continue exploring facilities
              </p>
              <div class="d-flex flex-column flex-md-row justify-content-center mt-sm-3">
                <button
                  type="submit"
                  class="btn btn-primary radius-20"
                  onClick={handleShowMore}
                >
                  Show more
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="col-md-12">
          <div className="no-facilities">
            <p>NO FACILITIES LISTED…YET!</p>
            <img src={accountImgMobile} className="img-fluid" alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default FacilityList;
