import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import homeImg from "../../assets/images/map_xl.png";
import { getCityName } from "../../utils";
import { seoUrlText } from "../../constants";

const MarkerComponent = (props) => {
  const { property } = props;
  let city = getCityName(property?.geocodingResult);

  return (
    <div
      style={{
        display: "inline-flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        transform: "translate(-50%, -50%)",
      }}
      onClick={(e) => {
        props.history &&
          props.history.push({
            pathname: `/living/${seoUrlText}-${city}&id=${property?.id}`,
            state: { property: property },
          });
      }}
      //  data-toggle="tooltip" data-placement="top" title={property?.id}
    >
      <img
        src={homeImg}
        style={{
          height: 70,
          width: 60,
        }}
      ></img>
    </div>
  );
};

class GoogleMapComponent extends Component {
  static defaultProps = {
    center: {
      lat: 42.42254053329317,
      lng: -71.00966335367497,
    },
    zoom: 12,
    markers: [],
    newCenter: {
      lat: 42.42254053329317,
      lng: -71.00966335367497,
    },
  };

  // shouldComponentUpdate(nextProps) {
  //   // Rendering the component only if
  //   // passed props value is changed

  //   if (JSON.stringify(nextProps.markers) !== JSON.stringify(this.props.markers)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  render() {
    return (
      <div className={this.props.className}>
        <GoogleMapReact
          resetBoundsOnResize
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API }}
          // defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          center={this.props.newCenter}
          onBoundsChange={this.onBoundsChange}
          libraries={["places"]}
          onClick={(e) => {
            if (this.props.handleClick) {
              this.props.handleClick(e);
            }
          }}
        >
          {this.props.markers.map((marker, index) => {
            return (
              <MarkerComponent
                key={index}
                lat={marker.lat}
                lng={marker.lng}
                property={this.props.properties && this.props.properties[index]}
                history={this.props.history && this.props.history}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }

  onBoundsChange = (center, zoom, bounds, marginBounds) => {
    /* this.props.onCenterChange(center);
    this.props.onZoomChange(zoom); */
    if (this.props.onBoundsChange) {
      let boundsCoordinates = {
        coordinates: {
          lat: center[0],
          lng: center[1],
        },
        boundsCoordinates: {
          topLeft: {
            lat: bounds[0],
            lng: bounds[1],
          },
          bottomRight: {
            lat: bounds[2],
            lng: bounds[3],
          },
        },
      };
      this.props.onBoundsChange(boundsCoordinates);
    }
  };
}

export default GoogleMapComponent;
