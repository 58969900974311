import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import Rater from 'react-rater';
import { LivingStatus } from '../../constants';
import moment from 'moment';
import { SpinnerContext } from '../../components/spinner/spinner.component';
import { connect } from 'react-redux';

class ReviewReplyForm extends Component {
  static contextType = SpinnerContext;
  reviewReplySchema = Yup.object().shape({
    comment: Yup.string().required('Review is required'),
    livedFrom: Yup.date().max(new Date(), 'Date should not be greater than the current date'),
    livedTo: Yup.date().when(['livedFrom', 'livingStatus'], (st, status) => {
      if (status === LivingStatus.Toured) {
        return Yup.date().max(new Date(), 'Date should not be greater than the current date');
      }
      // return Yup.date().min(st).max(new Date(), 'Date should not be greater than the current date');
      return Yup.date().max(new Date(), 'Date should not be greater than the current date');
    }),    
    livingStatus: Yup.string(),
    customName: Yup.string(),
    value: Yup.number().integer().min(1, 'Select at least 1 star').required()
  });
  hostReviewReplyScehma = Yup.object().shape({
    content: Yup.string().required('Review is required')
  });

  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      isTap: false
    };
    this.onReview = this.onReview.bind(this);
    this.onReplyReview = this.onReplyReview.bind(this);
  }

  showForm = ({ values, handleChange, handleSubmit, isSubmitting, errors, touched }) => {
    return (
      <Form
        onSubmit={handleSubmit}
        onClick={(e) => {
          if (!this.props.user) {
            e.preventDefault();
            this.context.showLogin(true);
          }
        }}
      >
        <div className="review-comment">
          <div className="rate-here d-block">
            <div
              className="rating d-block"
              onClick={() => {
                this.setState({ isTap: true });
              }}
            >
              <Rater total={5} rating={values.value} onRating={({ rating }) => (values.value = rating)} />
              {errors.value && <ErrorMessage component="div" className="error-message" name="value" value={errors.value} />}
            </div>
            <div className="mr-2 d-block">Tap to review</div>
          </div>
          <div className={`review-rating-form ${this.state.isTap ? '' : 'd-none'}`}>
            <h4 className="sub-title">REVIEW '{this.props.propertyName}'</h4>
            {this.props.user && (
              <div>
                <span>Your review</span>
                <Field component="textarea" name="comment" className="form-control" required />
                <div className="form-group mt-3">
                  <label>Review as</label>
                  <select className="form-control" name="customName" value={values.customName} onChange={handleChange}>
                    <option value={'Anonymous'}>Anonymous</option>
                    <option value={this.props.userName}>{this.props.userName}</option>
                  </select>
                  {/* <Field type="input" name="customName" className="form-control" disabled={true} /> */}
                </div>
                <div className="form-group mt-3">
                  <select className="form-control" name="livingStatus" value={values.livingStatus} onChange={handleChange}>
                    <option value={LivingStatus.Lived}>I lived at this sober living</option>
                    <option value={LivingStatus.Toured}>I toured this sober living</option>
                  </select>
                </div>
                <div className="form-group ">
                  {values.livingStatus === LivingStatus.Lived && (
                    <div className="form-group mt-3">
                      <label className="m-0">Lived From</label>
                      <Field name="livedFrom" className="form-control" type="date" placeholder="Choose From Date" defaultValue={moment().format('MM-DD-YYYY')} />
                      {touched.livedFrom && errors.livedFrom && <ErrorMessage component="div" className="error-message" name="livedFrom" />}
                    </div>
                  )}
                  <div className="form-group mt-3">
                    <label className="m-0"> {values.livingStatus === LivingStatus.Toured ? 'Toured At' : 'Lived To'}</label>
                    <Field name="livedTo" className="form-control" type="date" placeholder="Choose To Date" />
                    {touched.livedTo && errors.livedTo && <ErrorMessage component="div" className="error-message" name="livedTo" value={errors.livedTo} />}
                  </div>
                </div>
                <Button type="submit" disabled={isSubmitting} className="btn btn-primary radius-20 mb-5">
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form>
    );
  };

  showHostReplyForm = ({ values, handleChange, handleSubmit, isSubmitting }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="review-comment">
          {/* <span>Your reply</span> */}
          <Field component="textarea" name="content" className="form-control mt-2" placeholder="Your reply" required />

          <Button type="submit" disabled={isSubmitting} className="btn btn-primary radius-20 mt-3">
            Submit
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    return (
      <div>
        <div className="reply-review">
          <Formik
            initialValues={
              this.props.isHost
                ? { content: '' }
                : {
                    comment: '',
                    livedTo: moment().format('MM-DD-YYYY'),
                    livedFrom: moment().format('MM-DD-YYYY'),
                    livingStatus: LivingStatus.Lived,
                    customName: this.props.userName,
                    value: 0
                  }
            }
            onSubmit={this.props.isHost ? this.onReplyReview : this.onReview}
            validationSchema={this.props.isHost ? this.hostReviewReplyScehma : this.reviewReplySchema}
          >
            {(props) => (this.props.isHost ? this.showHostReplyForm(props) : this.showForm(props))}
          </Formik>
        </div>
      </div>
    );
  }

  onReview(values, { props, setErrors, setSubmitting, resetForm }) {
    const review = { ...values };
    if (values.livingStatus === LivingStatus.Toured) {
      review.touredAt = values.livedTo;
      delete review.livedTo;
    }
    review.isAnonymous = values.customName === 'Anonymous';
    this.props.submitReview(review).then(() => {
      setSubmitting(false);
      resetForm({});
      this.context.showMessage('Review added successfully!!!');
      this.setState({ isTap: false });
    });
  }

  onReplyReview(values, { props, setErrors, setSubmitting, resetForm }) {
    const review = { ...values };
    this.props.submitReview(review).then((res) => {
      setSubmitting(false);
      resetForm({});
      this.context.showMessage('Reply added successfully!!!');
    });
  }
}

export default connect(({ auth }) => ({
  user: auth.user,
  isHost: auth.isHost
}))(ReviewReplyForm);
