import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { ReactComponent as TickIcon } from "../../assets/images/tick_check_mark.svg";
import { Toast } from "react-bootstrap";
const ConfirmPayment = ({
  clientSecret,
  childCallback,
  parentCallback,
  allDetails,
  showSuccessPopup,
}) => {
  const stripe = useStripe();
  let history = useHistory();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const subscribePlan = async () => {
    try {
      const payment = await stripe.confirmPayment({
        clientSecret,
        confirmParams: {
          return_url: window.location.href,
        },

        redirect: "if_required",
      });
      setShowSuccessModal(true);

      setTimeout(() => {
        history.push("/profile");
      }, 3000);
      return payment;
    } catch (e) {}
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        Do you want to Confirm Subscription of "
        {allDetails?.data?.houseType || allDetails?.data?.livingName}" with
        price of $
        {allDetails?.data?.priceAmount || allDetails?.data?.selectedBudget} and
        card **** **** **** {allDetails.data.selectedCard?.last4} ?
        Subscriptions are automatically renewed and billed on a monthly basis
        until you cancel. You may change or cancel your subscription at any
        time. All subscription payments are non-refundable. Taste Recovery terms
        apply.
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => childCallback(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            parentCallback("paymentPage");
            subscribePlan();
            setIsButtonDisabled(true);
          }}
          disabled={isButtonDisabled}
        >
          Subscribe
        </Button>
      </Modal.Footer>

      <Modal show={showSuccessModal} centered>
        <Modal.Body
          style={{
            padding: "86px",
            backgroundColor: "white",
            borderRadius: "10px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center items horizontally
            justifyContent: "center", // Center items vertically
          }}
        >
          <Toast.Body>
            <TickIcon />
            <h5 style={{ marginTop: "10px" }}>
              Your subscription plan has been completed.
            </h5>
          </Toast.Body>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmPayment;
