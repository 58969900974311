import React from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { loadScript } from "../../utils";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.value ?? "",
      isReady: false,
     
    };
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);
    Geocode.setLanguage("en");
   
  }
  componentDidMount() {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API}&libraries=places`,
        () => this.setState({ isReady: true })
      );
    } else {
      this.setState({ isReady: true });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ address: this.props.value ?? "" });
    }
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    //console.log(address,"qw")
    address = address.formatted_address ?? address;

    this.setState({ address,  });
   
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
       // console.log(latLng,"qw1")
        if (this.props.fetchStateZip) {
          this.fetchStateZipCode({
            cityName: address,
            coordinates:latLng,
            ...latLng,
          });
        } else {
          this.props.onCitySelect({
            cityName: address,
            coordinates:latLng,
            ...latLng,
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  fetchStateZipCode = (cityData) => {
    Geocode.fromLatLng(cityData.lat, cityData.lng).then(
      (response) => {
        if (response && response.results[0]) {
          var postal = null;
          var state = null;
          var stateLong = null;
          const result = response.results;
          for (var i = 0; i < result.length; ++i) {
            if (result[i].types[0] == "postal_code" ||result[i].types[0]== "plus_code") {
              postal = result[i].address_components[0].long_name;
            }
            if (result[i].types[0] == "administrative_area_level_1") {
              state = result[i].address_components[0].short_name;
              stateLong = result[i].address_components[0].long_name;
            }
          }
          this.props.onCitySelect({
            zipCode: postal,
            state: state,
            stateLong: stateLong,
            ...cityData,
          });
        }
      },
      (error) => {
        this.props.onCitySelect(cityData);
      }
    );
  };



  render() {
    return (
      <>
        {this.state.isReady && (
          <div>
            <Autocomplete
              className="form-control"
              // apiKey={YOUR_GOOGLE_MAPS_API_KEY}
              onPlaceSelected={this.handleSelect}
              placeholder={this.props.placeholder}
              defaultValue={this.state.address}
             
            />
          </div>
        )}
      </>
    );
  }
}

export default LocationSearchInput;