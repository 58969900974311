/** @format */

import React, { Component } from "react";
import backImgWhite from "../../assets/images/back-white-arrow.svg";
import backImgBlack from "../../assets/images/back.svg";
import { withRouter } from "react-router-dom";

class BackButtonComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={this.props.className}
      >
        <a role="button"  onClick={() => {
          this.props?.pageName === "Profile"
            ? this.props.history.push("/")
            : this.props.history.goBack();
        }}>
          <img src={this.props.isBlack ? backImgBlack : backImgWhite} alt="" />
        </a>
      </div>
    );
  }
}

export default withRouter(BackButtonComponent);
