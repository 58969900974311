
import React from 'react';
import slickPrevArrow from '../../assets/images/arrow_left.svg';

const SliderPrevArrow = (props) => {
  const { className, style, onClick, currentSlide } = props;

  const handleOnClick = (event) => {
    if (currentSlide !== 0) {
      onClick(event);
    }
  };

  return (
    <div
      className={`slick-arrow prev-arrow ${currentSlide === 0 ? 'disabled' : ''}`}
      onClick={handleOnClick}
      style={{ pointerEvents: currentSlide === 0 ? 'none' : 'auto' }}
    >
      <img src={slickPrevArrow} className="img-fluid" alt="" />
    </div>
  );
};

export default SliderPrevArrow;

