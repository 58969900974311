import axios from "axios";
import join from "url-join";
import { STORAGE } from "../constants";


const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(process.env.REACT_APP_API_BASE_URL, config.url);
  }



  const authData = JSON.parse(
    localStorage.getItem(STORAGE.PREFIX + STORAGE.AUTH_KEY)
  );
  if (authData.authToken) {
    localStorage.removeItem(STORAGE.AUTH_KEY)
  }

  const authToken =
    authData && authData.authToken ? JSON.parse(authData.authToken) : localStorage.auth ? JSON.parse(localStorage.auth) : undefined;
  if (authToken) {
    config.headers = { Authorization: `Bearer ${authToken}` };
  }
  config.timeout = 90000; // 90 Second
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // debugger;
    /*if (error.response.status === "401") {
            const refreshToken = storage.getStorage(server.REFRESH_TOKEN_KEY);
            const refreshUrl = `${process.env.API_BASE_URL}/${server.REFRESH_TOKEN_URL}`;
            let result = await axios.post(refreshUrl, { refreshToken });

            const token = result.data.jwt;
            storage.setStorage(server.TOKEN_KEY, token);
            // debugger;
            return axios.request(error.config);
        } else if (error.response.status === "403") {
            // force logout
            storage.removeStorage(server.TOKEN_KEY);
            storage.removeStorage(server.REFRESH_TOKEN_KEY);
        }*/

    return Promise.reject(error);
  }
);

export const httpClient =  axios;

export const getQueryParams = (t) => {
  if (!t || "string" != typeof t || t.length < 2) return new Map();
  const r = t
    .substr(1)
    .split("&")
    .map((t) => {
      const r = t.split("=");
      return [r[0], r[1]];
    });
  return new Map(r);
};

export const generateQueryParams = (t) => {
  if (!t || typeof t !== "object") return "";
  let query = "";
  for (const [key, value] of Object.entries(t)) {
    query += `${key}=${value}&`;
  }
  return query;
};
