import React, { Component } from 'react';
import ProfileHost from "./profileHost.page";
import ProfileUser from "./profileUser.page";
import { connect } from 'react-redux';
import ReactHelmet from '../../components/react-helmet/react-helmet';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
             <ReactHelmet title={"Taste Recovery - Profile"} description={"Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."}/>
                {this?.props?.isHost ? <ProfileHost {...this?.props} /> : <ProfileUser {...this?.props} />}
            </>
        )
    }
}

export default connect(
    ({ auth }) => ({
        isHost: auth.isHost
    }), {}
)(Profile);