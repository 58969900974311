import React, { Component } from 'react';
import mcImg from '../../assets/images/mc.svg';
import visaImg from '../../assets/images/visa.svg';
import ovalRight from '../../assets/images/oval-right.png';
import { connect } from 'react-redux';
import { paymentActions } from '../../actions';
import { SpinnerContext } from '../../components/spinner/spinner.component';

class PaymentCards extends Component {
  static contextType = SpinnerContext;
  static defaultProps = {
    selectedCard: undefined,
    onSelectCard: (id) => { }
  };

  componentDidMount() {
    if (!this.props.isFetching) {
      this.context.showLoader('Get Cards...');
      this.props.getCards().finally(() => this.context.hideLoader());
    }
  }
  render() {
    return (
      <>
        {this.props.cards.map((card, index) => {
          return (
            <div className="row mt-4" key={index}>
              <div className="col-md-6">
                <div className="card-view cursor-pointer" onClick={() => this.props.onSelectCard(card)}>
                  <div className="card-top">
                    <img src={card.cardBrand === 'visa' ? visaImg : mcImg} alt="" />
                    {this.props.selectedCard === card.id && (
                      <div className="selected-card">
                        <img src={ovalRight} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="card-number">
                    <span className="dots">. . . .</span>
                    <span className="dots">. . . .</span>
                    <span className="dots">. . . .</span>
                    <span className="num">{card.last4}</span>
                  </div>
                  <div className="card-bottom">
                    <div className="card-holder">
                      <span className="ttl">Cardholder</span>
                      <span className="val"> {card.cardHolderName}</span>
                    </div>
                    <div className="card-expiry">
                      <span className="ttl">Expires end</span>
                      <span className="val">
                        {card.expirationMonth}/{card.expirationYear}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {this.props.cards.length === 0 && !this.props.isFetching && (
          <div className="no-records">
            <h2>OOPS.. NO CARDS ADDED</h2>
            <p>Your credit cards will show up here</p>
          </div>
        )}
      </>
    );
  }
}

export default connect(
  ({ payment }) => ({
    isFetching: payment.fetchingCards,
    cards: payment.cards,
    error: payment.error
  }),
  paymentActions
)(PaymentCards);
