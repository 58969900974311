import React, { Component } from "react";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { connect } from "react-redux";
import BackButtonComponent from "../../components/other/back-button.component";
import { livingAction, authAction } from "../../actions";
import moment from "moment";
import ActivityLineGraph from "../../components/graph/line.graph";
import {
  organicDataChartConfigs,
  sponsoredDataChartConfigs,
} from "../../constants/activity-graph.constant";
import { bindActionCreators } from "redux";
import MediaQuery from "react-responsive";
import { Modal, FormCheck, Toast } from "react-bootstrap";
import TourDatePicker from "../living/select-tour-dates";
import backImgBlack from "../../assets/images/back.svg";
import accountImgDesktop from "../../assets/images/accout-desktop.png";
import accountImgMobile from "../../assets/images/account-mobile.png";
import IOPCenterCard from "../../components/cards/iop-center.card";
import ResidencialCard from "../../components/cards/residencial-center-profile.card";
import DetoxCard from "../../components/cards/detox-center-profile.card";
import PhpCard from "../../components/cards/php-center-profile.card";

import SoberLivingProfileCard from "../../components/cards/sober-living-profile.card";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import UnclaimedFacilities from "./unclaimedFacilities";

class ProfileHost extends Component {
  static contextType = SpinnerContext;

  static defaultProps = {
    isFromSignUp: false,
  };

  isFromSignUp = false;
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      livingId: null,
      scheduleTour: false,
      isMobileDevice: false,
      isShowDropDown: false,
      toursAvailability: {
        isAvailableForTours: false,
        isAvailableCurrently: false,
        availableForToursDates: [],
      },
      dropdownclose: false,
      errors: null,
    };
    if (this.props?.location?.state) {
      const { isFromSignUp } = this.props?.location?.state;
      this.isFromSignUp = isFromSignUp;
    }
  }

  _APICalls() {
    if (!this.props.isFetchinhostLivings) {
      this.setState({ isVisible: true });
      this.context.showLoader("Fetching facility centers for you..");
      Promise.all([
        this.props?.fetchhostLivings(),
        this.props?.getActivity(),
        this.props?.getUser(),
        this.props.unclaimedList(),
      ]).finally(() => {
        if (this.context.addSubscriptionPage === "addSubscriptionPage") {
          this.reloadPage();
        }
        this.context.hideLoader();
        this.setState({ isVisible: false });
      });
    }
  }

  reloadPage = () => {
    this.context.showLoader("Fetching sober living for you..");
    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  async componentDidMount() {
    if (this.isFromSignUp) {
      window.history.replaceState(null, "");
      this.isFromSignUp = false;
      this.props.history.push("/profile");
    } else {
      await this._APICalls();
    }
    await this._APICalls();
    this.fetchDetails();
    this.props.clearSearch();
  }

  fetchDetails = () => {
    if (this.context.subscribePage === "paymentPage") {
      this.setState(
        {
          pageReload: true,
        },
        () => {
          this.loadPage();
        }
      );
    }
  };

  generateLabels = (activity) => {
    const modifiedLabels = activity.map((d) => {
      return moment(d.date).format("D");
    });
    return modifiedLabels.reverse();
  };

  getSponsoredData = (activity) => {
    const data = activity?.map((d) => {
      return d?.sponsored;
    });
    return data?.reverse();
  };

  getOrganicData = (activity) => {
    const data = activity?.map((d) => {
      return d?.organic;
    });
    return data?.reverse();
  };

  getChartData = (data) => {
    let chartData = {};
    if (data) {
      const labels = this.generateLabels(data && data?.activity);
      chartData.labels = labels;
      chartData.datasets = [
        {
          ...sponsoredDataChartConfigs,
          data: this.getSponsoredData(data?.activity),
        },
        {
          ...organicDataChartConfigs,
          data: this.getOrganicData(data?.activity),
        },
      ];
    }
    return chartData;
  };

  deleteLiving = (id) => {
    this.context.showLoader("Deleting Sober Living..");
    this.props
      .deleteLiving(id)
      .then(async (res) => {
        await this._APICalls();
      })
      .catch((err) => {})
      .finally(() => {
        this.context.hideLoader();
        window.location.reload();
      });
  };

  deleteLivingIop = (id) => {
    this.context.showLoader("Deleting IOP Center..");
    this.props
      .deleteIop(id)

      .catch((err) => {})
      .finally(async () => {
        this.context.hideLoader();
        await this._APICalls();
        this.fetchDetails();
      });
  };

  deleteResidencial = (id) => {
    this.props
      .deleteResidencial(id)
      .catch((err) => {})
      .finally(async () => {
        this.context.hideLoader();
        await this._APICalls();
        this.fetchDetails();
      });
  };

  scheduleTour = () => {
    const { livingId, toursAvailability } = this.state;
    this.context.showLoader("Scheduling Tour..");
    const data = { ...toursAvailability };
    data.availableForToursDates = data?.availableForToursDates?.map(
      (d) => new Date(d)
    );
    this.props
      .updateAvailablity(livingId, data)
      .then((res) => {
        this.context.setStickyFooter(true);
        this.setState({ livingId: null, scheduleTour: false });
      })
      .catch((err) => {
        this.setState({ errors: err.data });
      })
      .finally(() => {
        this.context.hideLoader();
      });
  };

  loadPage = () => {
    this.context.showLoader("Fetching sober living for you ...");
    setTimeout(() => {
      window.location.reload(true);
    }, 5000);
  };

  render() {
    const { activity, me } = this.props;
    const {
      scheduleTour,
      isMobileDevice,
      toursAvailability: {
        isAvailableCurrently,
        isAvailableForTours,
        availableForToursDates,
      },
    } = this.state;

    const isEmptyHostLiving = Object.values(this?.props?.hostLivings).every(
      (array) => array?.length === 0
    );

    const Add_Sober_Living_Btn = () => {
      return (
        <div className="profile-add-btn">
          <button
            onClick={() => {
              this.props.history.push(`living/add-living`);
            }}
            className="btn btn-primary my-4 radius-20"
          >
            Add Sober Living
          </button>
        </div>
      );
    };

    const Update_Availability = (showCloseButton = true) => {
      return (
        <>
          <Modal.Header closeButton={showCloseButton}>
            <Modal.Title>Select Tours and Availability</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group dflex-form no-border">
                <label>Do you have available beds currently?</label>
                <FormCheck custom type="switch">
                  <FormCheck.Input
                    isValid={isAvailableCurrently}
                    checked={isAvailableCurrently}
                    onChange={() => {}}
                  />
                  <FormCheck.Label
                    onClick={() => {
                      this.setState({
                        toursAvailability: {
                          ...this.state.toursAvailability,
                          isAvailableCurrently: !isAvailableCurrently,
                        },
                      });
                    }}
                  ></FormCheck.Label>
                </FormCheck>
              </div>
              <div className="form-group dflex-form no-border">
                <label>
                  Would you like your living to be available for tours?
                </label>
                <FormCheck custom type="switch">
                  <FormCheck.Input
                    isValid={isAvailableForTours}
                    checked={isAvailableForTours}
                    onChange={() => {}}
                  />
                  <FormCheck.Label
                    onClick={() => {
                      this.setState({
                        toursAvailability: {
                          ...this.state?.toursAvailability,
                          isAvailableForTours: !isAvailableForTours,
                        },
                      });
                    }}
                  ></FormCheck.Label>
                </FormCheck>
              </div>

              <label>
                Choose dates and times when you can receive tours at your living
              </label>
              <TourDatePicker
                enabled={isAvailableCurrently || isAvailableForTours}
                mobileView={true}
                handleChange={(values) => {
                  this.setState({
                    toursAvailability: {
                      ...this.state?.toursAvailability,
                      availableForToursDates: values,
                    },
                  });
                }}
                values={availableForToursDates}
              />
            </div>
            <Toast
              onClose={() => this.setState({ errors: null })}
              show={this.state.errors}
              className="list-group-item-warning"
            >
              <Toast.Body>
                <ul>
                  {this.state?.errors?.map(function (item) {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              </Toast.Body>
            </Toast>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                onClick={this.scheduleTour}
                className="btn btn-primary radius-20 btn-min-width"
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </>
      );
    };

    return (
      <div>
        {scheduleTour && (
          <MediaQuery maxWidth={767.98}>
            {(matches) => (
              <>
                {matches ? (
                  <div className="availability-back-btn">
                    <a
                      onClick={() => {
                        this.context.setStickyFooter(true);
                        this.setState({ scheduleTour: false, errors: null });
                      }}
                    >
                      <img src={backImgBlack} alt="" />
                    </a>
                    {Update_Availability(false)}
                  </div>
                ) : (
                  <Modal
                    centered
                    show={true}
                    onHide={() => {
                      this.setState({
                        scheduleTour: false,
                        livingId: null,
                        errors: null,
                      });
                    }}
                  >
                    {Update_Availability()}
                  </Modal>
                )}
              </>
            )}
          </MediaQuery>
        )}

        {(!scheduleTour || (scheduleTour && !isMobileDevice)) && (
          <>
            <section className="back responsive-back">
              <BackButtonComponent
                className="container"
                isBlack={true}
                pageName="Profile"
              />
            </section>
            <section className="listing sober-living-listing-wrapper pt-2 mt-2">
              <div className="container">
                <div className="listing-header profile-listing-header">
                  <h3 className="main-title mb-0">Account</h3>
                  <div className="new-owner_content p-0">
                    <div
                      className={
                        !this.state.isShowDropDown
                          ? "dropdown show"
                          : "dropdown"
                      }
                    >
                      {((!isEmptyHostLiving || this.props?.unclaimList?.length > 0 ) && !this.state.isVisible) && (
                        <button
                          className="btn btn-secondary dropdown-toggle btn btn-primary radius-20"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            this.setState({
                              isShowDropDown: !this.state.isShowDropDown,
                            });
                          }}
                        >
                          Create Facility Type
                        </button>
                      )}
                        {((this.state.isShowDropDown && !isEmptyHostLiving ) || (this.state.isShowDropDown && this.props?.unclaimList?.length > 0))  && (
                        <div
                          className="dropdown-menu show"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <ul>
                            <li className="mobile-title d-block d-md-none">
                              Choose Facility Type
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="detoxfacility/add-detox"
                              >
                                Detox
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="residentialfacility/add-residential"
                              >
                                Residential
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="phpfacility/add-php">
                                PHP
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/iopfacility/add-iopcenter"
                              >
                                IOP
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="living/add-living"
                              >
                                Sober Living
                              </Link>
                            </li>
                            <li
                              className="mobile-action d-block d-md-none"
                              onClick={() => {
                                this.setState({
                                  dropdownclose: !this.state.dropdownclose,
                                });
                              }}
                            >
                              <Link className="dropdown-item" to="#">
                                Cancel
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {(!this.state.isVisible  && this?.props?.unclaimList?.length < 0) ||
                (this?.props?.unclaimList?.length !== 0 || !isEmptyHostLiving) ? (
                  <>
                    {this.props?.hostLivings?.detox?.length !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="listing-sub-header test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2">
                              <h4 className="main-title mb-0">DETOX</h4>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="slider-wrapper">
                              <div className="sober-living-slider living d-flex flex-column">
                                {this.props?.hostLivings?.detox?.map(
                                  (item, index) => (
                                    <DetoxCard
                                      item={item}
                                      pageName="Profile"
                                      showPromoted={false}
                                      ratingOutside={true}
                                      isRecommended={false}
                                      showActionItems={true}
                                      preSalePlanType={item?.preSalePlanType}
                                      isPreSaleSubscribed={
                                        item?.isPreSaleSubscribed
                                      }
                                      isSubscribe={item?.isSubscribed}
                                      subscriptionDetail={
                                        item?.subscriptionDetail
                                      }
                                      onDeleteLiving={() =>
                                        this.deleteResidencial(item?.id)
                                      }
                                      isHost={this?.props?.isHost}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {this.props?.hostLivings?.residential?.length !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="listing-sub-header test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2">
                              <h4 className="main-title mb-0">RESIDENTIAL</h4>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="slider-wrapper">
                              <div className="sober-living-slider living d-flex flex-column">
                                {this.props?.hostLivings?.residential?.map(
                                  (item, index) => (
                                    <ResidencialCard
                                      item={item}
                                      pageName="Profile"
                                      showPromoted={false}
                                      ratingOutside={true}
                                      isRecommended={false}
                                      preSalePlanType={item?.preSalePlanType}
                                      isPreSaleSubscribed={
                                        item?.isPreSaleSubscribed
                                      }
                                      isSubscribe={item?.isSubscribed}
                                      subscriptionDetail={
                                        item?.subscriptionDetail
                                      }
                                      showActionItems={true}
                                      onDeleteLiving={() =>
                                        this.deleteResidencial(item?.id)
                                      }
                                      isHost={this?.props?.isHost}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {this.props?.hostLivings?.php?.length !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="listing-sub-header test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2">
                              <h4 className="main-title mb-0">PHP</h4>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="slider-wrapper">
                              <div className="sober-living-slider living d-flex flex-column">
                                {this.props?.hostLivings?.php?.map(
                                  (item, index) => (
                                    <PhpCard
                                      item={item}
                                      pageName="Profile"
                                      showPromoted={false}
                                      ratingOutside={true}
                                      isRecommended={false}
                                      showActionItems={true}
                                      isSubscribe={item?.isSubscribed}
                                      subscriptionDetail={
                                        item?.subscriptionDetail
                                      }
                                      preSalePlanType={item?.preSalePlanType}
                                      isPreSaleSubscribed={
                                        item?.isPreSaleSubscribed
                                      }
                                      onDeleteLiving={() =>
                                        this.deleteResidencial(item?.id)
                                      }
                                      isHost={this?.props?.isHost}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {this.props?.hostLivings?.iopList?.length !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="listing-sub-header test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2">
                              <h4 className="main-title mb-0">IOP</h4>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="slider-wrapper">
                              <div className="sober-living-slider living d-flex flex-column">
                                {this.props?.hostLivings?.iopList?.map(
                                  (item, index) => (
                                    <IOPCenterCard
                                      item={item}
                                      pageName="Profile"
                                      showPromoted={false}
                                      ratingOutside={true}
                                      isRecommended={false}
                                      showActionItems={true}
                                      isSubscribe={item?.isSubscribed}
                                      subscriptionDetail={
                                        item?.subscriptionDetail
                                      }
                                      preSalePlanType={item?.preSalePlanType}
                                      isPreSaleSubscribed={
                                        item?.isPreSaleSubscribed
                                      }
                                      onDeleteLiving={() =>
                                        this.deleteLivingIop(item?.id)
                                      }
                                      isHost={this?.props?.isHost}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {this?.props?.hostLivings?.livingList?.length !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="listing-sub-header test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2">
                              <h4 className="main-title mb-0">Sober Living</h4>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="slider-wrapper">
                              <div className="sober-living-slider living d-flex flex-column">
                                {this.props?.hostLivings?.livingList?.map(
                                  (item, index) => (
                                    <MediaQuery maxWidth={767.98}>
                                      {(matches) => (
                                        <SoberLivingProfileCard
                                          item={item}
                                          pageName="Profile"
                                          showPromoted={false}
                                          ratingOutside={true}
                                          isRecommended={false}
                                          showActionItems={true}
                                          onDeleteLiving={() =>
                                            this.deleteLiving(item?.id)
                                          }
                                          isHost={this?.props?.isHost}
                                          onScheduleTour={() => {
                                            if (matches) {
                                              this.context.setStickyFooter(
                                                false
                                              );
                                            }
                                            this.setState({
                                              scheduleTour: true,
                                              livingId: item?.id,
                                              isMobileDevice: matches,
                                              toursAvailability: {
                                                isAvailableForTours:
                                                  item?.isAvailableForTours,
                                                isAvailableCurrently:
                                                  item?.isAvailableCurrently,
                                                availableForToursDates:
                                                  item?.availableForToursDates,
                                              },
                                            });
                                          }}
                                          key={item?.id}
                                        />
                                      )}
                                    </MediaQuery>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {this?.props?.unclaimList?.length !== 0 && (
                      <UnclaimedFacilities />
                    )}
                  </>
                ) : (
                  <div className="new-owner_wrapper">
                    <div className="new-owner">
                      <div className="new-owner_content">
                        <h2 className="mb-3">NO FACILITIES LISTED…YET!</h2>
                        <p className="mb-4 mb-lg-5">
                          Time to elevate your business visibility with Taste
                          Recovery
                        </p>
                        <div
                          className={
                            !this.state.isShowDropDown
                              ? "dropdown show"
                              : "dropdown"
                          }
                        >
                          <button
                            className="btn btn-secondary dropdown-toggle btn btn-primary radius-20"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => {
                              this.setState({
                                isShowDropDown: !this.state.isShowDropDown,
                              });
                            }}
                          >
                            Choose Facility Type
                          </button>
                          {this.state.isShowDropDown && (
                            <div
                              className="dropdown-menu show"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <ul>
                                <li className="mobile-title d-block d-md-none">
                                  Choose Facility Type
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="detoxfacility/add-detox"
                                  >
                                    Detox
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="residentialfacility/add-residential"
                                  >
                                    Residential
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="phpfacility/add-php"
                                  >
                                    PHP
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/iopfacility/add-iopcenter"
                                  >
                                    IOP
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="living/add-living"
                                  >
                                    Sober Living
                                  </Link>
                                </li>
                                <li
                                  className="mobile-action d-block d-md-none"
                                  onClick={() => {
                                    this.setState({
                                      dropdownclose: !this.state.dropdownclose,
                                    });
                                  }}
                                >
                                  <Link className="dropdown-item" to="#">
                                    Cancel
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="new-owner_image">
                        <img
                          src={accountImgDesktop}
                          className="img-fluid d-none d-md-block"
                          alt=""
                        />
                        <img
                          src={accountImgMobile}
                          className="img-fluid d-block d-md-none"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </>
        )}
      </div>
    );
  }
}

export default connect(
  ({ living, home, auth }) => ({
    stateList: home?.states,
    hostLivings: living?.hostLivings,
    isFetchinhostLivings: living?.fetchingHostLivings,
    user: auth?.user,
    isFetchingActivity: living?.isFetchingActivity,
    activity: living?.activity,
    me: auth?.me,
    isFetchingMe: auth?.isFetchingMe,
    isHost: auth?.isHost,
    unclaimList: living?.claimList?.claimList,
  }),
  (dispatch) => {
    return {
      ...bindActionCreators({ ...livingAction, ...authAction }, dispatch),
    };
  }
)(ProfileHost);
