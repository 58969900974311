import { livingActionType } from "../actions";

const initialLivingState = {
  fetchingLiving: false,
  properties: new Map(),
  addingReview: false,
  submitedReport: false,
  searchInfo: {},
  livings: [],
  addedAlert: false,
  addingLiving: false,
  hostLivings: {},
  fetchingHostLivings: false,
  selectedLiving: {},
  editingLiving: false,
  addingReviewReply: false,
  fetchingFavLivings: false,
  favLivings: [],
  favilityAll:[],
  bookmarkAdding: false,
  bookmarkRemoving: false,
  fetchingRecentlyViewed: false,
  recentlyViewed: [],

  fetchingRecentlyRecentflag: false,
  recentlyViewedData: [],

  fetchingMyReviews: false,
  myReviews: [],
  isContactingHome: false,
  isFetchingActivity: false,
  activity: {},
  alerts: [],
  launchAdDetails: {},
  isPromotingLiving: false,
  isDeletingLiving: false,
  fetchingAllFacility:false,
  isUpdatingAvailability: false,
  reviewsForAnonymousUser: [],
  isDeleted: false,
  planFetch: [],
  Soberliving: [],
  isDeletingIop: false,
  isDeletingResidencial: false,
  fetchingClaim: false,
  claimList: [],
  updateClaim: false,
  claimStatus: {},
};

export const livingReducer = (state = initialLivingState, action) => {
  switch (action.type) {
    case livingActionType.Living_Fetching:
      return { ...state, fetchingLiving: true };
    case livingActionType.Living_Success: {
      const { property, reviews } = action.payload;
      state.properties.set(property.id, {
        property,
        reviews,
      });
      return { ...state, fetchingLiving: false, isDeleted: false };
    }

    case livingActionType.Living_Failure: {
      return { ...state, fetchingLiving: false, isDeleted: false };
    }
    case livingActionType.Living_Deleted: {
      return { ...state, isDeleted: true };
    }

    case livingActionType.Living_Reviews_Success:
      return {
        ...state,
        fetchingLiving: false,
        reviewsForAnonymousUser: action.payload.reviews,
      };
    case livingActionType.Review_Adding:
      return { ...state, addingReview: true };
    case livingActionType.Review_Added:
      const { review } = action.payload;
      const item = state.properties.get(review.property);
      item.reviews = [...item.reviews, review];
      state.properties.set(item.property.id, item);
      return { ...state, addingReview: false };

    case livingActionType.Reply_Review_Adding:
      return { ...state, addingReviewReply: true };
    case livingActionType.Review_Reply_Added:
      const { reply, propertyId } = action.payload;
      const payload = state.properties.get(propertyId);
      payload.reviews = payload.reviews.map((review) => {
        if (review.id === reply.id) {
          review = reply;
        }
        return review;
      });
     
      state.properties.set(propertyId, payload);
      return { ...state, addingReviewReply: false };

    case livingActionType.Living_Reset:
      return { ...state, livings: [], fetchingLiving: true };
    case livingActionType.Living_Adding:
      return { ...state, addingLiving: true };
    case livingActionType.Living_Add:
      const { Soberliving } = action.payload;
      return { ...state, addingLiving: false, Soberliving };

    case livingActionType.Living_Search:
      const { livings } = action.payload;
      return { ...state, livings, fetchingLiving: false };
    case livingActionType.Living_Search_Failure:
      return { ...state, fetchingLiving: false };

    case livingActionType.Living_Search_Update:
      const properties = action.payload.livings;
      return {
        ...state,
        fetchingLiving: false,
        livings: [...state.livings, ...properties],
      };
    case livingActionType.Living_Clear_Search:
      return {
        ...state,
        livings: [],
        Soberliving: [],
      };

    case livingActionType.Set_Selected_Living:
      const livingId = action.payload;
      const living = state.hostLivings.filter((living) => {
        return living.id === livingId;
      });
      return { ...state, selectedLiving: living };

    case livingActionType.Editing_Living:
      return { ...state, editingLiving: true };
    case livingActionType.Edited_Living:
      return { ...state, editingLiving: false };

    case livingActionType.Deleting_Living:
      return { ...state, isDeletingLiving: true };
    case livingActionType.Deleted_Living:
      return { ...state, isDeletingLiving: false };

    //Adding For IOP delete

    case livingActionType.Deleting_Iop:
      return { ...state, isDeletingIop: true };
    case livingActionType.Deleted_Iop:
      return { ...state, isDeletingIop: false };

    // resendial Delete

    case livingActionType.Deleting_Residencial:
      return { ...state, isDeletingResidencial: true };
    case livingActionType.Deleted_Residencial:
      return { ...state, isDeletingResidencial: false };

    // case livingActionType.Editing_iopCenter:
    //   return { ...state, Editing_iopCenter: true };
    // case livingActionType.Edited_iopCenter:
    //   return { ...state, Editing_iopCenter: false };

    case livingActionType.Living_Search_Info:
      const { searchInfo } = action.payload;
      return { ...state, searchInfo };

    case livingActionType.Alert_Success:
      return { ...state, addedAlert: true };
    case livingActionType.Alert_Fetched:
      const { alerts } = action.payload;
      return { ...state, alerts };
    case livingActionType.Alert_Removed:
      const { alertId } = action.payload;
      const newAlerts = state.alerts.filter((alert) => alert.id !== alertId);
      return { ...state, alerts: newAlerts };

    case livingActionType.Report_Success:
      return { ...state, submitedReport: false };
    case livingActionType.Admin_Living_Fetching:
      return { ...state, fetchingHostLivings: true };

    case livingActionType.Admin_Living_Success:
      return {
        ...state,
        fetchingHostLivings: false,
        hostLivings: {
          livingList: action.payload.livings.livingList,
          iopList: action.payload.livings.iopList,
          residential: action.payload.livings.residential,
          detox: action.payload.livings.detoxList,
          php: action.payload.livings.phpList,
        },
      };

    case livingActionType.Fav_Living_Fetching:
      return { ...state, fetchingFavLivings: true };
    case livingActionType.Fav_Living_Fetched:
      return {
        ...state,
        fetchingFavLivings: false,
        favLivings: action.payload.livings,
      };

// all facility

case livingActionType.Fetching_Recently_Viewed:
  return { ...state, fetchingAllFacility: true };
case livingActionType.Fetched_All_Facility:
  return {
    ...state,
    fetchingAllFacility: false,
    favilityAll: action.payload.livings,
  };




    case livingActionType.Bookmark_Adding:
      return { ...state, bookmarkAdding: true };
    case livingActionType.Bookmark_Added:
      const property_living_add = state.properties.get(action.payload.livingId);
      property_living_add.property.isMyFavorite = true;
      state.properties.set(action.payload.livingId, property_living_add);
      return { ...state, bookmarkAdding: false };

    case livingActionType.Bookmark_Removing:
      return { ...state, bookmarkRemoving: true };
    case livingActionType.Bookmark_Removed:
      const property_living_remove = state.properties.get(
        action.payload.livingId
      );
      property_living_remove.property.isMyFavorite = false;
      state.properties.set(action.payload.livingId, property_living_remove);
      return { ...state, bookmarkRemoving: false };

    case livingActionType.Fetching_Recently_Viewed:
      return { ...state, fetchingRecentlyViewed: true };
    case livingActionType.Fetched_Recently_Viewed:
      return {
        ...state,
        fetchingRecentlyViewed: false,
        recentlyViewed: action.payload.livings,
      };


// for facility recent view 
      case livingActionType.Fetching_Recently_Recent:
        return { ...state, fetchingRecentlyRecentflag: true };
      case livingActionType.Fetched_Recently_Recent:
        return {
          ...state,
          fetchingRecentlyRecentflag: false,
          recentlyViewedData: action.payload.livings,
        };

    
     

    case livingActionType.Fetching_My_Reviews:
      return { ...state, fetchingMyReviews: true };
    case livingActionType.Fetched_My_Reveiws:
      return {
        ...state,
        fetchingMyReviews: false,
        myReviews: action.payload.reviews,
      };

    case livingActionType.Contacting_Home:
      return { ...state, isContactingHome: true };
    case livingActionType.Contacted_Home:
      return { ...state, isContactingHome: false };

    case livingActionType.Fetching_Activity:
      return { ...state, isFetchingActivity: true };
    case livingActionType.Fetched_Activity:
      return {
        ...state,
        isFetchingActivity: false,
        activity: action.payload.activity,
      };

    case livingActionType.Launch_Ad_Data:
      return { ...state, launchAdDetails: action.payload };

    case livingActionType.Promoting_Living:
      return { ...state, isPromotingLiving: true };
    case livingActionType.Promoted_Living:
      return { ...state, isPromotingLiving: false };
    case livingActionType.Turning_Off_Ad:
      return { ...state, isTurningOffAd: true };
    case livingActionType.Turned_Off_Ad:
      return { ...state, isTurningOffAd: false };
    case livingActionType.Updating_Availability:
      return { ...state, isUpdatingAvailability: true };
    case livingActionType.SubscriptionPlan_Fetch:
      return {
        ...state,
        planFetch: action.payload.subscription_Plan,
      };
    case livingActionType.Updated_Availability:
      let index;
      let temp = state.hostLivings.find((l, i) => {
        if (l.id === action.payload.livingId) {
          index = i;
          return true;
        }
      });
      temp = { ...temp, ...action.payload.data };
      const tempHostLivings = [...state.hostLivings];
      tempHostLivings.splice(index, 1, temp);
      return {
        ...state,
        isUpdatingAvailability: false,
        hostLivings: tempHostLivings,
      };

    case livingActionType.Unclaimed_Facility:
  
      return {
        ...state,
        fetchingClaim: false,
        claimList: action.payload,
      };
    case livingActionType.Claimed_Facility:
      return {
        ...state,
        updateClaim: false,
        claimStatus: action.payload,
      };

    default:
      return state;
  }
};
