import { generateQueryParams, httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const surveyActionType = {
    Fetching_surveyQuestion: "[Fetching_surveyQuestion] Action",
    Fetched_surveyQuestion: "[Fetched_surveyQuestion] Action",
    Submitting_SurveyResponse: "[Submitting_SurveyResponse] Action",
    SurveyResponse_Submitted: "[SurveyResponse_Submitted] Action"
}
const onFetchsureveyQuestion = (id) => {

    return (dispatch) => {
        dispatch({ type: surveyActionType.Fetching_surveyQuestion });
        return httpClient
            .get(`${SERVER_APIS.Surveyquestions}/${id}`)
            .then((response) => {

                dispatch({
                    type: surveyActionType.Fetched_surveyQuestion,
                    payload:  response.data ,
                });
                return Promise.resolve();
            })
            .catch(() => Promise.reject());
    };
}
const onSubmitSurveyResponse = (payload) => {
    return (dispatch) => {
        dispatch({ type: surveyActionType.Submitting_SurveyResponse });
        return httpClient
            .post(`${SERVER_APIS.Submitsurvey}`, payload) // Use the correct endpoint for submitting survey responses
            .then((response) => {
                dispatch({ type: surveyActionType.SurveyResponse_Submitted ,  payload:  response.data ,});
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error submitting survey response:", error);
                return Promise.reject();
            });
    };
};

export const surveyquestionActions = {
    fetchsurveyquestion: onFetchsureveyQuestion,
    submitSurveyResponse: onSubmitSurveyResponse
}