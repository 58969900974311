export const formatedText = (text) => {
  const specialCases = {
    substanceUseDisorder: "Substance Use Disorder",
    mentalHealth: "Mental Health",
    dualDiagnosis: "Dual Diagnosis",
    relapsePrevention: "Relapse Prevention",
    telemedicineTelehealthTherapy: "Telemedicine/Telehealth Therapy",
    mentalHealthCounsellingForCoOccurringDisorders: "Mental Health Counseling For Co-Occurring Disorders",
    groupTherapy: "Group Therapy",
    familyTherapy: "Family Therapy",
    addictionRecoveryEducation: "Addiction Recovery Education",
    adolescents: "Adolescent Programs",
    youngAdults: "Young Adults",
    veterans: "Veterans",
    twentyFourSevenCare: "24/7 Care",
    selfHelpGroups: "Self Help Groups",
    recoveryCoach: "Recovery Coach",
    mentoringPeerSupport: "Mentoring/Peer Support",
    assistanceWithObtainingSocialServices: "Assistance With Obtaining Social Services",
    aftercareContinuingCare: "Aftercare/Continuing Care",
    dischargePlanning: "Discharge Planning",
    naloxoneAndOverdoseEducation: "Naloxone And Overdose Education",
    outcomeFollowUpAfterDischarge: "Outcome Follow-Up After Discharge",
    pregnantPostpartumWomen: "Pregnant/Postpartum Women",
    seniorsOrOlderAdults: "Seniors Or Older Adults",
    clientWithHIVOrAIDS: "Clients With HIV Or AIDS",
    clientsWithTrance: "Clients With Trauma",
    clientsWithSuicidalIdeations: "Clients With Suicidal Ideations",
    
    adhd: "ADHD",
    alcohol: "Alcohol",
    anger: "Anger",
    anxiety: "Anxiety",
    benzodiazepines: "Benzodiazepines",
    bipolar: "Bipolar",
    burnout: "Burnout",
    chronicPainManagement: "Chronic Pain Management",
    chronicRelapse: "Chronic Relapse",
    coOccurringDisorders: "Co-Occurring Disorders",
    cocaine: "Cocaine",
    codependency: "Codependency",
    depression: "Depression",
    drugAddiction: "Drug Addiction",
    eatingDisorders: "Eating Disorders",
    ecstasy: "Ecstasy",
    gambling: "Gambling",
    gaming: "Gaming",
    griefAndLoss: "Grief And Loss",
    heroin: "Heroin",
    internetAddiction: "Internet Addiction",
    marijuana: "Marijuana",
    methamphetamine: "Methamphetamine",
    nicotine: "Nicotine",
    obsessiveCompulsiveDisorder: "Obsessive Compulsive Disorder",
    opioids: "Opioids",
    personalityDisorder: "Personality Disorder",
    pornography: "Pornography",
    postTraumaticStressDisorder: "Post Traumatic Stress Disorder",
    prescriptionDrugs: "Prescription Drugs",
    psychedelics: "Psychedelics",
    schizophrenia: "Schizophrenia",
    sexHarm: "Sex Harm",
    sexAddiction: "Sex Addiction",
    shopping: "Shopping",
    smokingCessation: "Smoking Cessation",
    stress: "Stress",
    suicidality: "Suicidality",
    syntheticDrugs: "Synthetic Drugs",
    trauma: "Trauma",
    weightLoss: "Weight Loss",
    
    acamprosateCampral: "Acamprosate (Campral)",
    disulfiram: "Disulfiram",
    buprenorphineWithNaloxone: "Buprenorphine With Naloxone",
    buprenorphineWithoutNaloxone: "Buprenorphine Without Naloxone",
    buprenorphineExtendedReleaseInjection: "Buprenorphine (Extended-Release, Injection)",
    naltrexoneOral: "Naltrexone (Oral)",
    naltrexoneExtendedReleaseInjection: "Naltrexone (Extended-Release, Injection)",
    clonidine: "Clonidine",
    medicationForMentalDisorders: "Medication For Mental Disorders",
    nicotineReplacement: "Nicotine Replacement",
    nonNicotineSmokingCessation: "Non-Nicotine Smoking/Tobacco Cessation",
    
    angerManagement: "Anger Management",
    briefIntervention: "Brief Intervention",
    cognitiveBehavioralTherapy: "Cognitive Behavioral Therapy",
    contingencyManagementMotivationalIncentives: "Contingency Management/Motivational Incentives",
    motivationalInterviewing: "Motivational Interviewing",
    substanceUseDisorderCounselling: "Substance Use Disorder Counseling",
    traumaRelatedCounselling: "Trauma Related Counseling",
    twelveStepFacilitation: "12 Step Facilitation",
    faithBasedProgram: "Faith Based Program",
    substanceUseDisorderEducation: "Substance Use Disorder Education",
    individualCounselling: "Individual Counseling",
    groupCounselling: "Group Counseling",
    familyCounselling: "Family Counseling",
    mentalCouplesCounselling: "Mental/Couples Counseling",
    aaTherapy: "AA Therapy",
    reiki: "Reiki",
    musicTherapy: "Music Therapy",
    copingMechanism: "Coping Mechanism",
    griefManagement: "Grief Management",
    yoga: "Yoga",
    breathworks: "Breathworks",
    meditation: "Meditation",
    experientialTherapy: "Experiential Therapy",
    equineTherapy: "Equine Therapy",
    gymDailyFitnessActivities: "Gym/Daily Fitness Activities",
    emdr: "EMDR",
    crossMappingMethod: "Cross Mapping Method",
    brainSpotting: "Brain Spotting",
    somaticCoaching: "Somatic Coaching",
    
    comprehensiveSubstanceUseAssessment: "Comprehensive Substance Use Assessment",
    comprehensiveMentalHealthAssessment: "Comprehensive Mental Health Assessment",
    outreachToPersonsInTheCommunity: "Outreach To Persons In The Community",
    completeMedicalHistoryPhysicalExam: "Complete Medical History Physical Exam",
    screeningForTobaccoUse: "Screening For Tobacco Use",
    screeningForSubstanceUse: "Screening For Substance Use",
    screeningForMentalDisorders: "Screening For Mental Disorders",
    
    breathalyzerOrBloodAlcoholTesting: "Breathalyzer Or Blood Alcohol Testing",
    HIVTesting: "HIV Testing",
    drugOrAlcoholUrineScreening: "Drug Or Alcohol Urine Screening",
    STDTesting: "STD Testing",
    TBTesting: "TB Testing",
    testingForHepatitisB: "Testing For Hepatitis B (HBV)",
    testingForHepatitisC: "Testing For Hepatitis C (HCV)",
    
    smokingAllowed: "Smoking Allowed",
    noSmoking: "No Smoking",
    smokingPermittedInDesignatedAreas: "Smoking Permitted In Designated Areas",
    vapingAllowed: "Vaping Allowed",
    noVaping: "No Vaping",
    vapingPermittedInDesignatedAreas: "Vaping Permitted In Designated Areas"
  };

  // Check if the key is in the special cases
  if (specialCases[text]) {
    return specialCases[text];
  }

  // Convert camelCase to words with a space before capital letters
  let formattedText = text.replace(/([A-Z][a-z]+)/g, " $1").trim();

  // Capitalize the first letter of the resulting string
  formattedText =
    formattedText.charAt(0).toUpperCase() + formattedText.slice(1);

  // Convert the second word to lowercase
  const words = formattedText.split(" ");
  if (words.length > 1) {
    words[1] = words[1].toLowerCase();
  }

  // Join the words back together
  formattedText = words.join(" ");

  return formattedText;
};
