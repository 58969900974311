import React from "react";
import Slider from "react-slick";

import filterIcon from "../../assets/images/filter-new.svg";
import SliderNextArrow from "../../components/slider/sliderNext.component";
import SliderPrevArrow from "../../components/slider/sliderPrevArrow.component";
import CondTreatedFilter from "./conditionTreated.component";

const FilterSection = ({ FilteredOptions, selectedItems, handleConditionTreated, isMobile, setOpenFilterModal }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    autoplay: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow length={FilteredOptions.length} />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1269,
        settings: {
          slidesToShow: 7,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 6,
          variableWidth: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.9,
          variableWidth: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 4,
          variableWidth: true,
        },
      },
      {
        breakpoint: 475,
        settings: {
          slidesToShow: 3.3,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <section className="facility-list-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12 filter-col">
            <div className="filter-wrapper d-flex align-items-center">
              {isMobile() ? (
                <div className="tab-slider">
                  {FilteredOptions.map((ele, index) => (
                    <CondTreatedFilter
                      key={index}
                      ele={ele}
                      handleClick={() => handleConditionTreated(ele.key, ele.value)}
                      selectedItems={selectedItems}
                    />
                  ))}
                </div>
              ) : (
                <Slider {...settings} className="tab-slider">
                  {FilteredOptions.map((ele, index) => (
                    <CondTreatedFilter
                      key={index}
                      ele={ele}
                      handleClick={() => handleConditionTreated(ele.key, ele.value)}
                      selectedItems={selectedItems}
                    />
                  ))}
                </Slider>
              )}
              <button
                type="submit"
                className="btn btn-filter d-none d-md-flex align-items-center justify-content-center"
                onClick={() => setOpenFilterModal(true)}
              >
                <img src={filterIcon} className="img-fluid" alt="" />
                Filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FilterSection;
