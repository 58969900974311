
export const detoxFilter = {
    specialization:{
        relapsePrevention: false,
        telemedicineTelehealthTherapy: false,
        mentalHealthCounsellingForCoOccurringDisorders: false,
        groupTherapy: false,
        familyTherapy: false,
        addictionRecoveryEducation: false,
        mentalCouplesCounselling: false,
        adolescents: false,
        youngAdults: false,
        veterans: false,
        twentyFourSevenCare: false,
        selfHelpGroups: false,
        recoveryCoach: false,
        mentoringPeerSupport: false,
        assistanceWithObtainingSocialServices: false,
        aftercareContinuingCare: false,
        dischargePlanning: false,
        naloxoneAndOverdoseEducation: false,
        outcomeFollowUpAfterDischarge: false,
        pregnantPostpartumWomen: false,
        seniorsOrOlderAdults: false,
        clientWithHIVOrAIDS: false,
        clientsWithTrance: false,
        clientsWithSuicidalIdeations: false,
    },

    conditionTreat:{
        adhd: false,
        alcohol: false,
        anger: false,
        anxiety: false,
        benzodiazepines: false,
        bipolar: false,
        burnout: false,
        chronicPainManagement: false,
        chronicRelapse: false,
        coOccurringDisorders: false,
        cocaine: false,
        codependency: false,
        depression: false,
        drugAddiction: false,
        eatingDisorders: false,
        ecstasy: false,
        gambling: false,
        gaming: false,
        griefAndLoss: false,
        heroin: false,
        internetAddiction: false,
        marijuana: false,
        methamphetamine: false,
        nicotine: false,
        obsessiveCompulsiveDisorder: false,
        opioids: false,
        personalityDisorder: false,
        pornography: false,
        postTraumaticStressDisorder: false,
        prescriptionDrugs: false,
        psychedelics: false,
        schizophrenia: false,
        sexHarm: false,
        sexAddiction: false,
        shopping: false,
        smokingCessation: false,
        stress: false,
        suicidality: false,
        syntheticDrugs: false,
        trauma: false,
        weightLoss: false
    },

    medicationTherapy :{
        buprenorphineWithNaloxone: false,
        buprenorphineWithoutNaloxone: false,
        buprenorphineExtendedReleaseInjection: false,
        naltrexoneOral: false,
        naltrexoneExtendedReleaseInjection: false,
        medicationForMentalDisorders: false,
    },

    treatmentApproaches : {
        angerManagement: false,
        briefIntervention: false,
        cognitiveBehavioralTherapy: false,
        contingencyManagementMotivationalIncentives: false,
        motivationalInterviewing: false,
        relapsePrevention: false,
        substanceUseDisorderCounselling: false,
        traumaRelatedCounselling: false,
        twelveStepFacilitation: false,
        faithBasedProgram: false,
        substanceUseDisorderEducation: false,
        individualCounselling: false,
        groupCounselling: false,
        familyCounselling: false,
        mentalCouplesCounselling: false,
        aaTherapy: false,
        reiki: false,
        musicTherapy: false,
        copingMechanism: false,
        griefManagement: false,
        yoga: false,
        breathworks: false,
        meditation: false,
        experientialTherapy: false,
        equineTherapy: false,
        gymDailyFitnessActivities: false,
        emdr: false,
        crossMappingMethod: false,
        brainSpotting: false,
        somaticCoaching: false,
    },

    preTreatment :{
        comprehensiveSubstanceUseAssessment: false,
        comprehensiveHealthAssessment: false,
        outreachMedicalHistoryPhysicalExam: false,
        screeningForTobaccoUse: false,
        screeningForSubstanceUse: false,
        screeningForMentalDisorders: false,
    },

    testing : {
        breathalyzerOrBloodAlcoholTesting: false,
        drugAndOralFluidTesting: false,
        drugOrAlcoholUrineScreening: false,
        TBTesting: false,
    },
    smokingPolicy:{
        smokingAllowed: false,
        noSmoking: false,
        smokingPermittedInDesignatedAreas: false,
        vapingAllowed: false,
        noVaping: false,
        vapingPermittedInDesignatedAreas: false,
    }

}

export const phpFilter={
    specialization: {
        substanceUseDisorder: false,
        mentalHealth: false,
        dualDiagnosis: false,
        adolescents: false,
        veterans: false,
        seniorsOrOlderAdults: false,
        youngAdults: false,
        telemedicineTelehealthTherapy: false,
        clientWithHIVOrAIDS: false,
        pregnantPostpartumWomen: false,
    },
    conditionTreat:{
        adhd: false,
        alcohol: false,
        anger: false,
        anxiety: false,
        benzodiazepines: false,
        bipolar: false,
        burnout: false,
        chronicPainManagement: false,
        chronicRelapse: false,
        coOccurringDisorders: false,
        cocaine: false,
        codependency: false,
        depression: false,
        drugAddiction: false,
        eatingDisorders: false,
        ecstasy: false,
        gambling: false,
        gaming: false,
        griefAndLoss: false,
        heroin: false,
        internetAddiction: false,
        marijuana: false,
        methamphetamine: false,
        nicotine: false,
        obsessiveCompulsiveDisorder: false,
        opioids: false,
        personalityDisorder: false,
        pornography: false,
        postTraumaticStressDisorder: false,
        prescriptionDrugs: false,
        psychedelics: false,
        schizophrenia: false,
        sexHarm: false,
        sexAddiction: false,
        shopping: false,
        smokingCessation: false,
        stress: false,
        suicidality: false,
        syntheticDrugs: false,
        trauma: false,
        weightLoss: false
    },
    medicationTherapy:{
        acamprosateCampral: false,
        disulfiram: false,
        buprenorphineWithNaloxone: false,
        buprenorphineWithoutNaloxone: false,
        buprenorphineExtendedReleaseInjection: false,
        naltrexoneOral: false,
        naltrexoneExtendedReleaseInjection: false,
        clonidine: false,
        medicationForMentalDisorders: false,
        nicotineReplacement: false,
        nonNicotineSmokingCessation: false,
    },
    treatmentApproaches : {
        angerManagement: false,
        briefIntervention: false,
        cognitiveBehavioralTherapy: false,
        contingencyManagementMotivationalIncentives: false,
        motivationalInterviewing: false,
        relapsePrevention: false,
        substanceUseDisorderCounselling: false,
        traumaRelatedCounselling: false,
        twelveStepFacilitation: false,
        faithBasedProgram: false,
        substanceUseDisorderEducation: false,
        individualCounselling: false,
        groupCounselling: false,
        familyCounselling: false,
        mentalCouplesCounselling: false,
        aaTherapy: false,
        reiki: false,
        musicTherapy: false,
        copingMechanism: false,
        griefManagement: false,
        yoga: false,
        breathworks: false,
        meditation: false,
        experientialTherapy: false,
        equineTherapy: false,
        gymDailyFitnessActivities: false,
        emdr: false,
        crossMappingMethod: false,
        brainSpotting: false,
        somaticCoaching: false,
    },
    preTreatment:{
        comprehensiveSubstanceUseAssessment: false,
        comprehensiveMentalHealthAssessment: false,
        outreachToPersonsInTheCommunity: false,
        completeMedicalHistoryPhysicalExam: false,
        screeningForTobaccoUse: false,
        screeningForSubstanceUse: false,
        screeningForMentalDisorders: false,
    },
    testing :{
        breathalyzerOrBloodAlcoholTesting: false,
        HIVTesting: false,
        drugOrAlcoholUrineScreening: false,
        STDTesting: false,
        TBTesting: false,
        testingForHepatitisB: false,
        testingForHepatitisC: false,
    },
    smokingPolicy:{
        smokingAllowed: false,
        noSmoking: false,
        smokingPermittedInDesignatedAreas: false,
        vapingAllowed: false,
        noVaping: false,
        vapingPermittedInDesignatedAreas: false,
    },
}


export const residentialFilter={
    specialization: {
        substanceUseDisorder: false,
        mentalHealth: false,
        dualDiagnosis: false,
        adolescents: false,
        veterans: false,
        seniorsOrOlderAdults: false,
        youngAdults: false,
        telemedicineTelehealthTherapy: false,
        clientWithHIVOrAIDS: false,
        pregnantPostpartumWomen: false,
    },
    conditionTreat:{
        adhd: false,
        alcohol: false,
        anger: false,
        anxiety: false,
        benzodiazepines: false,
        bipolar: false,
        burnout: false,
        chronicPainManagement: false,
        chronicRelapse: false,
        coOccurringDisorders: false,
        cocaine: false,
        codependency: false,
        depression: false,
        drugAddiction: false,
        eatingDisorders: false,
        ecstasy: false,
        gambling: false,
        gaming: false,
        griefAndLoss: false,
        heroin: false,
        internetAddiction: false,
        marijuana: false,
        methamphetamine: false,
        nicotine: false,
        obsessiveCompulsiveDisorder: false,
        opioids: false,
        personalityDisorder: false,
        pornography: false,
        postTraumaticStressDisorder: false,
        prescriptionDrugs: false,
        psychedelics: false,
        schizophrenia: false,
        sexHarm: false,
        sexAddiction: false,
        shopping: false,
        smokingCessation: false,
        stress: false,
        suicidality: false,
        syntheticDrugs: false,
        trauma: false,
        weightLoss: false
    },
    medicationTherapy:{
        acamprosateCampral: false,
        disulfiram: false,
        buprenorphineWithNaloxone: false,
        buprenorphineWithoutNaloxone: false,
        buprenorphineExtendedReleaseInjection: false,
        naltrexoneOral: false,
        naltrexoneExtendedReleaseInjection: false,
        clonidine: false,
        medicationForMentalDisorders: false,
        nicotineReplacement: false,
        nonNicotineSmokingCessation: false,
    },
    treatmentApproaches : {
        angerManagement: false,
        briefIntervention: false,
        cognitiveBehavioralTherapy: false,
        contingencyManagementMotivationalIncentives: false,
        motivationalInterviewing: false,
        relapsePrevention: false,
        substanceUseDisorderCounselling: false,
        traumaRelatedCounselling: false,
        twelveStepFacilitation: false,
        faithBasedProgram: false,
        substanceUseDisorderEducation: false,
        individualCounselling: false,
        groupCounselling: false,
        familyCounselling: false,
        mentalCouplesCounselling: false,
        aaTherapy: false,
        reiki: false,
        musicTherapy: false,
        copingMechanism: false,
        griefManagement: false,
        yoga: false,
        breathworks: false,
        meditation: false,
        experientialTherapy: false,
        equineTherapy: false,
        gymDailyFitnessActivities: false,
        emdr: false,
        crossMappingMethod: false,
        brainSpotting: false,
        somaticCoaching: false,
    },
    preTreatment: {
        comprehensiveSubstanceUseAssessment: false,
        comprehensiveMentalHealthAssessment: false,
        outreachToPersonsInTheCommunity: false,
        completeMedicalHistoryPhysicalExam: false,
        screeningForTobaccoUse: false,
        screeningForSubstanceUse: false,
        screeningForMentalDisorders: false,
    },
    testing :{
        breathalyzerOrBloodAlcoholTesting: false,
        HIVTesting: false,
        drugOrAlcoholUrineScreening: false,
        STDTesting: false,
        TBTesting: false,
        testingForHepatitisB: false,
        testingForHepatitisC: false,
    },
    smokingPolicy:{
        smokingAllowed: false,
        noSmoking: false,
        smokingPermittedInDesignatedAreas: false,
        vapingAllowed: false,
        noVaping: false,
        vapingPermittedInDesignatedAreas: false,
    },

    verifiedBadge:{
        verifiedOnly:false
    }
}

export const iopFilter={
    specialization: {
        substanceUseDisorder: false,
        mentalHealth: false,
        dualDiagnosis: false,
        adolescents: false,
        veterans: false,
        seniorsOrOlderAdults: false,
        youngAdults: false,
        telemedicineTelehealthTherapy: false,
        clientWithHIVOrAIDS: false,
        pregnantPostpartumWomen: false,
    },
    conditionTreat:{
        adhd: false,
        alcohol: false,
        anger: false,
        anxiety: false,
        benzodiazepines: false,
        bipolar: false,
        burnout: false,
        chronicPainManagement: false,
        chronicRelapse: false,
        coOccurringDisorders: false,
        cocaine: false,
        codependency: false,
        depression: false,
        drugAddiction: false,
        eatingDisorders: false,
        ecstasy: false,
        gambling: false,
        gaming: false,
        griefAndLoss: false,
        heroin: false,
        internetAddiction: false,
        marijuana: false,
        methamphetamine: false,
        nicotine: false,
        obsessiveCompulsiveDisorder: false,
        opioids: false,
        personalityDisorder: false,
        pornography: false,
        postTraumaticStressDisorder: false,
        prescriptionDrugs: false,
        psychedelics: false,
        schizophrenia: false,
        sexHarm: false,
        sexAddiction: false,
        shopping: false,
        smokingCessation: false,
        stress: false,
        suicidality: false,
        syntheticDrugs: false,
        trauma: false,
        weightLoss: false
    },
    medicationTherapy:{
        acamprosateCampral: false,
        disulfiram: false,
        buprenorphineWithNaloxone: false,
        buprenorphineWithoutNaloxone: false,
        buprenorphineExtendedReleaseInjection: false,
        naltrexoneOral: false,
        naltrexoneExtendedReleaseInjection: false,
        clonidine: false,
        medicationForMentalDisorders: false,
        nicotineReplacement: false,
        nonNicotineSmokingCessation: false,
    },
    treatmentApproaches : {
        angerManagement: false,
    briefIntervention: false,
    cognitiveBehavioralTherapy: false,
    contingencyManagementMotivationalIncentives: false,
    motivationalInterviewing: false,
    relapsePrevention: false,
    substanceUseDisorderCounselling: false,
    traumaRelatedCounselling: false,
    twelveStepFacilitation: false,
    faithBasedProgram: false,
    substanceUseDisorderEducation: false,
    individualCounselling: false,
    groupCounselling: false,
    familyCounselling: false,
    mentalCouplesCounselling: false,
    aaTherapy: false,
    reiki: false,
    musicTherapy: false,
    copingMechanism: false,
    griefManagement: false,
    yoga: false,
    breathworks: false,
    meditation: false,
    experientialTherapy: false,
    equineTherapy: false,
    gymDailyFitnessActivities: false,
    emdr: false,
    crossMappingMethod: false,
    brainSpotting: false,
    somaticCoaching: false,
    },
    preTreatment: {
        comprehensiveSubstanceUseAssessment: false,
        comprehensiveMentalHealthAssessment: false,
        outreachToPersonsInTheCommunity: false,
        completeMedicalHistoryPhysicalExam: false,
        screeningForTobaccoUse: false,
        screeningForSubstanceUse: false,
        screeningForMentalDisorders: false,
    },
    testing :{
        breathalyzerOrBloodAlcoholTesting: false,
        HIVTesting: false,
        drugOrAlcoholUrineScreening: false,
        STDTesting: false,
        TBTesting: false,
        testingForHepatitisB: false,
        testingForHepatitisC: false,
    },
    smokingPolicy:{
        smokingAllowed: false,
        noSmoking: false,
        smokingPermittedInDesignatedAreas: false,
        vapingAllowed: false,
        noVaping: false,
        vapingPermittedInDesignatedAreas: false,
    },
}