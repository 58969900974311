import { generateQueryParams, httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const livingActionType = {
  Living_Fetching: "[Fetching_Living] Action",
  Living_Success: "[Living_Fetched] Action",
  Living_Failure: "[Living_Failure] Action",
  Living_Deleted: "[Living_Deleted] Action",
  Living_Reviews_Success: "[Living_Reviews_Fetched] Action",
  Living_Reset: "[Living_Reset] Action",
  Living_Search: "[Living_Search] Action",
  Living_Search_Update: "[Living_Search_Update] Action",
  Living_Search_Info: "[Living_Search_Info] Action",
  Report_Success: "[Report_Success] Action",
  Alert_Success: "[Alert_Success] Action",
  Alert_Fetched: "[Alert_Fetched] Action",
  Alert_Removed: "[Alert_Removed] Action",
  Review_Added: "[Add_Review] Action",
  Review_Fetched: "[Review_Fetched] Action",
  Review_Adding: "[Adding_Review] Action",
  Living_Adding: "[Adding_Living] Action",
  Living_Add: "[Add_Living] Action",
  Admin_Living_Fetching: "[Admin_Living_Adding] Action",
  Admin_Living_Success: "[Admin_Living_Success] Action",
  Set_Selected_Living: "[Set_Selected_Living] Action",
  Editing_Living: "[Editing_Living] Action",
  Edited_Living: "[Edited_Living] Action",
  Reply_Review_Adding: "[Reply_Review_Adding] Action",
  Review_Reply_Added: "[Review_Reply_Added] Action",
  Fav_Living_Fetching: "[Fav_Living_Fetching] Action",
  Fav_Living_Fetched: "[Fav_Living_Fetched] Action",
  Bookmark_Adding: "[Bookmark_Adding] Action",
  Bookmark_Added: "[Bookmark_Added] Action",
  Bookmark_Removing: "[Bookmark_Removing] Action",
  Bookmark_Removed: "[Bookmark_Removed] Action",
  Fetching_Recently_Viewed: "[Fetching_Recently_Viewed] Action",
  Fetched_Recently_Viewed: "[Fetched_Recently_Viewed] Action",
  Fetching_Recently_Recent: "[Fetching_Recently_Recent] Action",
  Fetched_Recently_Recent: "[Fetched_Recently_Recent] Action",


  Fetched_All_Facility: "[Fetched_All_Facility] Action",
  Fetching_My_Reviews: "[Fetching_My_Reveiws] Action",
  Fetched_My_Reveiws: "[Fetched_My_Reviews] Action",
  Contacting_Home: "[Contacting_Home] Action",
  Contacted_Home: "[Contacted_Home] Action",
  Fetching_Activity: "[Fetching_Activity] Action",
  Fetched_Activity: "[Fetched_Activity] Action",
  Living_Clear_Search: "[Living_Clear_Search] Action",
  Launch_Ad_Data: "[Save_Launch_Ad_Data] Action",
  Promoting_Living: "[Promoting_Living] Action",
  Promoted_Living: "[Promoted_Living] Action",
  Deleting_Living: "[Deleting_Living] Action",
  Deleted_Living: "[Deleted_Living] Action",
  Updating_Availability: "[Updating_Availability] Action",
  Updated_Availability: "[Updated_Availability] Action",
  Turned_Off_Ad: "[Turned_Off_Ad] Action",
  Turning_Off_Ad: "[Turning_Off_Ad] Action",
  Living_Search_Failure: "[Living_Search_Failure] Action",
  Deleting_Iop: "[Deleting_Iop] Action",
  Deleted_Iop: "[Deleted_Iop] Action",
  Deleting_Residencial: "[Deleting_Residencial] Action",
  Deleted_Residencial: "[Deleted_Residencial] Action",
  Unclaimed_Facility: "[Unclaimed_Facility] Action",
  Claimed_Facility: "[Claimed_Facility] Action",
};

const onFetchingLivingById = (id) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Living_Fetching });
    return Promise.all([
      httpClient.get(`${SERVER_APIS.Living_URL}/${id}`),
      httpClient.get(`${SERVER_APIS.Review_By_Living_URL}/${id}`),
    ])
      .then((response) => {
        dispatch({
          type: livingActionType.Living_Success,
          payload: { property: response[0].data, reviews: response[1].data },
        });
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: livingActionType.Living_Failure,
        });

        return Promise.reject(err);
      });
  };
};

const onFetchingLivingByIdWithoutLogin = (id) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Living_Fetching });
    return Promise.all([
      httpClient.get(`${SERVER_APIS.Living_URL}/${id}/home`),
      httpClient.get(`${SERVER_APIS.Review_By_Living_URL}/${id}`),
    ])
      .then((response) => {
        dispatch({
          type: livingActionType.Living_Success,
          payload: { property: response[0].data, reviews: response[1].data },
        });
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch({
          type: livingActionType.Living_Failure,
        });

        return Promise.reject(err);
      });
  };
};

const onFetchingLivingReviewsById = (id) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Living_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Review_By_Living_URL}/${id}`)
      .then((response) => {
        dispatch({
          type: livingActionType.Living_Reviews_Success,
          payload: { propertyId: id, reviews: response.data },
        });
        return Promise.resolve(response);
      })
      .catch(() => Promise.reject());
  };
};

const onAddReview = (review) => {

  return (dispatch) => {
    dispatch({ type: livingActionType.Review_Adding });
    return httpClient
      .post(SERVER_APIS.Review_URL, review)
      .then(({ data }) => {
        dispatch({
          type: livingActionType.Review_Added,
          payload: { review: data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onAddReviewReply = (review, id, propertyId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Reply_Review_Adding });
    return httpClient
      .post(`${SERVER_APIS.Review_URL}/${id}/reply`, review)
      .then(({ data }) => {
        dispatch({
          type: livingActionType.Review_Reply_Added,
          payload: { reply: data, propertyId },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};



const searchLiving = (params, isNextPage) => {
  return (dispatch) => {
    dispatch({
      type: isNextPage
        ? livingActionType.Living_Fetching
        : livingActionType.Living_Reset,
    });
    return httpClient
      .post(`${SERVER_APIS.Living_Search_URL}`,params)
      .then(({ data }) => {
        dispatch({
          type: isNextPage
            ? livingActionType.Living_Search_Update
            : livingActionType.Living_Search,
          payload: { livings: data },
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({ type: livingActionType.Living_Search_Failure });
        Promise.reject();
      });
  };
};

const searchLivingMap = (params, isNextPage) => {
  return (dispatch) => {
    dispatch({
      type: isNextPage
        ? livingActionType.Living_Fetching
        : livingActionType.Living_Reset,
    });

    return httpClient
      .post(`${SERVER_APIS.Living_Search_URL}`, params)
      .then(({ data }) => {
        dispatch({
          type: isNextPage
            ? livingActionType.Living_Search_Update
            : livingActionType.Living_Search,
          payload: { livings: data },
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({ type: livingActionType.Living_Search_Failure });
        Promise.reject();
      });
  };
};

const getLivingSearchInfo = (params) => {
  return (dispatch) => {
    return httpClient
      .post(`${SERVER_APIS.Living_Search_Info_URL}`, params)
      .then(({ data }) => {
        dispatch({
          type: livingActionType.Living_Search_Info,
          payload: { searchInfo: data },
        });
        return Promise.resolve(data);
      })
      .catch(() => Promise.reject());
  };
};

const clearSearch = (params) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Living_Clear_Search });
  };
};

const onReportProperty = (params) => {
  return (dispatch) => {
    return httpClient
      .post(`${SERVER_APIS.Report_URL}`, params)
      .then(({ data }) => {
        dispatch({
          type: livingActionType.Report_Success,
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onGetAlert = (params) => {
  return (dispatch) => {
    return httpClient
      .post(`${SERVER_APIS.Alert_URL}`, params)
      .then(({ data }) => {
        dispatch({
          type: livingActionType.Alert_Success,
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const fetchAlerts = () => {
  return (dispatch) => {
    return httpClient
      .get(`${SERVER_APIS.Alert_URL}`)
      .then(({ data }) => {
        dispatch({
          type: livingActionType.Alert_Fetched,
          payload: { alerts: data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const deleteAlert = (alertId) => {
  return (dispatch) => {
    return httpClient
      .delete(`${SERVER_APIS.Alert_URL}/${alertId}`)
      .then(() => {
        dispatch({
          type: livingActionType.Alert_Removed,
          payload: { alertId: alertId },
        });

        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onAddLiving = (living) => {
  living.phoneNumber = `${living.phoneNumber}`;
  return (dispatch) => {
    dispatch({ type: livingActionType.Living_Adding });
    return httpClient
      .post(SERVER_APIS.Living_URL, living)
      .then((res) => {
        dispatch({
          type: livingActionType.Living_Add,
          payload: { Soberliving: res.data },
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: livingActionType.Living_Add,
          payload: { Soberliving: [] },
        });
        return Promise.reject(data);
      });
  };
};

const onFetchHostLivings = () => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Admin_Living_Fetching });
    return httpClient
      .post(`${SERVER_APIS.Living_List_URL}`, {})
      .then((response) => {
        dispatch({
          type: livingActionType.Admin_Living_Success,
          payload: { livings: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onSetSelectedLiving = (id) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Set_Selected_Living, payload: id });
  };
};

const onEditLiving = (id, data) => {
  data.phoneNumber = `${data.phoneNumber}`;
  return (dispatch) => {
    dispatch({ type: livingActionType.Editing_Living });
    return httpClient
      .patch(`${SERVER_APIS.Living_URL}/${id}`, data)
      .then((res) => {
        dispatch({
          type: livingActionType.Edited_Living,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: livingActionType.Edited_Living,
        });
        return Promise.reject(data);
      });
  };
};

const onDeleteLiving = (id) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Deleting_Living });
    return httpClient
      .delete(`${SERVER_APIS.Living_URL}/${id}`)
      .then((res) => {
        dispatch({
          type: livingActionType.Deleted_Living,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: livingActionType.Deleted_Living,
        });
        return Promise.reject(data);
      });
  };
};

const onFetchFavoriteLiving = () => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Fav_Living_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Living_Fav_URL}`)
      .then((response) => {
        dispatch({
          type: livingActionType.Fav_Living_Fetched,
          payload: { livings: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};





const onBookmarkLiving = (livingId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Bookmark_Adding });
    return httpClient
      .put(`${SERVER_APIS.Living_URL}/${livingId}/favorite`)
      .then((response) => {
        dispatch({
          type: livingActionType.Bookmark_Added,
          payload: { livingId },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onBookmarkLivingFacility = (livingId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Bookmark_Adding });
    return httpClient
      .put(`${SERVER_APIS.Facility_URL}/${livingId}/favorite`)
      .then((response) => {
        dispatch({
          type: livingActionType.Bookmark_Added,
          payload: { livingId },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onBookmarkLivingFacilityRemove = (livingId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Bookmark_Adding });
    return httpClient
      .delete(`${SERVER_APIS.Facility_URL}/${livingId}/favorite`)
      .then((response) => {
        dispatch({
          type: livingActionType.Bookmark_Added,
          payload: { livingId },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onRemoveBookmarkLiving = (livingId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Bookmark_Removing });
    return httpClient
      .delete(`${SERVER_APIS.Living_URL}/${livingId}/favorite`)
      .then((response) => {
        dispatch({
          type: livingActionType.Bookmark_Removed,
          payload: { livingId },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onMarkAsViewed = (livingId) => {
  return (dispatch) => {
    return httpClient
      .put(`${SERVER_APIS.Living_URL}/${livingId}/viewed`)
      .then((response) => {
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onFetchRecentlyViewedLiving = () => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Fetching_Recently_Viewed });
    return httpClient
      .get(`${SERVER_APIS.Living_Recently_Viewed_URL}`)
      .then((response) => {
        dispatch({
          type: livingActionType.Fetched_Recently_Viewed,
          payload: { livings: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};







const onFetchAllFacility = () => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Fetching_Recently_Viewed });
    return httpClient
      .get(`${SERVER_APIS.GetAll_favoriteList}/favoriteList`)
      .then((response) => {
        dispatch({
          type: livingActionType.Fetched_All_Facility,
          payload: { livings: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

// 
const onFetchAllFacilityRecent = () => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Fetching_Recently_Recent });
    return httpClient
      .get(`${SERVER_APIS.GetAll_favoriteList}/viewedlist`)
      .then((response) => {
        dispatch({
          type: livingActionType.Fetched_Recently_Recent,
          payload: { livings: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};



const onFetchMyReviews = () => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Fetching_My_Reviews });
    return httpClient
      .get(`${SERVER_APIS.My_Reviews}`)
      .then((response) => {
        dispatch({
          type: livingActionType.Fetched_My_Reveiws,
          payload: { reviews: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onContactHome = (livingId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Contacting_Home });
    return httpClient
      .put(`${SERVER_APIS.Living_URL}/${livingId}/contact`, {})
      .then((response) => {
        dispatch({
          type: livingActionType.Contacted_Home,
        });
        return Promise.resolve(response.data);
      })
      .catch(() => Promise.reject());
  };
};


const onContactHomeFavility = (livingId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Contacting_Home });
    return httpClient
      .put(`${SERVER_APIS.Living_URL_Facility}/${livingId}/contact`, {})
      .then((response) => {
        dispatch({
          type: livingActionType.Contacted_Home,
        });
        return Promise.resolve(response.data);
      })
      .catch(() => Promise.reject());
  };
};

const onGetActivity = () => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Fetching_Activity });
    return httpClient
      .get(`${SERVER_APIS.Get_Activity}`)
      .then((response) => {
        dispatch({
          type: livingActionType.Fetched_Activity,
          payload: { activity: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onUploadLivingPhotos = (photos) => {
  return (dispatch) => {
    return httpClient
      .post(`${SERVER_APIS.Media_Upload}`, photos)
      .then((response) => {
        return Promise.resolve(response.data.data);
      })
      .catch((err) => {
        
        return Promise.reject(err);
      });
  };
};

const onSaveLaunchAdDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Launch_Ad_Data, payload: data });
    return Promise.resolve();
  };
};

const onPromoteLiving = (livingId, data) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Promoting_Living });
    return httpClient
      .post(`${SERVER_APIS.Living_URL}/${livingId}/promote`, data)
      .then((response) => {
        dispatch({
          type: livingActionType.Promoted_Living,
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onUpdatePromoteLiving = (livingId, data) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Promoting_Living });
    return httpClient
      .put(`${SERVER_APIS.Living_URL}/${livingId}/promote`, data)
      .then((response) => {
        dispatch({
          type: livingActionType.Promoted_Living,
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onTurnOffAd = (livingId) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Turning_Off_Ad });
    return httpClient
      .delete(`${SERVER_APIS.Living_URL}/${livingId}/promote`)
      .then((response) => {
        dispatch({
          type: livingActionType.Turned_Off_Ad,
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};
const onUpdateAvailability = (livingId, data) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Updating_Availability });
    return httpClient
      .patch(`${SERVER_APIS.Living_URL}/${livingId}/availability`, data)
      .then((response) => {
        dispatch({
          type: livingActionType.Updated_Availability,
          payload: { data: response.data, livingId },
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
};

// For IOP center delete
const onDeleteIop = (id) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Deleting_Iop });
    return httpClient
      .delete(`${SERVER_APIS.Iop_URL}/${id}`)
      .then((res) => {
        dispatch({
          type: livingActionType.Deleted_Iop,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: livingActionType.Deleted_Iop,
        });
        return Promise.reject(data);
      });
  };
};

// delete  residecial center

const onDeleteResidencial = (id) => {
  return (dispatch) => {
    dispatch({ type: livingActionType.Deleting_Residencial });
    return httpClient
      .delete(`${SERVER_APIS.Residential}/${id}`)
      .then((res) => {
        dispatch({
          type: livingActionType.Deleted_Residencial,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: livingActionType.Deleted_Residencial,
        });
        return Promise.reject(data);
      });
  };
};

const claimFacility = () => {

  return (dispatch) => {
    dispatch({ type: livingActionType.Unclaimed_Facility });
    return httpClient
      .get(`${SERVER_APIS.Fetch_Claim_List}`)
      .then((response) => {
        dispatch({
          type: livingActionType.Unclaimed_Facility,
          payload: { claimList: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onFetchUnclaimedFacility = (id) => {

  return (dispatch) => {
    dispatch({ type: livingActionType.Claimed_Facility });
    return httpClient
      .patch(`${SERVER_APIS.Update_Claim}/${id}/claim`)
      .then((response) => {
        dispatch({
          type: livingActionType.Claimed_Facility,
          payload: { claim: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};



export const livingAction = {
  fetchLivingById: onFetchingLivingById,
  fetchLivingReviewsById: onFetchingLivingReviewsById,
  addReview: onAddReview,
  reportCause: onReportProperty,
  searchLiving,
  getLivingSearchInfo,
  getAlert: onGetAlert,
  addLiving: onAddLiving,
  fetchhostLivings: onFetchHostLivings,
  setSelectedLiving: onSetSelectedLiving,
  editLiving: onEditLiving,
  addReviewReply: onAddReviewReply,
  fetchFavLivings: onFetchFavoriteLiving,
  bookmarkLiving: onBookmarkLiving,
  removeBookmark: onRemoveBookmarkLiving,
  markAsViewed: onMarkAsViewed,
  fetchRecentlyViewed: onFetchRecentlyViewedLiving,
  fetchMyReviews: onFetchMyReviews,
  contactHome: onContactHome,
  contactHomeFacility : onContactHomeFavility,
  getActivity: onGetActivity,
  fetchAlerts,
  deleteAlert,
  uploadMedia: onUploadLivingPhotos,
  clearSearch,
  saveLaunchAdDetails: onSaveLaunchAdDetails,
  promoteLiving: onPromoteLiving,
  deleteLiving: onDeleteLiving,
  updateAvailablity: onUpdateAvailability,
  searchLivingMap,
  updatePromoteLiving: onUpdatePromoteLiving,
  onTurnOffAd,
  onFetchingLivingByIdWithoutLogin,
  deleteIop: onDeleteIop,
  deleteResidencial: onDeleteResidencial,
  onBookmarkLivingFacility: onBookmarkLivingFacility,
  onBookmarkLivingFacilityRemove: onBookmarkLivingFacilityRemove,
  onFetchAllFacility : onFetchAllFacility,
  onFetchAllFacilityRecent : onFetchAllFacilityRecent,
  unclaimedList: claimFacility,
  updateClaimFacility: onFetchUnclaimedFacility,
};
