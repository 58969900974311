import React, { useState, useEffect, Fragment } from "react";
import BackButtonComponent from "../../components/other/back-button.component";
import { connect, useDispatch, useSelector } from "react-redux";
import { livingAction, paymentActions } from "../../actions";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import AddCard from "../payment/add-card";
import { Button, Modal, Toast } from "react-bootstrap";
import ModalComponent from "../../components/modal/modal.component";
import LaunchAdConfirmation from "../payment/launch-ad-confirmation";
import PaymentCards from "../../components/cards/payment-cards";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { SERVER_APIS } from "../../constants";
import axios from "axios";

import { generateQueryParams, httpClient } from "../../utils/http-client.util";

import { ReactComponent as TickIcon } from "../../assets/images/tick_check_mark.svg";

function PaymentOptions(props) {
  const location = useLocation();
  const history = useHistory();
  const context = React.useContext(SpinnerContext);
  const test = useSelector((state) => state);
  const [selectedCard, setSelectedCard] = useState(undefined);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [statusplan, setStausplan] = useState();
  const [showConfirmPaymentDialog, setShowConfirmPaymentDialog] =
    useState(false);
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [ppcConfirmationModel, setPpcConfirmationModel] = useState(false);

  useEffect(() => {
    if (
      props?.details?.livingName === undefined &&
      context.subscribePlanId === ""
    ) {
      setShowSuccessPopup(true);
      setTimeout(() => {
        props.history.push("/profile");
      }, 5000);
    } else {
    }
  }, []);
  const isAddSubscription = () => {
    if (
      context.addSober === "addSubscriptionPage" ||
      context.addDetox === "addSubscriptionPage" ||
      context.addPhp == "addSubscriptionPage" ||
      context.addIop === "addSubscriptionPage" ||
      context.addResidential === "addSubscriptionPage"
    ) {
      return true;
    }
  };

  const togglShowAddCard = () => {
    setShowAddCard(!showAddCard);
  };

  const hideConfirmPaymentDialog = (childData) => {
    setShowConfirmPaymentDialog(childData);
    context.hideLoader();
  };

  const ppcSubsFunction = async () => {
    const data = {
      ppcAllocatedBudget: location?.state?.data?.budget,
      cardId: selectedCardId,
      facilityOrSoberLivingId: location?.state?.data?.id,
    };

    try {
      const url = `${SERVER_APIS.PPC_Subs_Url}`;
      const res = await httpClient.post(SERVER_APIS.PPC_Subs_Url, data);
      if (res?.status == "200") {
        setPpcConfirmationModel(true);
        setTimeout(() => {
          setPpcConfirmationModel(false);
          history.push("/profile");
        }, 2500);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const preSalesubsciptionFunc = () => {
    if (
      test?.php?.facilityInfo?.facilitytype == "php" ||
      test?.detox?.facilityInfo?.facilitytype == "detox" ||
      test?.residential?.facilityInfo?.facilitytype == "residential" ||
      test?.iopCenter?.facilityInfo?.facilitytype == "iop"
    ) {
      const facilityId =
        test?.php?.facilityInfo?.id ||
        test?.residential?.facilityInfo?.id ||
        test?.detox?.facilityInfo?.id ||
        test?.iopCenter?.facilityInfo?.id ||
        null;
      const facilityCenter =
        test?.php?.facilityInfo?.facilitytype ||
        test?.residential?.facilityInfo?.facilitytype ||
        test?.detox?.facilityInfo?.facilitytype ||
        test?.iopCenter?.facilityInfo?.facilitytype ||
        null;
      const updatedServerApi = {
        ...SERVER_APIS,
        facilityCenter: `/${facilityCenter}`,
      };
      context.showLoader("Adding Subscription Plan...");
      const valueFacility = {
        cardId: selectedCardId,
        priceId:
          test?.residential?.priceId ||
          test?.detox?.priceId ||
          test?.php?.priceId ||
          test?.iopCenter?.priceId,
        userId: test?.auth?.user?.id,
      };

      var facilityOption = {
        method: "post",
        url: `${updatedServerApi.facilityCenter}/${facilityId}/subscribe`,
        data: valueFacility,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(facilityOption)
        .then(async (res) => {
          setStausplan(res?.data?.status);
          setShowConfirmPaymentDialog(true);
          setClientSecretKey(res.data.client_secret);
          context.hideLoader();
        })
        .catch((error) => {
          // alert(error);
          context.hideLoader();
        });
    } else {
      context.showLoader("Adding Subscription Plan...");
      const value = {
        cardId: selectedCardId,
        priceId: context.subscribePlanId,
        userId: props.user.id,
      };
      var authOptions = {
        method: "post",
        url: `${SERVER_APIS.Living_URL}/${context.livingId}/subscribe`,
        data: value,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(authOptions)
        .then(async (res) => {
          setShowConfirmPaymentDialog(true);
          setClientSecretKey(res.data.client_secret);
          context.hideLoader();
        })
        .catch((error) => {
          // alert(error);
          context.hideLoader();
        });
    }
  };

  const normalSubsciptionFunc = () => {
    if (
      test?.php?.facilityInfo?.facilitytype == "php" ||
      test?.detox?.facilityInfo?.facilitytype == "detox" ||
      test?.residential?.facilityInfo?.facilitytype == "residential" ||
      test?.iopCenter?.facilityInfo?.facilitytype == "iop"
    ) {
      const facilityId =
        test?.php?.facilityInfo?.id ||
        test?.residential?.facilityInfo?.id ||
        test?.detox?.facilityInfo?.id ||
        test?.iopCenter?.facilityInfo?.id ||
        null;
      const facilityCenter =
        test?.php?.facilityInfo?.facilitytype ||
        test?.residential?.facilityInfo?.facilitytype ||
        test?.detox?.facilityInfo?.facilitytype ||
        test?.iopCenter?.facilityInfo?.facilitytype ||
        null;
      const updatedServerApi = {
        ...SERVER_APIS,
        facilityCenter: `/${facilityCenter}`,
      };
      context.showLoader("Adding Subscription Plan...");
      const valueFacility = {
        cardId: selectedCardId,
        priceId: location?.state?.state?.priceId,
        userId: test?.auth?.user?.id,
        couponCode: location?.state?.state?.couponCode,
      };

      var facilityOption = {
        method: "post",
        url: `${updatedServerApi.facilityCenter}/${facilityId}/subscribe`,
        data: valueFacility,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(facilityOption)
        .then(async (res) => {
          setStausplan(res?.data?.status);
          setShowConfirmPaymentDialog(true);
          setClientSecretKey(res.data.client_secret);
          context.hideLoader();
        })
        .catch((error) => {
          // alert(error);
          context.hideLoader();
        });
    } else {
      context.showLoader("Adding Subscription Plan...");
      const value = {
        cardId: selectedCardId,
        priceId: context.subscribePlanId,
        userId: props.user.id,
      };
      var authOptions = {
        method: "post",
        url: `${SERVER_APIS.Living_URL}/${context.livingId}/subscribe`,
        data: value,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(authOptions)
        .then(async (res) => {
          setShowConfirmPaymentDialog(true);
          setClientSecretKey(res.data.client_secret);
          context.hideLoader();
        })
        .catch((error) => {
          // alert(error);
          context.hideLoader();
        });
    }
  };

  const toggleConfirmationDialog = (success = false, message = "") => {
    if (context.subscribePlanId === "") {
      setShowConfirmationDialog(!showConfirmationDialog);
      if (success) {
        context.showMessage(message);
        setShowSuccessPopup(true);
        setTimeout(() => {
          props.history.replace("/profile");
        }, 5000);
      }
    } else {
      if (context.subscribePlanType === "ppc") {
        ppcSubsFunction();
      } else {
        // subsciptionFunc();
        if (location?.state?.state?.subsFlow === "normal") {
          normalSubsciptionFunc();
        } else {
          preSalesubsciptionFunc();
        }
      }
    }
  };

  return (
    <div>
      {
        <Modal show={ppcConfirmationModel} centered>
          <Modal.Body
            style={{
              padding: "40px",
              backgroundColor: "white",
              borderRadius: "10px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Toast.Body>
              <TickIcon />
              <h5 style={{ marginTop: "10px" }}>
             { props?.location?.state?.data?.houseType ==="sober living" ? "Sober living": "Facility center"} has been promoted successfully.
              </h5>
            </Toast.Body>
          </Modal.Body>
        </Modal>
      }

      {showSuccessPopup && (
        <Toast show={true} autohide className="subscription-toast upgrade-plan">
          <Toast.Body>
            <p>
              <img
                src={require("../../assets/images/tick_check_mark.svg")}
                className="tick_mark-icon"
                alt=""
              />{" "}
              <img
                src={require("../../assets/images/cancle.svg")}
                alt=""
                onClick={() => {
                  setShowSuccessPopup(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </p>
          </Toast.Body>
        </Toast>
      )}
      <section>
        {isAddSubscription() ? (
          <div className={"container"}>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.history.push("/profile");
              }}
            >
              <h5>
                <b style={{ color: "#ff5f5f" }}>Cancel Payment</b>
              </h5>
            </a>
          </div>
        ) : (
          <BackButtonComponent className="container" isBlack={true} />
        )}
      </section>
      <section className="pt-4 listing">
        <div className="container">
          <div className="listing-header profile-listing-header">
            <h3 className="main-title mb-0">Payment options</h3>
            <Button
              className="btn btn-primary border-radius-20"
              onClick={togglShowAddCard}
            >
              ADD Card
            </Button>
          </div>
        </div>
        <div className="container">
          <PaymentCards
            selectedCard={selectedCard?.id}
            onSelectCard={(card) => {
              setSelectedCardId(card?.id);
              setSelectedCard(selectedCard?.id === card?.id ? undefined : card);
            }}
          />
        </div>
        {props.cards?.length > 0 && (
          <Fragment>
            <div className="profile-add-btn chart-footer-wrapper">
              <button
                className="btn btn-primary my-4 radius-20"
                onClick={() => toggleConfirmationDialog(false)}
                disabled={!selectedCard}
              >
                Continue
              </button>
            </div>
            {!selectedCard && (
              <p className="text-center text-bold">
                Please select card to continue.
              </p>
            )}
          </Fragment>
        )}
      </section>
      {test?.php?.facilityInfo?.facilitytype == "php" ||
      test?.detox?.facilityInfo?.facilitytype == "detox" ||
      test?.residential?.facilityInfo?.facilitytype == "residential" ||
      test?.iopCenter?.facilityInfo?.facilitytype == "iop" ? (
        <ModalComponent
          data={{
            priceAmount: location?.state?.state?.priceAmount,
            houseType: location?.state?.state?.facilityType,
            centerName: location?.state?.state?.name,
            selectedCard: selectedCard,
          }}
          component={AddCard}
          show={showConfirmPaymentDialog}
          parentCallback={hideConfirmPaymentDialog}
          clientSecret={clientSecretKey}
          onHide={hideConfirmPaymentDialog}
          paymentPlan="PaymentConfirmation"
          onSuccess={(data) => {
            togglShowAddCard();
          }}
        />
      ) : (
        <ModalComponent
          data={{
            livingName: context.selectedInterval,
            selectedBudget: context.selectedAmount,
            selectedCard: selectedCard,
          }}
          component={AddCard}
          show={showConfirmPaymentDialog}
          parentCallback={hideConfirmPaymentDialog}
          clientSecret={clientSecretKey}
          onHide={hideConfirmPaymentDialog}
          paymentPlan="PaymentConfirmation"
          onSuccess={(data) => {
            togglShowAddCard();
          }}
        />
      )}

      {context.subscribePlanType === "ppc" && (
        <ModalComponent
          data={{
            priceAmount: location?.state?.data?.budget,
            houseType: "detox",
            selectedCard: selectedCard,
          }}
          component={AddCard}
          show={showConfirmPaymentDialog}
          parentCallback={hideConfirmPaymentDialog}
          clientSecret={clientSecretKey}
          onHide={hideConfirmPaymentDialog}
          paymentPlan="PaymentConfirmation"
          onSuccess={(data) => {
            togglShowAddCard();
          }}
        />
      )}

      <ModalComponent
        data={{
          livingName: props.details.livingName,
          selectedBudget: props.details.selectedBudget,
          selectedCard: selectedCard,
          livingId: props.details.livingId,
          advertisementID: props.details.advertisementID,
        }}
        component={LaunchAdConfirmation}
        show={showConfirmationDialog}
        onHide={toggleConfirmationDialog}
        onSuccess={(data) => {
          toggleConfirmationDialog();
        }}
      />
      <ModalComponent
        component={AddCard}
        show={showAddCard}
        onHide={togglShowAddCard}
        onSuccess={(data) => {
          togglShowAddCard();
        }}
      />
    </div>
  );
}

export default connect(
  ({ payment, living, auth }) => ({
    cards: payment.cards,
    isFetchingCards: payment.fetchingCards,
    details: living.launchAdDetails,
    user: auth.user,
  }),
  paymentActions
)(PaymentOptions);
