import React, { useEffect, useState } from 'react';
import backImgBlack from "../../../assets/images/back.svg";
import { Modal, Button } from "react-bootstrap";
import { detoxFilter, iopFilter, phpFilter, residentialFilter } from './filterOptions';
import { formatString } from "../../../constants/utils";
import MappedCheckBox from './mappedCheckbox';
import { useSelector, useDispatch } from "react-redux";
import { homeAction } from '../../../actions';
import { useHistory, useParams } from "react-router-dom";
const FilterModel = (props) => {
    const history=useHistory();
    const dispatch = useDispatch();
    const { open, onCancel,total, facilityType, searchBar, getPostData,handleCheckboxChange,stateValues,handleOnChange,setValues,isScholarshipOffer } = props;

    const {specialized,conditionTreated,medicationTherapy,treatmentApproaches,preTreatment,testing,smokingPolicy,isGenderSpecific,gender,verifiedBadge,isFeatured,finalFilter,facilityCount,allowableStay
    } = stateValues;

    const {
        setSpecialized,
        setConditionTreated,
        setMedicationTherapy,
        setPreTreatment,
        setTreatmentApproaches,
        setTesting,
        setIsGenderSpecific,
        setGender,
        setSmokingPolicy,
        setIsFeatured,
        setIsVerifiedBadge,
        setAllowableStay,
        setIsScholarshipOffer,
        setSelectedItems,
        setInsurance
    }=setValues

    const [isViewMore, setIsViewMore] = useState({ specialized: false, conditionTreated: false, medicationTherapy: false, preTreatment: false, testing: false, smokingPolicy: false, treatmentApproaches: false, });
  
    // Handle view more button 
    const handleViewMore = (options) => {
        setIsViewMore((prevIsViewMore) => ({
            ...prevIsViewMore,
            [options]: !prevIsViewMore[options],
        }));
    }

    const reset = () => {
        setSpecialized([]);
        setConditionTreated([]);
        setMedicationTherapy([]);
        setPreTreatment([]);
        setTreatmentApproaches([]);
        setTesting([]);
        setIsGenderSpecific(false);
        setGender("");
        setSmokingPolicy([]);
        setIsFeatured(false);
        setIsVerifiedBadge(false);
        setIsScholarshipOffer(false);
        setInsurance("");
        setAllowableStay({
            from: "", to: ""
        });
        setSelectedItems([])
        setIsViewMore({ specialized: false, conditionTreated: false, medicationTherapy: false, preTreatment: false, testing: false, smokingPolicy: false, treatmentApproaches: false })
    }

    const handleCancel = () => {
        onCancel();
    }

    const handleShow = () => {
        onCancel();

    }

    return (
        <Modal show={open} onHide={onCancel}>

            <div className="modal upgrade-plan filter-modal fade show" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header flex-wrap text-md-center">
                            <div className="modal-header-top d-flex w-100 align-items-center justify-content-between mb-4">
                                <a role="button" className="d-block d-md-none" onClick={()=>onCancel()}>
                                    <img src={backImgBlack} alt="" />
                                </a>
                                <div className="filter-right d-flex align-items-center">
                                    <a className="cancel-btn d-none d-md-block" role="button" onClick={() => handleCancel()}><img src={require('../../../assets/images/cancle.svg')} className="d-none d-md-block" alt="" /></a>
                                    <a className="cancel-btn d-block d-md-none" role="button" onClick={() => reset()}><span className="d-block d-md-none">Clear</span></a>
                                    <a className="apply-btn d-block d-md-none" role="button"   onClick={() => handleShow()}>Apply</a>
                                </div>
                            </div>
                            <h3 className="w-100 mb-0">Filters</h3>
                        </div>
                        <div className="filter-content">
                            <form action="#">
                                <div className="taste-recovery-form add-sobar-living">
                                    <div className="form-mobile-tab-content">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper mb-0">
                                                    <label className="mb-4">Specializes In</label>
                                                    <div className="dflex-form">
                                                        <MappedCheckBox data={Object.keys(finalFilter.specialization)}
                                                            identifier="specialized"
                                                            isViewMore={isViewMore?.specialized}
                                                            value={specialized}
                                                            onCheckboxChange={handleCheckboxChange} />
                                                        {Object.keys(finalFilter.specialization)?.length > 8 && (!isViewMore.specialized ? <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("specialized")}
                                                            >View more</button>
                                                        </div> : <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("specialized")}
                                                            >View less</button>
                                                        </div>)}
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-12">
                                                <div className="form-group mb-0">
                                                    <label className="mb-4">
                                                        Is Your Program Gender-Specific
                                                    </label>
                                                    <div className="dflex-form justify-content-start border-0">
                                                        <div className="custom-control custom-radio">
                                                            <input
                                                                type="radio"
                                                                id="genderSpecificYes"
                                                                className="custom-control-input"
                                                                onChange={() => handleOnChange("isGenderSpecific", true)}
                                                                checked={isGenderSpecific}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="genderSpecificYes"
                                                            >
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input
                                                                type="radio"
                                                                id="genderSpecificNo"
                                                                className="custom-control-input"
                                                                onChange={() => handleOnChange("isGenderSpecific", false)}

                                                                checked={!isGenderSpecific}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="genderSpecificNo"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {isGenderSpecific && (
                                                <div className="col-md-12">
                                                    <div className="form-group mb-0">
                                                        <label className="mb-4">
                                                            Gender Type{" "}
                                                            <span className="mandatory">*</span>
                                                        </label>
                                                        <div className="dflex-form justify-content-start border-0">
                                                            <div className="custom-control custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    id="male"
                                                                    className="custom-control-input"
                                                                    checked={gender === "men"}
                                                                    onChange={() => handleOnChange("gender", "men")}

                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="male"
                                                                >
                                                                    Male
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    id="female"
                                                                    className="custom-control-input"
                                                                    checked={gender === "women"}
                                                                    onChange={() => handleOnChange("gender", "women")}

                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="female"
                                                                >
                                                                    Female
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    id="LGBTQ"
                                                                    className="custom-control-input"
                                                                    checked={gender === "lgbtq"}
                                                                    onChange={() => handleOnChange("gender", "lgbtq")}

                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="LGBTQ"
                                                                >
                                                                    LGBTQ+
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                             <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper mb-0">
                                                    <label className="mb-4">Condition Treated</label>
                                                    <div className="dflex-form">
                                                        <MappedCheckBox data={Object.keys(finalFilter?.conditionTreat)}
                                                            identifier="conditionTreated"
                                                            isViewMore={isViewMore?.conditionTreated}
                                                            value={conditionTreated}
                                                            onCheckboxChange={handleCheckboxChange} />
                                                        {Object.keys(finalFilter?.conditionTreat)?.length > 8 && (!isViewMore?.conditionTreated ? <div className="view-more-btn d-block w-100 ">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("conditionTreated")}
                                                            >View more</button>
                                                        </div> : <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("conditionTreated")}
                                                            >View less</button>
                                                        </div>)}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper mb-0">
                                                    <label className="mb-4">Medication Therapy</label>
                                                    <div className="dflex-form">
                                                        <MappedCheckBox data={Object.keys(finalFilter.medicationTherapy)}
                                                            identifier="medicationTherapy"
                                                            isViewMore={isViewMore?.medicationTherapy}
                                                            value={medicationTherapy}
                                                            onCheckboxChange={handleCheckboxChange} />

                                                        {Object.keys(finalFilter.medicationTherapy).length > 8 && (!isViewMore?.medicationTherapy ? <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("medicationTherapy")}
                                                            >View more</button>
                                                        </div> : <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("medicationTherapy")}
                                                            >View less</button>
                                                        </div>)}
                                                    </div>
                                                </div>
                                            </div>

                                            {facilityType !=="detox" && <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper mb-0">
                                                    <label className="mb-4">Treatment Approaches</label>
                                                    <div className="dflex-form">
                                                        <MappedCheckBox data={Object.keys(finalFilter.treatmentApproaches)}
                                                            identifier="treatmentApproaches"
                                                            isViewMore={isViewMore?.treatmentApproaches}
                                                            value={treatmentApproaches}
                                                            onCheckboxChange={handleCheckboxChange} />
                                                        {Object.keys(finalFilter.treatmentApproaches)?.length > 8 && (!isViewMore?.treatmentApproaches && Object.keys(finalFilter.treatmentApproaches)?.length > 8 ? <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("treatmentApproaches")}
                                                            >View more</button>
                                                        </div> : <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("treatmentApproaches")}
                                                            >View less</button>
                                                        </div>)}

                                                    </div>
                                                </div>
                                            </div>}

                                            <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper mb-0">
                                                    <label className="mb-4">Assessment/Pre-Treatment</label>
                                                    <div className="dflex-form">
                                                        <MappedCheckBox data={Object.keys(finalFilter.preTreatment)}
                                                            identifier="preTreatment"
                                                            isViewMore={isViewMore?.preTreatment}
                                                            value={preTreatment}
                                                            onCheckboxChange={handleCheckboxChange} />
                                                        {Object.keys(finalFilter.preTreatment)?.length > 8 && (!isViewMore?.preTreatment ? <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("preTreatment")}
                                                            >View more</button>
                                                        </div> : <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("preTreatment")}
                                                            >View less</button>
                                                        </div>)}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper mb-0">
                                                    <label className="mb-4">Testing</label>
                                                    <div className="dflex-form">
                                                        <MappedCheckBox data={Object.keys(finalFilter.testing)}
                                                            identifier="testing"
                                                            isViewMore={isViewMore?.testing}
                                                            value={testing}
                                                            onCheckboxChange={handleCheckboxChange} />
                                                        {Object.keys(finalFilter.testing).length > 8 && (!isViewMore?.testing ? <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("testing")}
                                                            >View more</button>
                                                        </div> : <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("testing")}
                                                            >View less</button>
                                                        </div>)}

                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper mb-0">
                                                    <label className="mb-4">Facility Smoking Policy</label>
                                                    <div className="dflex-form ">
                                                        <MappedCheckBox data={Object.keys(finalFilter.smokingPolicy)}
                                                            identifier="smokingPolicy"
                                                            isViewMore={isViewMore?.smokingPolicy}
                                                            value={smokingPolicy}
                                                            onCheckboxChange={handleCheckboxChange} />
                                                        {Object.keys(finalFilter.smokingPolicy).length > 8 && (!isViewMore?.smokingPolicy ? <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("smokingPolicy")}
                                                            >View more</button>
                                                        </div> : <div className="view-more-btn d-block w-100">
                                                            <button type="button" className="btn btn-primary-outline radius-20 m-0"
                                                                onClick={() => handleViewMore("smokingPolicy")}
                                                            >View less</button>
                                                        </div>)}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        Stay Period{" "}

                                                    </label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group mb-0">
                                                                <label className="small-label">From</label>
                                                                <input
                                                                    name="dateRange.from"
                                                                    type="number"
                                                                    min="0"
                                                                    maxValue={allowableStay?.to ? parseInt(allowableStay?.to) - 1 : ""}
                                                                    className="form-control"
                                                                    placeholder="Days"
                                                                    value={allowableStay?.from}
                                                                    onChange={(e) => handleOnChange("from", e.target.value)}
                                                                />
                                                                {allowableStay.from != "" && (
                                                                    <span
                                                                        className="days"
                                                                    >
                                                                        Days
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group mb-0">
                                                                <label className="small-label">To</label>
                                                                <input
                                                                    name="dateRange.to"
                                                                    type="number"
                                                                    className="form-control"
                                                                
                                                                    min={allowableStay?.from ? parseInt(allowableStay?.from) + 1 : ""}
                                                                    placeholder="Days"
                                                                    
                                                                    value={allowableStay?.to}
                                                                   
                                                                    onChange={(e) => handleOnChange("to", e.target.value)}
                                                                />
                                                                {allowableStay.to != "" && (
                                                                    <span
                                                                        className="days"
                                                                    >
                                                                        Days
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group checkbox_wrapper ">
                                                    <div className="dflex-form ">
                                                        <div className="custom-control custom-checkbox">

                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="isVerified"
                                                                name="isVerified"
                                                                value={verifiedBadge}
                                                                checked={verifiedBadge}
                                                               
                                                                onChange={() => handleOnChange("verifiedBadge", !verifiedBadge)}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="isVerified"
                                                            >
                                                                Verified Only
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="dflex-form ">
                                                    <div className="custom-control custom-checkbox">

                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="isFeatured"
                                                            name="isFeatured"
                                                            checked={isFeatured}
                                                            onChange={() => handleOnChange("isFeatured", !isFeatured)}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="isFeatured"
                                                        >
                                                            Featured Only
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="dflex-form ">
                                                    <div className="custom-control custom-checkbox">

                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="isScholarshipOffer"
                                                            name="isScholarshipOffer"
                                                            checked={isScholarshipOffer}
                                                            onChange={() => handleOnChange("isScholarshipOffer", !isScholarshipOffer)}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="isScholarshipOffer"
                                                        >
                                                           Offer Scholarships 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer d-none d-md-flex">
                            <button type="button" className="modal-btn confirm-btn d-block" onClick={() => {
                                reset();
                                }}>Clear all</button>
                            <button type="button" className="btn btn-primary radius-20 m-0"
                                onClick={() => handleShow()}

                            >Show {total} Facilities</button>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default FilterModel





