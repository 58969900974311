import React, { Component, useContext, useEffect, useState } from "react";
import backImgBlack from "../../assets/images/back.svg";
import VerifiedBadgeUpload from "../upload-photos/verifiedBadgeUpload";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { httpClient } from "../../utils/http-client.util";
import { SERVER_APIS } from "../../constants";
import { useDispatch } from "react-redux";
import { homeAction } from "../../actions/home.action";
import { Modal } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { livingAction } from "../../actions";

const VerifiedBadgeModel = (props) => {
  const dispatch = useDispatch();
  const { open, onClose } = props;
  const history = useHistory();
  const [comment, setComment] = useState("");
  const [notUploadedCount, setNotUploadedCount] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [media, setMedia] = useState([]);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  const uploadMedia = async (e) => {
    setIsUploading(true);
    if (media.length > 0) {
      showLoader("Uploading media files..");
      let uploadedFilesCount = 0;
      const fileUploadPromises = media.map(async (file, index) => {
        const formData = new FormData();
        formData.append(`file${index}`, file);
        try {
          const response = await httpClient.post(
            SERVER_APIS.Media_Upload_VerifiedBadge,
            formData
          );
          uploadedFilesCount++;
          showLoader(`Uploading media files ${uploadedFilesCount}`);
          return response.data;
        } catch (error) {
          throw new Error(
            `Failed to upload file ${index + 1}: ${error.message}`
          );
        }
      });
      const uploadedFilesData = await Promise.all(fileUploadPromises);

      const resMedia = uploadedFilesData.map((uploadResult, index) => {
        if (
          !uploadResult.errors &&
          uploadResult.data &&
          uploadResult.data.length > 0
        ) {
          const imageData = uploadResult.data[0];
          return {
            position: index,
            id: imageData.id,
          };
        } else {
          throw new Error(
            `Error occurred while processing upload result ${index + 1}: ${
              uploadResult.errors
            }`
          );

          return null;
        }
      });

      const arrayID = resMedia.map((item) => item.id);

      const data = {
        facilityId: props.id,
        documents: arrayID,
        description: comment,
      };
      showLoader("Requesting for verification ");
      await dispatch(homeAction.verifiedBadgeRequest(data));
      await dispatch(livingAction.fetchhostLivings());

      hideLoader("");
      onClose();
    } else {
      setShowErrorPopup(true);
      setIsUploading(false);
      setTimeout(() => {
        setShowErrorPopup(false);
      }, 2500);
    }
  };
  const getImageData = (data) => {
    setMedia(data);
  };

  return (
    <>
      {showErrorPopup && (
        <Modal
          centered
          show={showErrorPopup}
          onHide={() => {}}
          className="upgrade-plan cancel-subscription-modal updated-successfully-modal"
          style={{ background: "transparent" }}
          backdrop={false}
        >
          <div className="upgrade-plan-content d-flex flex-column align-items-center">
            <img
              src={require("../../assets/images/cross-cancel.png")}
              width="50"
              height="50"
              alt="Error!"
            />
            <span className="loading-msg">
              {"Atleast one document is mandatory"}
            </span>
          </div>
        </Modal>
      )}

      {open && (
        <div
          className="modal upgrade-plan upload-documents fade show"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content upload-documents-content">
                <div className="modal-header flex-wrap p-0 border-bottom-0">
                  <div className="modal-header-top d-flex w-100 align-items-center justify-content-between">
                    <a role="button" className="d-block d-md-none">
                      <img
                        src={backImgBlack}
                        alt=""
                        onClick={() => history.push(-1)}
                      />
                    </a>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        onClose();
                        hideLoader("");
                      }}
                      className={`cancel-btn ${isUploading ? "disabled" : ""}`}
                      role="button"
                      disabled={isUploading}
                    >
                      <img
                        src={require("../../assets/images/cancle.svg")}
                        className="d-none d-md-block"
                        alt=""
                      />{" "}
                      <span className="d-block d-md-none">CANCEL</span>
                    </a>
                  </div>
                  <h3 className="w-100 mb-4 pb-2">Upload documents</h3>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-4 pb-2">
                        <label>
                          Upload license
                          <span className="mandatory" style={{ color: "red" }}>
                            *
                          </span>{" "}
                          <span className="d-flex">
                            Max of 5 documents (word/pdf/jpg)
                          </span>
                        </label>
                        <VerifiedBadgeUpload
                          name="photos"
                          selectedPhotos={photos}
                          deleteImage={(data) => {
                            setPhotos(data, true);
                            setNotUploadedCount(0);
                            setUploadedCount(0);
                          }}
                          addImage={(data) => {
                            setPhotos(data, true);
                            setNotUploadedCount(0);
                            setUploadedCount(0);
                          }}
                          draggable={true}
                          getImageData={getImageData}
                          isUploading={isUploading}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <label>License description</label>
                        <textarea
                          name="about"
                          maxlength="100"
                          rows="5"
                          className="form-control"
                          placeholder="Add text here"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <span>{comment.length}</span>/<span>100</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => onClose()}
                  type="button"
                  className="modal-btn d-none d-md-block"
                  data-dismiss="modal"
                  disabled={isUploading}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal-btn confirm-btn"
                  onClick={() => uploadMedia()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default VerifiedBadgeModel;
