import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  getCityName,
  getLocationFromItem,
  getPriceFromItem,
  getRatingsFromItem,
  Ucfirst,
} from "../../utils";
import { seoUrlText } from "../../constants";
import defaultImg from "../../assets/images/default.png";
import starImg from "../../assets/images/star-24px.svg";
import starFillImg from "../../assets/images/star-24px.svg";
import pinImg from "../../assets/images/ic_pin_small.svg";
import editImg from "../../assets/images/edit-primary.svg";
import infoPrimary from "../../assets/images/info-primary.svg";
import infoWhite from "../../assets/images/info-white-1.svg";
import editImgMobile from "../../assets/images/edit.svg";
import deleteImg from "../../assets/images/delete-primary.svg";
import verifiedbadgeImg from "../../assets/images/verifiedbadge.svg";
import surveyImg from "../../assets/images/survey.svg";
import NoImage from "../../assets/images/no-image.svg";
import { useDispatch } from "react-redux";
import cancleImg from "../../assets/images/cancle-primary.svg";
import { addiopcenterAction } from "../../actions/index";
import DeleteConfirmationModal from "../modal/DeleteConfirmationModal.jsx";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import VerifiedBadgeModel from "../modal/verifiedBadgeModal.component";
import PayPerClick from "../modal/payPerClick.component";
import CancelConfirmationModal from "../modal/cancelSubscription.component";
import upgradeImg from "../../assets/images/upgrade.svg";
import promotionsImg from "../../assets/images/promotions.svg";
import { Tooltip } from "../button/tool-tip.component";
import cancleImgMobile from "../../assets/images/cancle-subscription-mobile.svg";
import infoMobile from "../../assets/images/info-mobile.svg";

const ResidencialCard = (props) => {
  const item = props?.item;
  const {
    pageName,
    isRecommended,
    isHost,
    onDeleteLiving,
    isPreSaleSubscribed,
    preSalePlanType,
    isSubscribe,
    subscriptionDetail,
  } = props;
  const [imageUrl, setImageUrl] = useState("");
  const [openDeletemodal, setOpenDeletemodal] = useState(false);
  const [openVerifiedBadge, setOpenVerifiedBadge] = useState(false);
  const [ratingStr, setRatingStr] = useState("");

  const [locationStr, setLocationStr] = useState("");
  const [heading, setHeading] = useState("");

  const [stateStr, setStateStr] = useState("");
  const [isPromoted, setIsPromoted] = useState(false);
  const [objFit, setObjFit] = useState("cover");
  const [isSubscribed, setIsSubscribed] = useState("");
  const [openPPC, setOpenPPC] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const contextType = React.useContext(SpinnerContext);
  useEffect(() => {
    setImageUrl(getImageUrlFromItem());
    setRatingStr(getRatingsFromItem(item));

    setHeading(item?.name);
    setLocationStr(getLocationFromItem(item));

    setStateStr(item?.geocodingResult?.stateLong);
    setIsPromoted(item?.isPromoted);
    setIsSubscribed(item?.isSubscribed);
  }, [item]);

  const deleteLiving = (id) => {};

  const isTextLong = locationStr?.length > 20;
  const city = getCityName(item?.geocodingResult);

  const onImgLoad = ({ target: img }) => {
    try {
      if (img?.naturalWidth < img?.offsetWidth) {
        setObjFit("cover");
      } else {
        setObjFit("cover");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <VerifiedBadgeModel
        open={openVerifiedBadge}
        onClose={() => setOpenVerifiedBadge(false)}
        id={item?.id}
      />
      <DeleteConfirmationModal
        open={openDeletemodal}
        onCancel={() => setOpenDeletemodal(false)}
        onDelete={onDeleteLiving}
        residential={"residential"}
      />

      <CancelConfirmationModal
        open={openCancelModal}
        name={item?.facilityType}
        onCancel={() => setOpenCancelModal(false)}
        subscriptionId={item?.subscriptionDetail?.subscriptionId}
        facilityType={item?.facilityType}
        facilityId={item?.id}
        userId={item?.createdBy}
      />
      <PayPerClick
        open={openPPC}
        onClosePPC={() => setOpenPPC(false)}
        id={item?.id}
        isPPCSubscribed={item?.isPPCSubscribed}
        houseType="residential"
        ppcPerClickPrice={item?.ppcPerClickPrice}
      />
      <div className="sober-living-slider_item w-100">
        <Link
          className="card text-decoration-none d-flex flex-wrap flex-row"
          to={{
            pathname: `/residentialfacility/view/${item?.id}`,
            state: { property: item },
          }}
        >
          <div className="card-img" onClick={() => deleteLiving(item?.id)}>
            <img
              className="img-fluid"
              src={imageUrl ? imageUrl : NoImage}
              style={{ objectFit: objFit }}
              onLoad={onImgLoad}
              onError={() => setImageUrl(defaultImg)}
              alt=""
            />
            {pageName === "Profile" ? (
              <div>
                {isHost === true && (preSalePlanType || isSubscribed) ? (
                  (preSalePlanType === "pro" && isPreSaleSubscribed) ||
                  subscriptionDetail?.subscriptionType === "pro" ? (
                    <span className="subscription-msg">Pro</span>
                  ) : (
                    <span className="subscription-msg unsubscribed">Basic</span>
                  )
                ) : (
                  <span className="subscription-msg listed">
                    Not Subscribed
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {!isRecommended ? (
            <div className="card-body">
              <div className="property-wrapper d-flex flex-column flex-md-row justify-content-between w-100 h-100 border-0 pr-0">
                <div className="card-content-left d-flex flex-column py-2">
                  <div className="card-heading">
                    <span>
                      {heading.length > 50
                        ? heading
                            .slice(0, 50)
                            .split(" ")
                            ?.map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")
                        : heading
                            .split(" ")
                            ?.map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}{" "}
                    </span>

                    <span className="card-actions d-flex d-md-none align-items-center">
                      {!item?.subscriptionDetail?.subscriptionId && (
                        <button className="owner-card-action-button subscription-pending d-block d-md-none">
                          <Tooltip message="Subscription pending. Subscribe your facility to get listed on the homepage.">
                            <img
                              src={infoMobile}
                              className="img-fluid"
                              alt=""
                            />
                          </Tooltip>
                        </button>
                      )}
                      {item?.subscriptionDetail?.subscriptionId && (
                        <button
                          className="owner-card-action-button d-flex d-md-none"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenCancelModal(true);
                          }}
                        >
                          <img
                            src={cancleImgMobile}
                            className="img-fluid"
                            alt=""
                          />
                        </button>
                      )}
                      <button
                        className="owner-card-action-button -disabled"
                        onClick={(e) => {
                          setOpenDeletemodal(true);
                          e.preventDefault();
                        }}
                      >
                        <img src={deleteImg} className="img-fluid" alt="" />
                      </button>
                    </span>
                  </div>
                  <span className="location">
                    <img src={pinImg} className="img-fluid" alt="" />{" "}
                    {isTextLong
                      ? `${locationStr.slice(0, 20)}...`
                      : locationStr}
                  </span>
                  {isSubscribed == true && (
                    <span className="location star-rating">
                      <img src={starImg} className="img-fluid" alt="" />{" "}
                      {ratingStr}
                    </span>
                  )}
                  <>
                    {(item?.rentRange?.from || item?.rentRange?.to) && (
                      <div className="card-price">
                        ${item?.rentRange?.from} - ${item?.rentRange?.to}{" "}
                      </div>
                    )}
                  </>
                </div>
                <div className="card-content-right d-flex flex-column justify-content-between">
                  <span className="card-actions">
                    {!item?.subscriptionDetail?.subscriptionId && (
                      <button className="owner-card-action-button subscription-pending d-none d-md-block">
                        <Tooltip message="Subscription pending. Subscribe your facility to get listed on the homepage.">
                          <img src={infoPrimary} className="img-fluid" alt="" />
                        </Tooltip>
                      </button>
                    )}
                    {item?.subscriptionDetail?.subscriptionId && (
                      <button
                        className="owner-card-action-button d-none d-md-flex"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenCancelModal(true);
                        }}
                      >
                        <img src={cancleImg} className="img-fluid" alt="" />
                      </button>
                    )}
                    <button
                      className="owner-card-action-button"
                      onClick={(e) => {
                        history.push(`/residentialfacility/edit/${item?.id}`);
                        e.preventDefault();
                      }}
                    >
                      <img
                        src={editImg}
                        className="img-fluid d-none d-md-block"
                        alt=""
                      />
                      <img
                        src={editImgMobile}
                        className="img-fluid d-block d-md-none"
                        alt=""
                      />
                    </button>
                    <button
                      className="owner-card-action-button d-none d-md-flex"
                      onClick={(e) => {
                        setOpenDeletemodal(true);
                        e.preventDefault();
                      }}
                    >
                      <img src={deleteImg} className="img-fluid" alt="" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="card-body">
              <div className="property-wrapper d-flex flex-column flex-md-row justify-content-between w-100 h-100 border-0 pr-0">
                <div className="card-content-left d-flex flex-column py-2">
                  <div className="card-heading">
                    <span>
                      {heading.length > 50
                        ? heading
                            .slice(0, 50)
                            .split(" ")
                            ?.map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")
                        : heading
                            .split(" ")
                            ?.map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}{" "}
                    </span>
                  </div>
                  <span className="location">
                    <img src={pinImg} className="img-fluid" alt="" />{" "}
                    {isTextLong
                      ? `${locationStr?.slice(0, 20)}...`
                      : locationStr}
                  </span>
                  {isSubscribed == true && (
                    <span className="location star-rating">
                      <img src={starFillImg} className="img-fluid" alt="" />{" "}
                      {ratingStr}
                    </span>
                  )}
                </div>
                <div className="card-content-right d-flex flex-column justify-content-between">
                  <span className="card-actions">
                    {item?.subscriptionDetail?.subscriptionId && (
                      <button
                        className="owner-card-action-button d-none d-md-flex"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenCancelModal(true);
                        }}
                      >
                        <img src={cancleImg} className="img-fluid" alt="" />
                      </button>
                    )}
                    <button
                      className="owner-card-action-button"
                      onClick={(e) => {
                        dispatch(
                          addiopcenterAction.getByIdIopcenterdeatials(
                            item?.id,
                            item
                          )
                        );
                        history.push(`/residentialfacility/edit/${item?.id}`);
                        e.preventDefault();
                      }}
                    >
                      <img
                        src={editImg}
                        className="img-fluid d-none d-md-block"
                        alt=""
                      />
                      <img
                        src={editImgMobile}
                        className="img-fluid d-block d-md-none"
                        alt=""
                      />
                    </button>
                    <button
                      className="owner-card-action-button d-none d-md-flex"
                      onClick={(e) => {
                        setOpenDeletemodal(true);
                        e.preventDefault();
                      }}
                    >
                      <img src={deleteImg} className="img-fluid" alt="" />
                    </button>
                  </span>
                  <div className="card-btn-container mb-md-2">
                    <button
                      className="btn btn-primary-outline radius-20"
                      disabled={true}
                    >
                      <img
                        src={surveyImg}
                        className="img-fluid d-block d-md-none"
                        alt=""
                      />
                      <span>Manage</span> Questionnaire
                    </button>
                    <button
                      className="btn btn-primary radius-20"
                      data-toggle="tooltip"
                      data-placement="top"
                      disabled={true}
                      title="Your facility would be quality-checked by Taste Recovery Team for accuracy and completeness. We would provide a verified badge to the facility center you requested for and that attracts more seekers."
                    >
                      <img
                        src={verifiedbadgeImg}
                        className="img-fluid d-block d-md-none"
                        alt=""
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenVerifiedBadge(true);
                        }}
                      />
                      <span>Request</span> Verified Badge
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="card-btn-container mb-md-2">
            {(isPreSaleSubscribed || isSubscribe) && (
              <button
                className="btn btn-primary-outline radius-20"
                data-placement="top"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPPC(true);
                }}
              >
                <img
                  src={promotionsImg}
                  className="img-fluid d-block d-md-none"
                  alt=""
                />
                Promote facility
              </button>
            )}

            {isSubscribe && (
              <button
                className="btn btn-primary-outline radius-20"
                data-placement="top"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/residentialfacility/edit/${item?.id}`, {
                    state: {
                      isUpgrade: true,
                      facilityId: item?.id,
                      facilityType: item?.facilityType,
                      subscriptionId: item?.subscriptionDetail?.subscriptionId,
                      userId: item?.createdBy,
                    },
                  });
                }}
              >
                <img
                  src={upgradeImg}
                  className="img-fluid d-block d-md-none"
                  alt=""
                />
                upgrade
              </button>
            )}
            {(preSalePlanType === "pro" ||
              subscriptionDetail?.subscriptionType === "pro") && (
              <button
                className="btn btn-primary-outline radius-20"
                onClick={(e) => {
                  contextType.setFacilityId(item?.id);
                  contextType.setFacilityName(item?.name);
                  e.preventDefault();
                  history.push(`/manageSurvey`);
                }}
              >
                <img
                  src={surveyImg}
                  className="img-fluid d-block d-md-none"
                  alt=""
                />
                <span>Manage</span> Questionnaire
              </button>
            )}
            {(isPreSaleSubscribed || isSubscribe) && (
              <button
                className="btn btn-primary radius-20  verified-badge-btn"
                data-toggle="tooltip"
                data-placement="top"
                disabled={
                  item.verifyRequestStatus === "process" ||
                  item.verifyRequestStatus === "approved"
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenVerifiedBadge(true);
                }}
              >
                <Tooltip message="Your facility will be quality checked by our Taste Recovery Team. We will provide a verified badge on your facilities home page once all information has been approved.">
                  <img
                    src={verifiedbadgeImg}
                    className="img-fluid d-block d-md-none"
                    alt=""
                  />
                  <span>Request</span> Verified Badge
                </Tooltip>
              </button>
            )}
          </div>
        </Link>
      </div>
    </>
  );

  function getImageUrlFromItem() {
    const item = props?.item;
    if (!item || !item?.photos || item?.photos?.length === 0) {
      return " ";
    }
    const mainImage = item?.photos?.find((photo) => photo?.isMain);
    if (!mainImage?.thumbnails || mainImage?.thumbnails?.length === 0) {
      return mainImage?.path;
    }
    return mainImage?.thumbnails["thumb360w"];
  }

  function onCardClick() {
    let city = getCityName(props?.item?.geocodingResult);
    props.history.push(`living/sober-living-in${city}&id=${props?.item?.id}`);
  }
};

export default withRouter(ResidencialCard);
