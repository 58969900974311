import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import ModalComponent from '../../components/modal/modal.component';
import { connect } from 'react-redux';
import { SpinnerContext } from '../../components/spinner/spinner.component';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { DatePickerCalendar } from 'react-nice-dates';
import { enGB } from 'date-fns/locale';
import { TimeCapsules } from '../../constants/server.constant';
import { convertEnumToArray } from '../../utils/common.util';
import moment from 'moment';

const DatePicker = (props) => {
  const { enabled = true } = props
  const changeDateFormat = (date) => {

    // return new Date(date);
    return moment(date).local().format("YYYY-MM-DD");
  };
  const [selectedDates, setSelectedDates] = useState(props.availableDates.length === 0 ? [] : props.availableDates);
  const [currentDate, setCurrentDate] = useState(changeDateFormat(new Date()));
  const modifiersClassNames = {
    highlight: '-highlight-avialable-date'
  };

  const modifiers = {
    disabled: date => !enabled, // Disables Saturdays
    highlight: (date) =>
      selectedDates.some((d) => {
        return moment(date).local().format('YYYY-MM-DD') === changeDateFormat(d);
      })
  };

  const isActive = (time) => {
    const currentDates = selectedDates.filter((date) => {
      return changeDateFormat(date) === currentDate;
    });
    const isAvailable = currentDates.some((date) => {
      return date.split('T')[1].slice(0, 5) === time;
    });

    return isAvailable ? 'active' : '';
  };

  return (
    <div>
      {!props.mobileView &&
        <Modal.Header closeButton>
          <Modal.Title>Select Tours Availability</Modal.Title>
        </Modal.Header>
      }
      <DatePickerCalendar
        minimumDate={new Date()}
        //Don't put current date directly to new Date object It will give past date in US time
        date={new Date(currentDate.split("-")[0], currentDate.split("-")[1] - 1, currentDate.split("-")[2])}
        onDateChange={(date) => {
          setCurrentDate(changeDateFormat(date));
        }}
        locale={enGB}
        modifiers={modifiers}
        modifiersClassNames={modifiersClassNames}
        weekdayFormat="eeeee"
      />
      <div className="time-slot-wrap">
        {convertEnumToArray(TimeCapsules).map((time, index) => (
          <span
            key={index}
            className={`time-slot ${isActive(time.value)}`}
            onClick={() => {
              let selected = selectedDates;
              let current = `${currentDate}T${time.value}:00.000Z`;
              // check if already exists

              if (selected.indexOf(current) !== -1) {
                selected = selected.filter((s) => s !== current);
              } else {
                selected = [...selected, current];
              }
              setSelectedDates([...selected]);
              if (props.mobileView) {
                props.onSubmit(selected);
              }
            }}
          >
            {time.label}
          </span>
        ))}
      </div>
      {!props.mobileView
        ?
        <Modal.Footer className="justify-content-center">
          <Button
            className="btn btn-primary radius-20 center mr-3"
            size="lg"
            onClick={() => {
              props.onSubmit(selectedDates);
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        :
        null
      }
    </div>
  );
};
class TourDatePicker extends Component {
  static contextType = SpinnerContext;

  constructor(props) {
    super(props);
    this.state = {
      tourModalShow: false,
      availableDates: this.props.values
    };
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState, snapshot) { }

  onSelectTimeSlot = () => { };

  render() {
    const { mobileView = false, enabled = true } = this.props;
    return (
      <div>
        {!mobileView && <button className="select-tours" type="button" onClick={() => this.setState({ tourModalShow: true })}>
          Select tours availability
        </button>}
        {mobileView ?
          <DatePicker
            enabled={enabled}
            mobileView={true}
            onSubmit={(values) => {
              this.setState({ availableDates: values });
              this.props.handleChange(values);
            }}
            availableDates={this.state.availableDates}
            onSelectTimeSlot={this.onSelectTimeSlot}

          /> :
          <ModalComponent
            component={DatePicker}
            show={this.state.tourModalShow}
            onSubmit={(values) => {
              this.setState({ tourModalShow: false, availableDates: values });
              this.props.handleChange(values);
            }}
            availableDates={this.state.availableDates}
            onSelectTimeSlot={this.onSelectTimeSlot}
            className="calendar-modal"
            onHide={() => {
              this.setState({ tourModalShow: false });
            }}
            size="lg"
          />
        }
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ tour, living }) => ({
      isTourBooking: tour.bookingATours,
      properties: living.properties,
      tourCalendar: tour.calendar,
      fetchingTourCalendar: tour.fetchingCalendar,
      fetchingLiving: living.fetchingLiving
    }),
    (dispatch) => {
      return {};
    }
  )(TourDatePicker)
);
