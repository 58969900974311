import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Logoutsessionmodal from '../components/modal/Logoutsessionmodal'; 
import { authAction } from '../actions';

const SessionTimeout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const sessionExpired = useSelector((state) => state.auth);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleSessionTimeout = () => {
    if(sessionExpired?.user?.id){
      dispatch(authAction.sessionLogout());
    }
   
    if (sessionExpired.sessionInformation.isTokenExpired)  {
      dispatch(authAction.logout());
      setOpenConfirmationModal(true);

      const timer = setTimeout(() => {
        setOpenConfirmationModal(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    handleSessionTimeout();
  }, [dispatch, location]);

  return (
    <>
      {children}
      <Logoutsessionmodal open={openConfirmationModal} />
    </>
  );
};

export default SessionTimeout;
