import React from "react";
import {Helmet} from "react-helmet";

export default function ReactHelmet({title,description}){
    return  (<Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href="https://tasterecovery.com" />
    <link rel="icon" href="/public/icon-176.png" />
    </Helmet>)

}