import { httpClient, removeCache } from "../utils";
import { SERVER_APIS } from "../constants";
import { requestFirebaseNotificationPermission } from "./../push-notification";

const registerDevice = () => {
  return (dispatch) => {
    return requestFirebaseNotificationPermission()
      .then((notificationsToken) => {
        return httpClient
          .post(`${SERVER_APIS.Device_Register_URL}`, {
            notificationsToken,
            deviceId: `web-${new Date().getTime()}`,
          })
          .then(({ data }) => {
            dispatch({
              type: authActionTypes.Register_Device,
              payload: { device: data },
            });
            return Promise.resolve(data);
          })
          .catch(({ response: { data } }) => {
            return Promise.reject();
          });
      })
      .catch(() => 
        Promise.reject()
    );
  };
};

const onLoginAction = (email, password) => {
  return (dispatch) => {
    return sendAuthRequest(
      SERVER_APIS.SIGN_IN_URL,
      { email, password },
      dispatch
    )
      .then((data) => {
        dispatch({
          type: authActionTypes.Login,
          payload: { authToken: data.token, user: data.user },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onRegisterAction = (data) => {
  return (dispatch) => {
    return sendAuthRequest(SERVER_APIS.SIGN_UP_URL, data, dispatch)
      .then(({ token, user }) => {
        dispatch({
          type: authActionTypes.Register,
          payload: { authToken: token, user },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onForgotPassword = (email) => {
  return (dispatch) => {
    return sendAuthRequest(SERVER_APIS.FORGOT_PASSWORD_URL, { email }, dispatch)
      .then(() => {
        dispatch({
          type: authActionTypes.Forgot_Password_Success,
          payload: { forgotPassword: true },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onResetPassword = (data) => {
  return (dispatch) => {
    return sendAuthRequest(SERVER_APIS.RESET_PASSWORD_URL, data, dispatch)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onLogout = () => {
  return (dispatch) => {
    return sendAuthRequest(SERVER_APIS.LOGOUT_URL, null, dispatch)
      .then(() => {
        dispatch({
          type: authActionTypes.Logout,
        });
        localStorage.removeItem("isShown");
        localStorage.setItem("closedModal", false);
        removeCache();
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const sendAuthRequest = (url, data, dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      return httpClient
        .post(url, data)
        .then((response) => {
          return resolve(response.data);
        })
        .catch(({ response: { data } }) => {
          dispatch({
            type: authActionTypes.Auth_Failed,
            payload: { error: data },
          });
          return reject();
        });
    } catch (e) {}
  });
};

const onGetUser = () => {
  return (dispatch) => {
    dispatch({ type: authActionTypes.Fetching_Me });
    return httpClient
      .get(`${SERVER_APIS.GET_ME}`)
      .then((response) => {
        dispatch({
          type: authActionTypes.Fetched_Me,
          payload: { me: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const sesionExpired = () => {
  return (dispatch) => {
    dispatch({ type: authActionTypes.Expiring_Session });
    return httpClient
      .get(`${SERVER_APIS.Session_Logout}`)
      .then((response) => {
        dispatch({
          type: authActionTypes.Expired_Session,
          payload: { sessionInfo: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onUpdateUser = (data, passwords) => {
  return (dispatch) => {
    dispatch({ type: authActionTypes.Updating_Me });
    return httpClient
      .patch(`${SERVER_APIS.GET_ME}`, data)
      .then((response) => {
        dispatch({
          type: authActionTypes.Updated_Me,
          payload: { me: response.data },
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: authActionTypes.Updated_Me,
          payload: null,
        });
        return Promise.reject(data);
      });
  };
};

const onChangePassword = (data) => {
  return (dispatch) => {
    dispatch({ type: authActionTypes.Updating_Me });
    return httpClient
      .patch(`${SERVER_APIS.GET_ME}/password`, data)
      .then((response) => {
        dispatch({
          type: authActionTypes.Updated_Me,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: authActionTypes.Updated_Me,
        });
        return Promise.reject(data);
      });
  };
};

const onSendContactMessage = (data) => {
  return (dispatch) => {
    dispatch({ type: authActionTypes.Updating_Me });
    return httpClient
      .post(`${SERVER_APIS.CONTACT}`, data)
      .then((response) => {
        dispatch({
          type: authActionTypes.Updated_Me,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: authActionTypes.Updated_Me,
        });
        return Promise.reject(data);
      });
  };
};

const removeUser = () => {
  return (dispatch) => {
    httpClient
      .delete(`${SERVER_APIS.GET_ME}`)
      .then(() => {
        dispatch({
          type: authActionTypes.Removed_Me,
        });
        Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

export const authActionTypes = {
  Login: "[Login] Action",
  Forgot_Password_Success: "[Forgot Password Success] Action",
  Auth_Failed: "[Auth Failed] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  Register_Device: "[Register_Device] Action",
  Fetching_Me: "[Fetching_Me] Action",
  Fetched_Me: "[Fetched_Me] Action",
  Updating_Me: "[Updating_Me] Action",
  Updated_Me: "[Updated_Me] Action",
  Removed_Me: "[Removed_Me] Action",
  Expiring_Session: "[Expiring_Session] Action",
  Expired_Session: "[Expired_Session] Action",
};
export const authAction = {
  login: onLoginAction,
  register: onRegisterAction,
  forgotPassword: onForgotPassword,
  resetPassword: onResetPassword,
  logout: onLogout,
  registerDevice,
  getUser: onGetUser,
  updateUser: onUpdateUser,
  removeUser,
  changePassword: onChangePassword,
  sendContactMessage: onSendContactMessage,
  sessionLogout: sesionExpired,
};
