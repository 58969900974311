
import React, { useState, useEffect, useContext } from "react";
import accountImgDesktop1 from "../../assets/images/image_1.png";
import accountImgDesktop2 from "../../assets/images/image_2.png";
import pinImg from "../../assets/images/ic_pin_small.svg";
import starImg from "../../assets/images/star-24px.svg";
import NoImage from '../../assets/images/no-image.svg'
import editImg from "../../assets/images/edit-disabled.svg";
import editImgPrimary from "../../assets/images/edit-primary.svg";
import editImgMobile from "../../assets/images/edit.svg";
import verifiedbadgeImg from "../../assets/images/verifiedbadge.svg";
import surveyImg from "../../assets/images/survey.svg";
import deleteImgDisabled from "../../assets/images/delete-disabled.svg";
import menuDotsIcon from "../../assets/images/menu-dots.svg";
import { livingAction } from "../../actions/living.action";
import { useDispatch, useSelector } from "react-redux";
import {
  addiopcenterAction,
  detoxAction,
  phpAction,
  residentialAction,
} from "../../actions/index";
import { useHistory, Link } from "react-router-dom";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { formatString } from "../../constants/utils";

const UnclaimedFacilities = () => {
  const dispatch = useDispatch();
  const contextType = useContext(SpinnerContext);

  const claimListData = useSelector((state) => state?.living.claimList);
  const history = useHistory();

  useEffect(() => {
    dispatch(livingAction.unclaimedList());
  }, [dispatch]);

  const handleEdit = (e, claimData) => {
    e.preventDefault();
    e.stopPropagation();
    
    switch (claimData?.facilityType) {
      case "iop":
        dispatch(addiopcenterAction.getByIdIopcenterdeatials(claimData?.id, claimData));
        history.push(`/iopfacility/edit-iopcenter/${claimData?.id}`);
        break;
      case "detox":
        dispatch(detoxAction.getDetoxById(claimData?.id, claimData));
        history.push(`/detoxfacility/edit/${claimData?.id}`);
        break;
      case "php":
        dispatch(phpAction.getPhpById(claimData?.id, claimData));
        history.push(`/phpfacility/edit/${claimData?.id}`);
        break;
      default:
        dispatch(residentialAction.getResidencialById(claimData?.id, claimData));
        history.push(`/residentialfacility/edit/${claimData?.id}`);
        break;
    }
  };

  const constructPathname = (data) => {
    switch (data?.facilityType) {
      case "php":
        return `/phpfacility/view/${data?.id}`;
      case "detox":
        return `/detoxfacility/view/${data?.id}`;
      case "residential":
        return `/residentialfacility/view/${data?.id}`;
      case "iop":
        return `/iopfacility/view/${data?.id}`;
      default:
        return "";
    }
  };

  return (
    <>
      {claimListData?.claimList?.length > 0 && (
        <div className="row">
          <div className="col-md-12 unclaimed-facilities">
            <div className="listing-sub-header test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2">
              <h4 className="main-title mb-0">UNCLAIMED FACILITIES</h4>
            </div>
            <div className="slider-wrapper">
              <div className="sober-living-slider living d-flex flex-column">
                {claimListData?.claimList?.map((data) => (
                  <div className="sober-living-slider_item w-100" key={data.id}>
                    <Link
                      className="card text-decoration-none d-flex flex-wrap flex-row"
                      to={{
                        pathname: constructPathname(data),
                        state: { property: data },
                      }}
                      onClick={() => contextType.setClaimedFacility(true)}
                    >
                      <div className="card-img">
                        {data?.photos?.length>0 ? <img
                          src={data?.photos[0]?.path}
                          className="img-fluid"
                          alt=""
                        />: <img
                        src={NoImage}
                        className="img-fluid"
                        alt=""
                      />}
                        <div>
                          {data?.preSalePlanType ? (
                            data?.isSubscribed === true ||
                            (data?.preSalePlanType === "pro" &&
                              data?.isPreSaleSubscribed) ? (
                              <span className="subscription-msg">Pro</span>
                            ) : (
                              <span className="subscription-msg unsubscribed">
                                Basic
                              </span>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="property-wrapper d-flex flex-column flex-md-row justify-content-between w-100 h-100 border-0 pr-0">
                          <div className="card-content-left d-flex flex-column justify-content-center">
                            <div className="card-heading">
                              {data?.name
                          ?.split(" ")
                          ?.map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                              <div className="position-relative d-block d-md-none">
                                <button
                                  type="button"
                                  className="mobile-dropdown-btn"
                                >
                                  <img
                                    src={menuDotsIcon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </button>
                                <div className="mobile-dropdown">
                                  <button className="btn btn-primary-outline radius-20">
                                    <span>Cancel</span> Subscription
                                  </button>
                                  <button className="btn btn-primary-outline radius-20">
                                    Upgrade
                                  </button>
                                </div>
                                <div className="mobile-dropdown-overlay"></div>
                              </div>
                            </div>
                            <span className="location">
                              <img
                                src={pinImg}
                                className="img-fluid"
                                alt=""
                              />
                              {data?.geocodingResult?.cityName},{" "}
                              {data?.geocodingResult?.stateShort},{" "}
                              {data?.geocodingResult?.country}
                            </span>
                            <span className="location star-rating">
                              <img
                                src={starImg}
                                className="img-fluid"
                                alt=""
                              />
                              {data?.rating?.value} /{" "}
                              {data?.rating?.quantity}
                            </span>
                            {(data?.rentRange?.from || data?.rentRange?.to) &&<div className="card-price">
                              ${data?.rentRange?.from} - $
                              {data?.rentRange?.to}
                              {/* initially code was added need to check the reason  */}
                              {/* <span> Monthly</span>  */}
                            </div>}
                          </div>
                          <div className="card-content-right d-flex flex-column justify-content-between">
                            <span className="card-actions">
                              <div
                                className="owner-card-action-button-container"
                                onClick={(e) => handleEdit(e, data)}
                              >
                                <button
                                  className="owner-card-action-button"
                                >
                                  <img
                                    src={editImgPrimary}
                                    className="img-fluid d-none d-md-block"
                                    alt=""
                                  />
                                  <img
                                    src={editImgMobile}
                                    className="img-fluid d-block d-md-none"
                                    alt=""
                                  />
                                </button>
                              </div>
                              <button className="owner-card-action-button d-none d-md-flex disabled">
                                <img
                                  src={deleteImgDisabled}
                                  className="img-fluid"
                                  alt=""
                                />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-btn-container mb-md-2">
                        <button className="btn btn-primary-outline radius-20 d-none d-md-block disabled">
                          <img
                            src={surveyImg}
                            className="img-fluid d-block d-md-none"
                            alt=""
                          />
                          <span>Cancel</span> Subscription
                        </button>
                        <button className="btn btn-primary-outline radius-20 d-none d-md-block disabled">
                          <img
                            src={surveyImg}
                            className="img-fluid d-block d-md-none"
                            alt=""
                          />
                          Upgrade
                        </button>
                        <button className="btn btn-primary-outline radius-20 disabled">
                          <img
                            src={surveyImg}
                            className="img-fluid d-block d-md-none"
                            alt=""
                          />
                          <span>Manage </span> Questionnaire
                        </button>
                        <button
                          className="btn btn-primary radius-20 disabled"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Your facility would be quality-checked by Taste Recovery Team for accuracy and completeness. We would provide a verified badge to the facility center you requested for and that attracts more seekers."
                        >
                          <img
                            src={verifiedbadgeImg}
                            className="img-fluid d-block d-md-none"
                            alt=""
                          />
                          <span>Request</span> Verified Badge
                        </button>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnclaimedFacilities;
