import { paymentActionTypes } from "./../actions/payment.action";

const initalPaymentState = {
  isAddingCard: false,
  cards: [],
  error: undefined,
  fetchingCards: false,
  cards: [],
  fetchingTransactions: false,
  transactions: [],
  fetchsubscribePlanIOP:[],
  couponData:{},
  couponCodeData: null,
  
};
export const paymentReducer = (state = initalPaymentState, action) => {
  switch (action.type) {
    case paymentActionTypes.Adding_Card:
      return { ...state, isAddingCard: true, error: undefined };
    case paymentActionTypes.Adding_Success:
      const { card } = action.payload;
      state.cards.push(card);
      return { ...state, isAddingCard: false };
    case paymentActionTypes.Adding_Failed:
      const { error } = action.payload;
      return { ...state, isAddingCard: false, error };

    case paymentActionTypes.Fetching_Cards:
      return { ...state, fetchingCards: true, cards: [] };
    case paymentActionTypes.Fetched_Cards:
      const { cards } = action.payload;
      return { ...state, fetchingCards: false, cards };

      case paymentActionTypes.Fetching_PlanStateforIOP:
        return { ...state, fetchingPlan: true };

      case paymentActionTypes.PlanStateforIOP_Fetched:
        const { subscribePlanIOP } = action.payload;
        return { ...state, fetchingPlan: false,fetchsubscribePlanIOP: subscribePlanIOP };
    case paymentActionTypes.Removed_Card:
      const { cardId } = action.payload;
      const savedCards = state.cards.filter((card) => card.id !== cardId);
      return { ...state, cards: savedCards };

    case paymentActionTypes.Fetching_Transactions:
      return { ...state, fetchingTransactions: true };

    case paymentActionTypes.Fetched_Transactions:
      const { transactions } = action.payload;
      return { ...state, fetchingTransactions: false, transactions };

    case paymentActionTypes.Retry_Transaction_Failed:
      const errorRetry = action.payload.error;
      return { ...state, error: errorRetry };
      case paymentActionTypes?.Apply_Coppon_Success: 
      const {applycopponcode ,couponCode } = action.payload;
      return { ...state, couponData: applycopponcode,couponCodeData: couponCode };

      case paymentActionTypes.RESET_COUPON_DATA:
        return { ...state, couponData: {} };

    default:
      return state;
  }
};
