import React, { Component } from 'react';

class CustomDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { error = '', label, mandatory, options, disabled = false, layout = 'vertical', placeholder, handleChange = () => { }, name = "", value } = this.props;
        return (
            <>
                {layout === 'vertical' ? (
                    <div className="form-group">
                        <label>
                            {label}
                            {mandatory === "true" && <span className="mandatory">*</span>}
                        </label>
                        <select className="form-control" name={name} id="" value={value} onChange={handleChange} disabled={disabled} >
                            {placeholder && <option value="" disabled>{placeholder}</option>}
                            {
                                options?.map((option, index) => (
                                    <option key={index} value={option.value ?? option}>{option.label ?? option}</option>
                                ))
                            }
                        </select>
                        <div className="error-message">{error}</div>
                    </div>)
                    :
                    <div className="form-group dflex-form">
                        <label htmlFor="">{label}</label>
                        <div className="time">
                            <select className="form-control border-0" name={name} value={value} onChange={handleChange} disabled={disabled}>
                                <option value="" disabled>Time</option>
                                {
                                    options?.map((option, index) => (
                                        <option key={index} value={option.value ?? option}>{option.label ?? option}</option>
                                    ))
                                }
                            </select>
                            <div className="error-message">{error}</div>
                        </div>
                    </div>
                }
            </>
        );
    }

}

export default CustomDropDown