import React, { Component } from "react";
import BackButtonComponent from "../../components/other/back-button.component";
import { connect } from "react-redux";
import { chatActions } from "../../actions";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import moment from "moment";
import { debounce } from "lodash";
import MediaQuery from "react-responsive";
import backImgBlack from "../../assets/images/back.svg";
import { getCityName } from "../../utils";
import { seoUrlText } from "../../constants";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import { ManageChat } from "./managechat/managechat";
import canclePrimaryImg from "../../assets/images/cancle-primary.svg";
class Chat extends Component {
  static contextType = SpinnerContext;
  _isMounted = false;
  chatContainer = React.createRef();
  fromContactHome;

  constructor(props) {
    super(props);
    this.state = {
      activeChatId: "",
      currentChatDetails: {},
      message: "",
      searchText: "",
      activeTab: "chat",
      showDialogueList: true,
      messageHide: true,
    };
    this._isMounted = true;
    this.messagesEndRef = React.createRef();
    this.chatContainer = React.createRef();
    this.fromContactHome = props.location.search?.split("=")[1];
  }

  async componentDidMount() {
    this._isMounted = true;

    this.context.showLoader("Fetching chats for you.");
    this.props
      .fetchChats()
      .then((res) => {
        this.context.hideLoader();
        const chatId = this.props.match.params.chatId;
        if (chatId) {
          this.setActiveChat(chatId);
        }
      })
      .catch((err) => {
        this.context.hideLoader();
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  scrollToMyRef = () => {
    setTimeout(() => {
      var chatBox = document.getElementById("chat-dialogue");
      if (chatBox) chatBox.scrollTop = chatBox.scrollHeight + 1;
    }, 0);
  };

  getCurrentChatDetailsIndex = (chatId) => {
    let currentIndex;
    this.props.chats.some((chat, index) => {
      if (chat.id === chatId) {
        currentIndex = index;
        return true;
      }
    });
    return currentIndex;
  };

  setActiveChat = (chatId) => {
    const currentIndex = this.getCurrentChatDetailsIndex(chatId);
    const chatData = this.props.chats[currentIndex];
    if (this._isMounted && chatId && this.props.chats && chatData) {
      this.setState({
        activeChatId: chatId,
        currentChatDetails: { chatData },
        message: "",
        showDialogueList: false,
      });
    }

    if (this.state.showDialogueList && chatId != "") {
      this.context.setStickyFooter(false);
    }

    if (!this.props.isFetchingActiveChat) {
      this.props.fetchActiveChat(chatId).finally(() => {
        if (this.state.currentChatDetails?.unreadCount !== 0) {
          this.props.markRead(chatId);
        }
        this.context.hideLoader();
        this.scrollToMyRef();
      });
    }
  };

  postMessage = async () => {
    const { activeChatId, message } = this.state;
    if (message.trim() === "") {
      return;
    }

    this.props
      .addChatMessage(activeChatId, { content: message })
      .finally(() => {
        this.setState({ message: "" });

        this.props.fetchActiveChat(activeChatId);

        var chatBox = document.getElementById("chat-dialogue");

        if (chatBox) {
          chatBox.scrollTop = chatBox.scrollHeight + 1;

          this.props.fetchChats();
        }
      });

    if (this.state?.currentChatDetails?.chatData?.lastMessage === "") {
      setTimeout(() => {
        if (this.state?.currentChatDetails?.chatData?.lastMessage === "") {
          this.context.showLoader("Fetching chats for you.");
          this.props.fetchChats();
        }
        this.context.hideLoader();
      }, 2000);
    }
  };

  handleTextChange = ({ target }) => {
    this.setState({ message: target.value });
  };

  wrapHandleSearch = ({ target }) => {
    this.setState({ searchText: target.value });
    this.handleSearch();
  };
  handleSearch = debounce(() => {
    this.props.searchChatByName(this.state.searchText);
  }, 500);

  filterItemByDate = (item) => {
    item = item.reverse();
    return item;
  };
  chatName = (chat, user) => {
    return chat?.soberLivingInfo?.soberLivingOwnerId == user.id
      ? `${chat?.userInfo?.name}`
      : chat?.facilityInfo?.soberLivingOwnerId == user.id
      ? chat?.userInfo?.name
      : chat?.userInfo?.name;
  };

  chatAvatar = (chat, user) => {
    return chat?.soberLivingInfo?.soberLivingOwnerId == user.id ? (
      chat?.userInfo?.name
        .toUpperCase()
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
    ) : chat?.facilityInfo?.soberLivingOwnerId == user.id ? (
      chat?.userInfo?.name
        .toUpperCase()
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
    ) : chat?.userInfo?.name ? (
      chat?.userInfo?.name
        .toUpperCase()
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
    ) : (
      <img src={require("../../assets/images/home-placeholder.png")} />
    );
  };

  handleTabChange = (tab) => {
    if (tab === "manageSurvey") {
      this.props.history.push("/manageSurvey");
    } else {
      this.setState({ activeTab: tab });
    }
  };
  scrollToTop = () => {
    this.chatContainer.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  render() {
    const soberliving = this.props.chats.filter((chat) =>
      chat.soberLivingInfo?.isSubscribed === true ||
      chat.soberLivingInfo?.chat?.soberLivingInfo?.isPreSaleSubscribed === true
        ? true
        : false === true
    );

    const facilities = this.props.chats.filter((chat) =>
      chat.facilityInfo?.isSubscribed === true ||
      chat.facilityInfo?.isPreSaleSubscribed === true
        ? true
        : false === true
    );

    const subscribedHousedChat = [...facilities, ...soberliving];

    const Chat_Capsule_UI = ({ item, ...rest }) => {
      return (
        <>
          <li key={item?.id} onClick={() => this?.setActiveChat(item?.id)}>
            <div className="conversation-info">
              <div className="user-img avatar-img">
                <div className="name-avtar">
                  <span> {this.chatAvatar(item, this.props.user)}</span>
                </div>
              </div>
              <div className="user-info">
                <span className="name">
                  {this.chatName(item, this.props.user)}
                </span>
                <span className="short-conversation">
                  {item?.lastMessage?.length > 30 ? (
                    <>{item?.lastMessage.substring(0, 30)}...</>
                  ) : (
                    <>{item?.lastMessage}</>
                  )}
                </span>
              </div>
              <div className="timetotal">
                <span className="time">
                  {moment(item?.lastMessageAt)?.format("YYYY-MM-DD") ===
                  moment(new Date())?.format("YYYY-MM-DD")
                    ? moment(item?.lastMessageAt)?.format("hh:mm A")
                    : moment(item?.lastMessageAt)?.format("MMMM D")}
                </span>
                {item?.unreadCount !== 0 && (
                  <span className="total">{item?.unreadCount}</span>
                )}
              </div>
            </div>
          </li>
        </>
      );
    };

    const Active_Chat_Box = (item, matches) => {
      const { currentChatDetails } = this.state;
      if (this.state.activeChatId == "") {
        return (
          <div className="no-records">
            <h2>
              NO OPEN CHATS!{" "}
              <span style={{ fontSize: "15px", color: "red" }}>
                (*Pro Feature)
              </span>
            </h2>
          </div>
        );
      }
      let city = getCityName(
        currentChatDetails?.soberLivingInfo?.location ||
          currentChatDetails?.facilityInfo?.location
      );

      return (
        <>
          {!matches && (
            <div className="conv-detail-header">
              <div className="user-img avatar-img">
                <div className="name-avtar">
                  <span>
                    {currentChatDetails?.soberLivingInfo?.id
                      ? this.chatAvatar(currentChatDetails, this.props.user)
                      : currentChatDetails?.facilityInfo?.id
                      ? currentChatDetails?.chatData?.userInfo?.name
                          .toUpperCase()
                          .split(/\s/)
                          .reduce(
                            (response, word) => (response += word.slice(0, 1)),
                            ""
                          )
                      : currentChatDetails?.chatData?.userInfo?.name
                          .toUpperCase()
                          .split(/\s/)
                          .reduce(
                            (response, word) => (response += word.slice(0, 1)),
                            ""
                          )}
                  </span>
                </div>
              </div>
              <span style={{ fontWeight: "bold", color: "black" }}>
                <a
                  href={
                    currentChatDetails?.chatData?.facilityInfo?.facilityType
                      ? `/home/centers/view/${currentChatDetails?.chatData?.facilityInfo?.id}`
                      : `/living/sober-living-in-${seoUrlText}&id=${currentChatDetails?.chatData?.soberLivingInfo?.id}`
                  }
                >
                  {currentChatDetails?.chatData?.userInfo?.name?.toUpperCase()}{" "}
                  - (
                  {currentChatDetails?.chatData?.facilityInfo?.name
                    ? currentChatDetails?.chatData?.facilityInfo?.name
                        ?.split(" ")
                        ?.map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")
                    : currentChatDetails?.chatData?.soberLivingInfo?.name?.toUpperCase()}
                  -
                  {currentChatDetails?.chatData?.facilityInfo?.facilityType
                    ? currentChatDetails?.chatData?.facilityInfo?.facilityType?.toUpperCase()
                    : "Sober Living"}
                  )
                </a>
              </span>
              <span
                className={`name ${
                  Boolean(
                    (currentChatDetails?.soberLivingInfo?.soberLivingOwnerId !=
                      this.props.user.id &&
                      currentChatDetails?.soberLivingInfo?.id) ||
                      (currentChatDetails?.facilityInfo?.soberLivingOwnerId !=
                        this.props.user.id &&
                        currentChatDetails?.facilityInfo?.id)
                  ) && "cursor-pointer"
                }`}
                onClick={() => {
                  Boolean(
                    (currentChatDetails?.soberLivingInfo?.soberLivingOwnerId !=
                      this.props.user.id &&
                      currentChatDetails?.soberLivingInfo?.id) ||
                      (currentChatDetails?.facilityInfo?.soberLivingOwnerId !=
                        this.props.user.id &&
                        currentChatDetails?.facilityInfo?.id)
                  ) && currentChatDetails?.facilityInfo?.id
                    ? this.props.history.push(
                        `/living/${seoUrlText}-${city.toLowerCase()}&id=${
                          currentChatDetails?.soberLivingInfo?.id
                        }`
                      )
                    : this.props.history.push(
                        `/home/centers/view/${currentChatDetails?.facilityInfo?.id}`
                      );
                }}
              >
                {this.chatName(currentChatDetails, this.props.user)}
              </span>
            </div>
          )}

          <div
            className="conversation-content"
            id="chat-dialogue"
            ref={this.chatContainer}
          >
            {this.state.messageHide && this.props.user.role === "regular" && (
              <div className="custom-alert-message d-flex align-items-center justify-content-between flex-fill">
                <p className="mb-0">
                  Avoid sharing personal information. Taste Recovery is not
                  liable for user-shared data
                </p>
                <a href="#" className="cancel-btn d-flex">
                  <img
                    src={canclePrimaryImg}
                    alt=""
                    onClick={(e) =>{e.preventDefault(); this.setState({ messageHide: false })}}
                  />
                </a>
              </div>
            )}
            {item?.map((chat, index) => {
              const isOtherUser =
                chat.userId === currentChatDetails?.chatData?.userInfo?.id;
              const className = isOtherUser
                ? "single-conversation left"
                : "single-conversation right";

              return (
                <div key={index}>
                  {
                    <div className="conversation-date mt-4">
                      <span className="date">
                        {moment(chat?.createdAt)?.format("YYYY-MM-DD") ===
                        moment(new Date())?.format("YYYY-MM-DD")
                          ? moment(chat?.createdAt)?.format("hh:mm a")
                          : moment(chat?.createdAt)?.format("MMMM D")}
                      </span>
                    </div>
                  }
                  <div className={className}>
                    {isOtherUser && (
                      <div className="user-img avatar-img">
                        <div className="name-avtar">
                          <span>
                            {this.state.currentChatDetails?.chatData?.userInfo?.name
                              .toUpperCase()
                              .split(/\s/)
                              .reduce(
                                (response, word) =>
                                  (response += word.slice(0, 1)),
                                ""
                              )}
                          </span>
                        </div>
                      </div>
                    )}
                    <span className="conv-box msg-block_left">
                      {chat?.isSurveyQA ? (
                        <>
                          <span> {chat.content.split(":")[0].trim()} </span> :
                          <span style={{ fontWeight: "600", color: "red" }}>
                            {chat.content.split(":")[1].trim()}
                          </span>
                        </>
                      ) : (
                        chat?.content
                      )}
                    </span>
                    <span className="time">
                      {moment(chat?.createdAt).format("hh:mm a")}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="start-conversation"
            ref={this.chatContainer}
            id="chat-box"
          >
            <input
              id={"message"}
              value={this.state.message}
              name="message"
              type="text"
              placeholder="Write a message…"
              onChange={(e) => this.handleTextChange(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && this.state.message !== "") {
                  this.postMessage();
                  this.setState({ message: "" });
                }
              }}
            />
            <a
              className="send-mes-btn"
              onClick={() => {
                if (this.state.message !== "") {
                  this.postMessage();
                }
                this.setState({ message: "" });
              }}
            >
              {this.state.message ? (
                <img
                  src={require("../../assets/images/ic_send_black.svg")}
                  alt=""
                />
              ) : (
                <img
                  src={require("../../assets/images/ic_send_disabled.svg")}
                  alt=""
                />
              )}
            </a>
          </div>
        </>
      );
    };

    if (this.state.activeTab === "manageSurvey") {
      return <ManageChat />;
    }

    return (
      <div>
        <ReactHelmet
          title={"Taste Recovery - Chat"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />
        <section className="back chat-back">
          {/* Button to show in web mode */}
          <MediaQuery maxWidth={767.98}>
            {(matches) =>
              (matches ? false : true) ? (
                <BackButtonComponent className="container" isBlack={true} />
              ) : (
                <></>
              )
            }
          </MediaQuery>

          <MediaQuery maxWidth={767.98}>
            {(matches) =>
              (matches ? !this.state.showDialogueList : false) ? (
                <div className={"container"}>
                  <a
                    onClick={() => {
                      if (this.fromContactHome) {
                        this.context.setStickyFooter(false);
                        setTimeout(() => {
                          this.props.history.goBack();
                        }, 10);
                      } else {
                        this.setState({
                          showDialogueList: true,
                          currentChatDetails: {},
                          activeChatId: "",
                        });
                        this.context.setStickyFooter(true);
                      }
                    }}
                  >
                    <img src={backImgBlack} alt="" />
                  </a>
                </div>
              ) : (
                <></>
              )
            }
          </MediaQuery>
        </section>
        {this.state.activeTab === "chat" && (
          <section className="listing chat-section pt-0 pt-md-4">
            <div className="container">
              <ul
                className="manage-survey_tabs d-flex align-items-center"
                id="mobileDisplay"
              >
                <MediaQuery maxWidth={767.98}>
                  {(matches) =>
                    (matches ? this.state.showDialogueList : true) ? (
                      <>
                        <li
                          className={
                            this.state.activeTab === "chat"
                              ? "tab-item active"
                              : "tab-item"
                          }
                        >
                          <a
                            onClick={() => this.handleTabChange("chat")}
                            className="tab-link"
                          >
                            Chat
                          </a>
                        </li>
                        <li
                          className={
                            this.state.activeTab === "manageSurvey"
                              ? "tab-item active"
                              : "tab-item"
                          }
                        >
                          {this.props.user?.role === "host" && (
                            <a
                              onClick={() =>
                                this.handleTabChange("manageSurvey")
                              }
                              className="tab-link"
                            >
                              Manage Questionnaire
                            </a>
                          )}
                        </li>
                      </>
                    ) : (
                      <></>
                    )
                  }
                </MediaQuery>
                <MediaQuery maxWidth={767.98}>
                  {(matches) =>
                    (matches ? !this.state.showDialogueList : false) ? (
                      <div className="conversation-info">
                        <div className="user-img avatar-img">
                          <div className="name-avtar">
                            <span>
                              {this.state.currentChatDetails?.soberLivingInfo
                                ?.id
                                ? this.chatAvatar(
                                    this.state.currentChatDetails,
                                    this.props.user
                                  )
                                : this.state.currentChatDetails?.facilityInfo
                                    ?.id
                                ? this.chatAvatar(
                                    this.state.currentChatDetails,
                                    this.props.user
                                  )
                                : this.state.currentChatDetails?.chatData?.userInfo?.name
                                    .toUpperCase()
                                    .split(/\s/)
                                    .reduce(
                                      (response, word) =>
                                        (response += word.slice(0, 1)),
                                      ""
                                    )}
                            </span>
                          </div>
                        </div>
                        <h3
                          id="mobileDisplay"
                          className="main-title mb-0"
                          onClick={() => {
                            Boolean(
                              this.state.currentChatDetails?.soberLivingInfo
                                ?.soberLivingOwnerId != this.props.user.id &&
                                this.state.currentChatDetails?.soberLivingInfo
                                  ?.id
                            ) &&
                              this.props.history.push(
                                `/living/${this.state.currentChatDetails?.soberLivingInfo?.id}`
                              );
                          }}
                        >
                          <a
                            href={
                              this.state?.currentChatDetails?.chatData
                                ?.facilityInfo?.facilityType
                                ? `/home/centers/view/${this.state?.currentChatDetails?.chatData?.facilityInfo?.id}`
                                : `/living/sober-living-in-${seoUrlText}&id=${this.state?.currentChatDetails?.chatData?.soberLivingInfo?.id}`
                            }
                          >
                            {this.state?.currentChatDetails?.chatData?.userInfo?.name?.toUpperCase()}{" "}
                            - (
                            {this.state?.currentChatDetails?.chatData
                              ?.facilityInfo?.name
                              ? this.state?.currentChatDetails?.chatData?.facilityInfo?.name
                                  ?.split(" ")
                                  ?.map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1).toLowerCase()
                                  )
                                  .join(" ")
                              : this.state?.currentChatDetails?.chatData?.soberLivingInfo?.name?.toUpperCase()}
                            -
                            {this.state?.currentChatDetails?.chatData
                              ?.facilityInfo?.facilityType
                              ? this.state?.currentChatDetails?.chatData?.facilityInfo?.facilityType?.toUpperCase()
                              : "Sober Living"}
                            )
                          </a>
                        </h3>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </MediaQuery>
              </ul>
              <div className="chat-wrapper">
                <MediaQuery maxWidth={767.98}>
                  {(matches) =>
                    (matches ? this.state.showDialogueList : true) ? (
                      <>
                        <div className="chat-leftbar">
                          <div className="chat-search">
                            <div className="form-group position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                value={this.state.searchText}
                                onChange={(e) => this.wrapHandleSearch(e)}
                              />
                              <span className="search-icon">
                                <img
                                  src={require("../../assets/images/search-24px.svg")}
                                  alt="search-icon"
                                />
                              </span>
                            </div>
                          </div>

                          <div className="convesation-wrapper">
                            <ul className="conversation-list">
                              {subscribedHousedChat.map((chat, index) => (
                                <Chat_Capsule_UI
                                  item={chat}
                                  key={index}
                                  isSubscribed={
                                    chat?.isSubscribed === true ||
                                    chat?.isPreSaleSubscribed === true
                                      ? true
                                      : false
                                  }
                                />
                              ))}
                            </ul>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  }
                </MediaQuery>
                <MediaQuery maxWidth={767.98}>
                  {(matches) =>
                    (matches ? !this.state.showDialogueList : true) ? (
                      <div className="chat-rightbar">
                        {Active_Chat_Box(this.props.activeChat, matches)}
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </MediaQuery>
              </div>
              <div className="d-none d-md-block chat-section_footer">
                <p>Copyright © Taste Recovery 2024</p>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default connect(
  ({ chat, auth }) => ({
    chats: chat.chats,
    isFetchingChats: chat.fetchingChats,
    activeChat: chat.activeChat,
    isFetchingActiveChat: chat.fetchingActiveChat,
    isAddingChatMessage: chat.addingChatMessage,
    isSeachingChat: chat.searchingChat,
    isHost: auth?.isHost,
    user: auth.user,
  }),
  chatActions,
  null,
  { forwardRef: true }
)(Chat);
