import React, {Component} from "react";
import {Modal} from "react-bootstrap";

class ModalComponent extends Component {
    render() {
        const Component = this.props.component;
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} className={this.props.className} centered>
                <Component {...this.props}/>
            </Modal>
        )
    }
}

export default ModalComponent;
