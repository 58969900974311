import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ModalComponent from '../../components/modal/modal.component';
import AddCard from '../payment/add-card';
import PaymentCards from '../../components/cards/payment-cards';
import { connect } from 'react-redux';
import { paymentActions } from '../../actions';
import { SpinnerContext } from '../../components/spinner/spinner.component';

class SavedCards extends Component {
  static contextType = SpinnerContext;
  constructor(props) {
    super(props);
    this.state = {
      showAddCard: false,
      selectedCard: undefined,
      showModal: false
    };
    this.togglShowAddCard = this.togglShowAddCard.bind(this);
    this.removeCard = this.removeCard.bind(this);
  }
  render() {
    return (
      <div className="savehome-wrp setting-list" id="six">
        <h3 className="main-title">Your cards </h3>
        <div className="saved-home-wrappper cards-wrapper mt-4">
          <PaymentCards
            selectedCard={this.state.selectedCard?.id}
            onSelectCard={(selectedCard) =>
              this.setState({
                selectedCard: this.state.selectedCard?.id === selectedCard.id ? undefined : selectedCard
              })
            }
          />
          <Button className="btn btn-primary border-radius-20 float-md-right mt-md-4 py-2 px-5" onClick={this.togglShowAddCard}>
            ADD
          </Button>
          {this.state.selectedCard && (
            <Button variant="secondary" className="btn border-radius-20 float-right btn btn-secondary mt-md-4 py-2 px-5 mr-4" onClick={() => this.setState({ showModal: true })}>
              Delete
            </Button>
          )}
        </div>
        <ModalComponent
          component={AddCard}
          show={this.state.showAddCard}
          onHide={this.togglShowAddCard}
          onSuccess={(data) => {
          
            this.togglShowAddCard();
          }}
        />
        <div>
          <Modal show={this.state.showModal} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Remove Card?</Modal.Title>
            </Modal.Header>
            <Modal.Body> Are you sure you want to delete the Card?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.removeCard}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
  handleClose = () => {
    this.setState({ showModal: false });
  };
  togglShowAddCard() {
    this.setState({ showAddCard: !this.state.showAddCard });
  }

  removeCard() {
    if (this.state.selectedCard) {
      this.context.showLoader('Removing the card');
      this.handleClose();
      this.props
        .removeCard(this.state.selectedCard.id)
        .then(() => {
          this.context.showMessage('Removed card successfully!');
          this.setState({ selectedCard: undefined });
        })
        .finally(() => this.context.hideLoader());
    }
  }
}

export default connect(null, paymentActions)(SavedCards);
