export const DEFAULT_VALUES_STEP1 = {
    name: '', //
    about: '', //
    webUrl: 'https://www.',
    phoneNumber: '',
    options: [], //
    photos: [],
    type: '',
    paymentPeriod: '',
    paymentType: 'fixed',
    rentRange: {
        from: process.env.REACT_APP_MIN_PRICE_RANGE,
        to: process.env.REACT_APP_MAX_PRICE_RANGE
    },
    rentCost: '',
    serving: '',
    address: '',
    aptSuite: '',
    state: 'CA',
    zipCode: '',
    coordinates: {
        //     type: 'Point',
        //     coordinates: [0, 0],
        lat: 42.42254053329317,
        lng: -71.00966335367497
    },
    geocodingResult: {
        cityName: ''
    },
    bedroomQuantity: '',
    isOfferPrivateBedrooms: false,
    bathroomQuantity: '',
    isOfferPrivateBathrooms: false,
    maximumOccupancy: '',
    isHandicapFriendly: false,
    isProvideTransportation: false,
    isSmokeFriendly: false,
    havePrivateChef: false,
    isAvailableCurrently: false,
    isAvailableForTours: false,
    availableForToursDates: [],
    offersScholarships: false,
    offersPaymentPlans: false,
    haveTrainedSupportStaff: false,
    staffRequiredHaveAYearOfSobriety: false,
    offersCaseManagement: false,
    isAllowedWithChildren: false,
    media: [],
}

export const DEFAULT_VALUES_STEP2 = {
    mustClimbStairs: false,
    isPetLiveInProperty: false,
    isNoParking: false,
    isStreetParkingOnly: false,
    haveSurveillance: false,
    isOnlyAcceptsCashOrChecks: false,
    isAcceptsPaypal: false,
    houseRules: {
        abidingLocalCovidRules: false,
        dailyChores: false,
        weeklyChores: false,
        randomDrugTesting: false,
        zeroTolerancePolicy: false,
        curfew: false,
        curfewTime: {
            daily: '10 PM',
            sun: '',
            mon: '',
            tue: '',
            wed: '',
            thu: '',
            fri: '',
            sat: '',
        },
        mandatoryHouseMeetings: false,
        twelveStepMeetings: false,
        currentStep: 1,
        weeklyCheckIns: false,
        personalGoals: false,
        mustBeOutOfHouse: false,
        mustBeOutOfHouseDateRange: { from: '', to: '' },
        haveGuestVisitingHours: false,
        guestVisitingHours: { from: '', to: '' },
        haveQuietHours: false,
        quietHours: { from: '', to: '' },
        isOvernightPassesApprovedInAdvance: false,
        overnightPassesApprovedInAdvance: 0,
        isClientRestrictionFor: false,
        clientRestrictionFor: 'NONE',
        zeroToleranceForStealing: false,
        zeroToleranceForDestroyingProperty: false,
        zeroToleranceForSexualActivity: false,
        zeroToleranceForConfrontation: false,
        mustNotifyOfPrescribedMeds: false,
        allMedsMustBeTakenAsPrescribed: false,
        isReportingAllRulesViolations: false,
        meetingCardsAreDueAtHouseMeetings: false,
        mustHaveASponsorFor12Steps: false,
        residentsRoomsMustBeClean: false,
        noLiteringInFrontOfTheProperty: false,
        unusualJobSchedulesMustBeApproved: false,
        payRentOnTheFirstOfEachMonth: false,
        allSharedSpacesMustBeClean: false,
        noIllegalActivity: false,
        dndOtherResidents: false,
        beAccountable: false,
    },
    requirements: {
        isSobrietyRequirement: false,
        sobrietyRequirement: 'none',
        isGoneThroughAPriorProgram: false,
        isCompletedDetox: false,
        isAdministerMeds: false,
    },
}
export const DEFAULT_VALUES_STEP3 = {

}