import React, { Component } from 'react';
import BackButtonComponent from '../../components/other/back-button.component';
import { livingAction } from '../../actions';
import { connect } from 'react-redux';
import SoberLivingCard from '../../components/cards/sober-living.card';
import { SpinnerContext } from '../../components/spinner/spinner.component';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PropertyServing, StandartOptions, RoomOptions, PaymentPeriodFilter } from '../../constants';
import LivingFilter from './living-filter';
import GoogleMapComponent from './../../components/map/google-map.component';
import { getGeoCode, getLocationFromUrl, getCityName } from './../../utils';
import mapImg from '../../assets/images/map-black.svg';
import filterImg from '../../assets/images/filter.svg';
import ReactHelmet from '../../components/react-helmet/react-helmet';
import { withRouter } from 'react-router-dom';
class LivingSearch extends Component {
  static contextType = SpinnerContext;
  static defaultProps = {
    properties: []
  };
  queryParams = {
    paymentPeriod: PaymentPeriodFilter.Any,
    servingForWhom: PropertyServing.Any,
    availability: StandartOptions.Any,
    pets: StandartOptions.Any,
    transportation: StandartOptions.Any,
    room: RoomOptions.Any,
    bathroom: RoomOptions.Any,
    handicapFriendly: StandartOptions.Any,
    smokeFriendly: StandartOptions.Any,
    privateChef: StandartOptions.Any,
    children: StandartOptions.Any,
    acceptMAT: StandartOptions.Any,
    isCertifiedSoberLiving: StandartOptions.Any
  };
  defaultParams = {
    ...this.queryParams,
    priceMin: parseInt(process.env.REACT_APP_MIN_PRICE_RANGE),
    priceMax: parseInt(process.env.REACT_APP_MAX_PRICE_RANGE),
    page: 1
  };
  isSearchByCity = false;

  constructor(props) {
    super(props);
    const { location } = this.props.match.params;
    const coordinate={
      lat:this.props.location.state.lat , lng:this.props.location.state.lng
    }

    const topStateCords={
      lat:this.props.location.state.lat , lng:this.props.location.state.lng
    }
    // const params = new URLSearchParams(this.props.location.search);
    if (location) {
      // let city;
      // if(params.get("city"))
      // {
      //   city=params.get("city")
      // }else{
      //   city=location.split("city=")[1]
      // }
      let splitUrl = location.split("-");

      if (splitUrl.length >= 5) {
        let city = getLocationFromUrl(location);
        this.defaultParams['cityName'] = city.trim();
        this.defaultParams["coordinates"]=coordinate


      } else {
        const splitUrl = location.split("-");
        const stateLong = splitUrl[splitUrl.length - 1];
        this.defaultParams['stateLong'] = this.capitalizeFirstLetter(stateLong);
        this.defaultParams["coordinates"]=topStateCords
      }

      this.defaultParams['page'] = 1;

    }


    this.state = {
      showFilter: !!this.defaultParams.id,
      showMap: this.props.location.state?.isMap,
      center: undefined,
      queryParams: {
        ...this.defaultParams
      }
    };

    this.onApplySearch = this.onApplySearch.bind(this);
    this.getMarkers = this.getMarkers.bind(this);
    this.getCenter = this.getCenter.bind(this);
  }


  async componentDidMount() {

    this.props.clearSearch();
    const queryParams = this.state.queryParams;
    this.setState({
      queryParams: { ...queryParams, page: 1 }
    });

    await this.props.getLivingSearchInfo(this.state.queryParams);
    this.fetchSoberLiving();


    this.getCenter(this.state.queryParams['cityName'] || this.state.queryParams['stateLong']);
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  getStateOrCityName() {
    const { location } = this.props.match.params;
    if (location) {
      let splitUrl = location.split("-");
      if (splitUrl.length >= 5) {
        let city = getLocationFromUrl(location);
        return { type: "city", name: this.capitalizeFirstLetter(city.split(",")[0]) }

      } else {
        const splitUrl = location.split("-");
        const stateLong = splitUrl[splitUrl.length - 1];
        return { type: "state", name: this.capitalizeFirstLetter(stateLong) }
      }
    }

  }
  render() {
    return (
      <div>
        <ReactHelmet title={`${this.getStateOrCityName()?.name} - Taste Recovery`} description={`Search Sober Living homes ${this.getStateOrCityName().type == "state" ? `in ${this.getStateOrCityName().name}` : "near me"}`} />
        <section className="back">
          <BackButtonComponent className="container" isBlack={true} />
        </section>
        {/* listing */}
        <section className="listing pt-4">
          <div className="container">
            <div className="listing-header">
              <h3 className="main-title mb-0">{this.state.queryParams['cityName'] ? this.state.queryParams['cityName'] : this.state.queryParams['stateLong']}</h3>
              <div className="filter-options">
                <ul>
                  <li>
                    <a
                      className="btn"
                      as="button"
                      onClick={() => this.setState({
                        showFilter: false,
                        showMap: !this.state.showMap
                      }, () => { this.props.history.replace({ ...this.props.history.location, state: { ...this.props.history.location.state, isMap: this.state.showMap } }); this.fetchSoberLiving() }
                      )
                      }

                    >
                      <img src={mapImg} alt="" />
                      {this.state.showMap ? 'List' : 'Map'}
                    </a>
                  </li>
                  <li>
                    <a className="btn" as="button" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                      <img src={filterImg} alt="" /> Filter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {!this.state.showMap && (
              <div>
                <InfiniteScroll dataLength={this.props.properties.length} next={this.fetchMoreData} className="row py-3 animated fadeIn" hasMore={this.props.searchInfo.totalEntries > this.props.properties.length}>
                  {this.props.properties.map((item, index) => {
                    return (
                      <div className="col-md-4  mb-3 md-mb-0" key={index}>
                        <SoberLivingCard item={item} className="m-0" ratingOutside="true" />
                      </div>
                    );
                  })}
                  {this.props.properties.length === 0 && !this.props.fetchingLiving && (
                    <div className="no-records">
                      <h2>NO SOBER LIVINGS FOUND</h2>
                    </div>
                  )}
                </InfiniteScroll>
              </div>
            )}
            {this.state.showFilter && <LivingFilter onApplySearch={this.onApplySearch} defaultParams={this.defaultParams} onBack={() => this.setState({ showFilter: false })} queryParams={this.state.queryParams} />}
            {this.state.showMap && this.state.center && (
              <div className="row mt-4">
                <div className="col-md-12 p-0">
                  <div className="mavview">
                    <GoogleMapComponent zoom={this.state.queryParams['stateLong'] ? 7 : 12} className="list-map" newCenter={this.state.center} markers={this.getMarkers()} onBoundsChange={this.onBoundsChange} properties={this.getProperties()} history={this.props.history} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }

  getMarkers() {
    return this.props.properties.map((property) => property.coordinates);
  }

  getProperties() {
    return this.props.properties.map((property) => property);
  }
  async getCenter(address) {
    if (address) {
      const result = await getGeoCode(address);
      if (result.lat && result.lng) {
        return this.setState({ center: { ...result } });
      }
    }
    return this.setState({ center: { lat: 0, lng: 0 } });
  }

  onApplySearch(queryParams) {
    queryParams = { ...queryParams, page: 1 };
    this.setState(
      {
        showFilter: false,
        queryParams: { ...queryParams }
      },
      () => this.fetchSoberLiving()
    );
  }

  fetchMoreData = () => {
    const queryParams = this.state.queryParams;
    this.setState({
      queryParams: { ...queryParams, page: this.props.properties.length > 0 ? queryParams.page + 1 : 1 }
    });
    this.fetchSoberLiving(true);
  };

  fetchSoberLiving(loadMore = false) {
    if (!this.props.fetchingLiving) {
      if (!this.state.showMap) {
        this.context.showLoader('Get Sober Livings ...');
        this.props
          .searchLiving(
            {
              ...this.state.queryParams
            },
            loadMore
          )
          .finally(() => this.context.hideLoader());
      } else {
        this.context.showLoader('Get Sober Livings ...');
        this.props
          .searchLivingMap(
            {
              ...this.state.queryParams
            },
            loadMore
          )
          .finally(() => this.context.hideLoader());

      }

    }
  }

  onBoundsChange = (bounds) => {
    this.onApplySearch({
      ...this.state.queryParams,
      ...bounds
    });
  };
}

export default connect(
  ({ living }) => ({
    searchInfo: living.searchInfo,
    fetchingLiving: living.fetchingLiving,
    properties: living.livings
  }),
  livingAction
)(withRouter(LivingSearch));
